import {z} from 'zod'

const EventValueSchema = z.array(
  z.object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    value: z.string(),
  })
)

const valuesSchema = EventValueSchema.nullable()

const TraitItem = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  operators: z.array(z.string()),
  values: valuesSchema,
})

export type TraitItemType = z.infer<typeof TraitItem>

export type TraitsType = TraitItemType[]

export const GetAllTraitsRequestSchema = z.object({
  traitType: z.string().optional(),
})

const operatorDetailsSchema = z.object({
  id: z.number(),
  name: z.string(),
  operatorType: z.string(),
})

const OperatorsSchema = z.record(z.string(), operatorDetailsSchema)

export type OperatorDetailsType = z.infer<typeof operatorDetailsSchema>

export type OperatorsType = z.infer<typeof OperatorsSchema>

export type EventValuesType = z.infer<typeof EventValueSchema>

export type ValuesType = z.infer<typeof valuesSchema>

export const GetAllTraitsResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    traits: z.array(TraitItem),
    operators: OperatorsSchema,
  }),
  errorMessage: z.string().nullable().optional(),
})

export type GetAllTraitsRequest = z.infer<typeof GetAllTraitsRequestSchema>
export type GetAllTraitsResponse = z.infer<typeof GetAllTraitsResponseSchema>

const parameterItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  trait: z.object({
    id: z.number(),
    name: z.string(),
  }),
  operator: z.object({
    id: z.number(),
    name: z.string(),
  }),

  value: z.union([z.string(), z.number()]).nullable().optional(),
  predefinedValue: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullish(),
})

export type ParameterItem = z.infer<typeof parameterItemSchema>

export type Parameters = ParameterItem[]

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {
  getRequiresInterventionReportDataService,
  getRequiresInterventionReportService,
} from '../services/journeysService'
import {JourneyError} from '../types/JourneyError'
import {GetReportDataResponse, GetReportResponse} from '../types/Journeys'

interface GetRequiresInterventionReportUseCaseParams {
  id?: string
}

type ReportsResult = GetReportResponse | GetReportDataResponse

export async function getRequiresInterventionReportUseCase(
  params: GetRequiresInterventionReportUseCaseParams,
  signal?: AbortSignal
): Promise<ReportsResult> {
  return isFeatureEnabled(FeatureToggle.dataReports) ? getReportData(params, signal) : getReport(params, signal)
}

const getReport = async (
  {id}: GetRequiresInterventionReportUseCaseParams,
  signal?: AbortSignal
): Promise<GetReportResponse> => {
  const result = (await getRequiresInterventionReportService(id, signal)) as GetReportResponse

  if (!result?.path?.length) {
    throw new JourneyError('No current records that requires intervention', 'Export error')
  }

  return result
}

const getReportData = async (
  {id}: GetRequiresInterventionReportUseCaseParams,
  signal?: AbortSignal
): Promise<GetReportDataResponse> => {
  const result = (await getRequiresInterventionReportDataService(id, signal)) as GetReportDataResponse

  if (!result?.content?.length) {
    throw new JourneyError('No current records that requires intervention', 'Export error')
  }

  return result
}

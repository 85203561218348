import {RequiresInterventionParams} from '@/components/Drawer/ContentParams/RequiresInterventionParams'
import {Spinner} from '@/shared-components/Spinner'
import {useCallback, useEffect, useState} from 'react'
import {NodeTypeEnum} from '../../types/NodeTypeEnum'
import {FilterParams} from './ContentParams/FilterParams'
import {SendNotificationParams} from './ContentParams/SendNotificationParams'
import {WaitParams} from './ContentParams/WaitParams'

interface Provider {
  component: React.ReactNode
}

const provider: {[key in NodeTypeEnum]: Provider} = {
  [NodeTypeEnum.entryCondition]: {
    component: <FilterParams />,
  },
  [NodeTypeEnum.variant]: {
    component: <FilterParams />,
  },
  [NodeTypeEnum.wait]: {
    component: <WaitParams />,
  },
  [NodeTypeEnum.requiresIntervention]: {
    component: <RequiresInterventionParams />,
  },
  [NodeTypeEnum.sendPush]: {
    component: <SendNotificationParams type={NodeTypeEnum.sendPush} />,
  },
  [NodeTypeEnum.sendSMS]: {
    component: <SendNotificationParams type={NodeTypeEnum.sendSMS} />,
  },
  [NodeTypeEnum.sendWhatsApp]: {
    component: <SendNotificationParams type={NodeTypeEnum.sendWhatsApp} />,
  },
}

export interface ContentParamsProviderProps {
  type?: NodeTypeEnum
}

export const ContentParamsProvider = ({type}: ContentParamsProviderProps) => {
  const [showContent, setShowContent] = useState(false)

  const animationDelay = useCallback(() => {
    setTimeout(() => {
      setShowContent(true)
    }, 300)
  }, [setShowContent])

  useEffect(() => {
    setShowContent(false)
    animationDelay()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!type) return null

  if (!showContent)
    return (
      <div data-testid="loading-content-content-provider" className="m-auto w-fit">
        <Spinner size="large" />
      </div>
    )

  const {component} = provider[type]
  return <>{component}</>
}

import {GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {RequiresInterventionNodeModel} from './RequiresInterventionNodeModel'
import {RequiresInterventionNodeWidget} from './RequiresInterventionNodeWidget'

export class RequiresInterventionNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.requiresIntervention)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <RequiresInterventionNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new RequiresInterventionNodeModel()
  }
}

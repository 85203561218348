import {InputText} from '@/shared-components/InputText'
import {uniqueId} from 'lodash'
import {useIntl} from 'react-intl'
import {ThresholdMessages, ThresholdMessagesSchema} from '../../types/Settings/ThresholdMessages'

export type ThresholdMessagesProps = {
  values?: ThresholdMessages
  onChange?: (value: ThresholdMessages) => void
  autofocus?: number
}

export const ThresholdMessagesConfig = ({values, onChange, autofocus}: ThresholdMessagesProps) => {
  const {formatMessage} = useIntl()

  if (!values) return null

  const onValueDidChange = (type: keyof ThresholdMessages) => (newValue: string) => {
    const value = newValue === '' ? null : Math.max(0, parseInt(newValue, 10))

    onChange?.({
      ...values,
      [type]: value ?? null,
    })
  }

  return (
    <div className="flex w-fit flex-col gap-6">
      {(Object.keys(ThresholdMessagesSchema.shape) as (keyof ThresholdMessages)[]).map((fieldKey, idx) => {
        const unique = `${fieldKey}-${uniqueId()}`
        return (
          <div key={fieldKey} className="flex gap-2">
            <InputText
              type="number"
              label={formatMessage({id: `SETTINGS.THRESHOLD_${fieldKey.toUpperCase()}_LABEL`})}
              placeholder={formatMessage({id: 'SETTINGS.NO_THRESHOLD_LIMIT'})}
              id={`threshold-messages-${unique}`}
              data-testid={`threshold-messages-${fieldKey}`}
              data-cy={`threshold-messages-${fieldKey}`}
              min={0}
              value={Number(values[fieldKey]) || ''}
              onChange={evt => onValueDidChange(fieldKey)(evt.target.value)}
              autoFocus={idx === autofocus}
            />
          </div>
        )
      })}
    </div>
  )
}

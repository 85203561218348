import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {RemoveParameterButton} from '@/shared-components/RemoveParameterButton'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {
  BooleanOperatorsSchema,
  BooleanParams,
  DataParams,
  DateOperatorsSchema,
  DateParams,
  EnumOperatorsSchema,
  EnumParams,
  IntegerOperatorsSchema,
  IntegerParams,
  OperatorTypeSchema,
  Operators,
  ParamsSelector,
  StringOperatorsSchema,
  StringParams,
} from '../../../types/paramFilters/paramFilters'
import {getParamByValue, isOperatorBoolean, isOperatorList, params} from '../../../utils/consts'
import {ChangeFilterValueProps, FilterOptionsByParam} from './FilterOptionsByParam'

interface Props {
  position: number
  isPublished: boolean
  hasMultipleParamsSelectors: boolean
  currentParamSelector: ParamsSelector
  updateSelectedParam: (id: number, updatedParamSelector: ParamsSelector | null) => void
}

export const ParamSelector: React.FC<Props> = ({
  position,
  isPublished,
  currentParamSelector,
  hasMultipleParamsSelectors,
  updateSelectedParam,
}) => {
  const isFirstParamSelector = position === 0
  const {selectedParam, id} = currentParamSelector

  const shouldDisplayRemoveButton =
    !isPublished && (hasMultipleParamsSelectors || (isFirstParamSelector && selectedParam !== ''))

  const {formatMessage} = useIntl()
  const onSelectNewParam = (newParam: DataParams) => {
    if (isPublished) return

    let updatedSelectedParam: ParamsSelector
    const selectedType = getParamByValue(newParam)

    switch (selectedType.type) {
      case OperatorTypeSchema.Values.boolean:
        updatedSelectedParam = {
          ...currentParamSelector,
          selectedParamType: selectedType.type,
          selectedParam: newParam as BooleanParams,
          selectedParamData: {
            operator: BooleanOperatorsSchema.Values.isNo,
            value: null,
          },
        }
        break
      case OperatorTypeSchema.Values.integer:
        updatedSelectedParam = {
          ...currentParamSelector,
          selectedParamType: selectedType.type,
          selectedParam: newParam as IntegerParams,
          selectedParamData: {
            operator: IntegerOperatorsSchema.Values.lessThan,
            value: null,
          },
        }
        break
      case OperatorTypeSchema.Values.date:
        updatedSelectedParam = {
          ...currentParamSelector,
          selectedParamType: selectedType.type,
          selectedParam: newParam as DateParams,
          selectedParamData: {
            operator: DateOperatorsSchema.Values.lessThan,
            value: null,
          },
        }
        break
      case OperatorTypeSchema.Values.enum:
        updatedSelectedParam = {
          ...currentParamSelector,
          selectedParamType: selectedType.type,
          selectedParam: newParam as EnumParams,
          selectedParamData: {
            operator: EnumOperatorsSchema.Values.equalTo,
            value: null,
          },
        }
        break
      case OperatorTypeSchema.Values.string:
      default:
        updatedSelectedParam = {
          ...currentParamSelector,
          selectedParamType: selectedType.type,
          selectedParam: newParam as StringParams,
          selectedParamData: {
            operator: StringOperatorsSchema.Values.equalTo,
            value: null,
          },
        }
        break
    }

    updateSelectedParam(id, updatedSelectedParam)
  }

  const shouldSetOperatorValueToNull = (newOperator: Operators, oldOperator: Operators) => {
    return (
      isOperatorBoolean(newOperator) ||
      (isOperatorList(newOperator) && !isOperatorList(oldOperator)) ||
      (!isOperatorList(newOperator) && isOperatorList(oldOperator))
    )
  }

  const onChangeFilterValue = ({key, newValue}: ChangeFilterValueProps) => {
    if (!selectedParam || isPublished) return

    const updatedSelectedParam = {
      ...currentParamSelector,
      selectedParamData: {...currentParamSelector.selectedParamData, [key]: newValue},
    } as ParamsSelector

    if (key === 'operator') {
      const oldOperator = currentParamSelector.selectedParamData.operator

      if (shouldSetOperatorValueToNull(newValue, oldOperator)) {
        updatedSelectedParam.selectedParamData.value = null
      }
    }

    if (key === 'value' && newValue === '') {
      updatedSelectedParam.selectedParamData.value = null
    }

    updateSelectedParam(id, updatedSelectedParam)
  }

  const removeCurrentSelector = () => {
    updateSelectedParam(id, null)
  }

  const traits = useMemo(() => {
    return params()
      .filter(param => {
        if (['daysRemainingUntilPointExpiryDate'].includes(param.value)) {
          return isFeatureEnabled(FeatureToggle.traitsPoints)
        }

        if (
          [
            'daysSinceLastDeliveryDate',
            'daysUntilNextDeliveryDate',
            'daysSinceLastOrderDate',
            'daysUntilNextOrderDate',
            'nextOrderDate',
          ].includes(param.value)
        ) {
          return isFeatureEnabled(FeatureToggle.traitsOrders)
        }

        if (
          ['daysSincePromotionStart', 'promotionId', 'promotionStartDate', 'promotionEndDate', 'displayOrder'].includes(
            param.value
          )
        ) {
          return isFeatureEnabled(FeatureToggle.traitsPromotions)
        }

        if (
          [
            'activeStartDate',
            'targetType',
            'targetTypeDescription',
            'targetHit',
            'targetHitDate',
            'targetName',
            'goalStatusName',
            'joinedCampaignFlag',
            'activeEndDate',
            'targetActiveFlag',
            'targetHalfPeriodNotificationDate',
            'noOfDaysSinceTargetActiveStartDate',
          ].includes(param.value)
        ) {
          return isFeatureEnabled(FeatureToggle.traitsTargets)
        }
        return true
      })
      .sort((a, b) => formatMessage({id: a.displayValue}).localeCompare(formatMessage({id: b.displayValue})))
  }, [formatMessage])

  return (
    <>
      <div className="mb-4 flex gap-4 text-sm font-bold leading-6">
        {formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER'}, {position: position + 1})}
        {shouldDisplayRemoveButton && <RemoveParameterButton iconSize="small" onClick={removeCurrentSelector} />}
      </div>
      <div className="relative flex w-full flex-wrap items-start justify-between gap-4">
        <div className="flex w-full max-w-[321px] flex-col gap-2">
          <InputSelector
            id="param"
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_TRAIT'})}
            onChange={evt => onSelectNewParam(evt.target.value as DataParams)}
            value={selectedParam}
            disabled={isPublished}
            required
          >
            <option hidden>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_A_PARAMETER'})}</option>
            {traits.map(({value, displayValue}) => {
              return (
                <option value={value} key={value} data-testid="param-option">
                  {formatMessage({id: displayValue})}
                </option>
              )
            })}
          </InputSelector>
        </div>

        <FilterOptionsByParam
          currentParamSelector={currentParamSelector}
          onChangeFilterValue={onChangeFilterValue}
          disabled={isPublished}
        />
      </div>
    </>
  )
}

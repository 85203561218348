import {store, useAppSelector} from '@/store'
import {resetHasUnsavedChanges, setHasUnsavedChanges} from '@ReduxActions'
import _ from 'lodash'
import {useCallback, useEffect, useRef} from 'react'

export const useHasUnsavedChanges = (value: unknown, newValue: unknown) => {
  const hasUnsavedChanges = useAppSelector(state => state.hasUnsavedChanges)

  const hasUnsavedChangesRef = useRef(false)

  const resetUnsavedChanges = useCallback(() => {
    store.dispatch(resetHasUnsavedChanges())
  }, [])

  useEffect(() => {
    hasUnsavedChangesRef.current = !!newValue && !_.isEqual(JSON.stringify(newValue), JSON.stringify(value))
    store.dispatch(setHasUnsavedChanges(hasUnsavedChangesRef.current))

    return resetUnsavedChanges
  }, [newValue, resetUnsavedChanges, value])

  return {
    hasUnsavedChanges,
    resetUnsavedChanges,
  }
}

export const HowtoMessageThresholdLimits = () => {
  return (
    <>
      <p>
        The message threshold limits are located in the general Settings page. To access it, just click on the button in
        the sidebar menu.
      </p>
      <p>
        On the Settings page, within the "Threshold message limits" section, you have the option to specify the maximum
        number of messages a target can receive in a day, week, or month, considering the total across all notification
        channels.
      </p>
      <p>
        The preset values are <b>5 for daily, 20 for weekly, and 30 for monthly</b>, but you have the flexibility to
        modify these settings. If you prefer no restrictions, simply leave the field empty.
      </p>
      <p className="border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium">
        <i>
          Be mindful of these limits, as they encompass the total notifications from all available message channels,
          including SMS, Push Notifications, and others.
        </i>
      </p>
      <p>
        For example, if you set the <b>daily limit to 1</b>, it means that a target can only receive 1 message per day,
        regardless of the channel.
      </p>
      <h4 className="py-4 text-lg font-medium">Let's see some other use cases:</h4>
      <ul className="font-mono text-xs">
        <li>Max messages per day: 5</li>
        <li>Max messages per week: 20</li>
        <li>Max messages per month: unrestricted</li>
      </ul>
      <p>
        In this scenario, it means that a target can receive up to 5 messages per day, until the weekly limit of 20
        messages in total per week is reached.
      </p>
      <ul className="font-mono text-xs">
        <li>Max messages per day: unrestricted</li>
        <li>Max messages per week: 20</li>
        <li>Max messages per month: 30</li>
      </ul>
      <p>
        In this scenario, it means that a target can receive up to 20 messages, until the weekly limit of 20 messages in
        total is reached, no matter if all of them were received in just a single day or in different days in a week
        window. And then, it can receive up to 10 more messages until the monthly limit of 30 messages is reached.
      </p>
    </>
  )
}

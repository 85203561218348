import {FeatureConfig} from '@/config/featureToggles'
import {FeatureToggle, setFeatureFlagOnStorage} from '@/helpers/featureToggle'
import {isSUT} from '@/utils/env'

export const getCountries = () => {
  const countriesByEnv = import.meta.env[FeatureConfig.countries] as string
  if (!countriesByEnv) throw new Error(`${FeatureConfig.countries} is not defined`)

  const countriesByStorage = isSUT() ? sessionStorage.getItem(FeatureConfig.countries) : null

  const countries = countriesByStorage ?? countriesByEnv
  return countries.split(',').map(country => country.trim())
}

const setDefaultValues = () => {
  Object.values(FeatureToggle).forEach(key => {
    setFeatureFlagOnStorage(key, import.meta.env[key] === 'true')
  })
}
setDefaultValues()

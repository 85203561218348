import {BorderlessButton, PrimaryButton, SecondaryButton} from '@/shared-components/Button'

export interface DrawerNotificationButtonGroupsProps {
  hasSecondaryButton?: boolean
  hasTertiaryButton?: boolean
  testIdPrimary: string
  testIdSecondary?: string
  testIdTertiary?: string
  primaryButtonLabel: string
  secondaryButtonLabel?: string
  tertiaryButtonLabel?: string
  onClickPrimary: () => void
  onClickSecondary?: () => void
  onClickTertiary?: () => void
  isPrimaryDisabled?: boolean
  isSecondaryDisabled?: boolean
  isTertiaryDisabled?: boolean
}

export default function DrawerNotificationButtonGroups({
  hasSecondaryButton = false,
  hasTertiaryButton = false,
  testIdPrimary,
  testIdSecondary,
  testIdTertiary,
  primaryButtonLabel,
  secondaryButtonLabel,
  tertiaryButtonLabel,
  onClickPrimary,
  onClickSecondary,
  onClickTertiary,
  isPrimaryDisabled = false,
  isSecondaryDisabled = false,
  isTertiaryDisabled = false,
}: DrawerNotificationButtonGroupsProps): React.JSX.Element {
  return (
    <div className="absolute bottom-0 left-0 flex h-16 w-full items-center justify-end gap-4 border-t bg-white px-6">
      {hasTertiaryButton && (
        <BorderlessButton
          data-testid={testIdTertiary}
          data-cy={testIdTertiary}
          onClick={onClickTertiary}
          disabled={isTertiaryDisabled}
        >
          {tertiaryButtonLabel}
        </BorderlessButton>
      )}
      {hasSecondaryButton && (
        <SecondaryButton
          data-testid={testIdSecondary}
          data-cy={testIdSecondary}
          onClick={onClickSecondary}
          disabled={isSecondaryDisabled}
        >
          {secondaryButtonLabel}
        </SecondaryButton>
      )}
      {primaryButtonLabel && (
        <PrimaryButton
          data-testid={testIdPrimary}
          data-cy={testIdPrimary}
          onClick={onClickPrimary}
          disabled={isPrimaryDisabled}
        >
          {primaryButtonLabel}
        </PrimaryButton>
      )}
    </div>
  )
}

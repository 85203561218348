import {setInitialNodeParamFiltersData} from '@ReduxActions'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {store} from '../../../../store'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {removeNodeAndConnectItsParentAndTarget} from '../../utils/NodeEventUtils'
import {CustomNodeModel} from '../CustomNodeModel'
import {VariantPortModel} from './VariantPortModel'

// this can be further extended for more complicated node types
export class VariantNodeModel extends CustomNodeModel {
  constructor(id?: string) {
    const obj = {
      type: NodeTypeEnum.variant,
      id,
      extras: {
        name: 'Variant: Yes / No',
        priority: 2,
        nodeSize: 200,
      },
    }
    if (!id) delete obj.id

    super(obj)

    this.addPort(new VariantPortModel(PortModelAlignment.TOP))
    this.addPort(new VariantPortModel(PortModelAlignment.LEFT))
    this.addPort(new VariantPortModel(PortModelAlignment.RIGHT))

    store.dispatch(
      setInitialNodeParamFiltersData({
        id: this.getID(),
        type: NodeTypeEnum.variant,
      })
    )
  }

  remove() {
    removeNodeAndConnectItsParentAndTarget(this.getID(), () => super.remove())
  }
}

import {NodeHasWarningsMessage} from '@/components/Canvas/customNodes/NodeHasWarnings'
import {StepExecutionStatus} from '@/components/Canvas/customNodes/StepExecutionStatus'
import {StepNodeActions} from '@/components/Canvas/customNodes/StepNodeActions'
import {StepNodeInfos} from '@/components/Canvas/customNodes/StepNodeInfos'
import {cn} from '@/helpers/shadcn/utils'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {JourneyTypeSchema} from '@/types/JourneyType'
import {setCurrentNode, setSelectedStepId} from '@ReduxActions'
import {DiagramEngine} from '@projectstorm/react-diagrams'
import classNames from 'classnames'
import {PropsWithChildren, useCallback} from 'react'
import {store, useAppSelector} from '../../../store'
import {NodeTypeEnum} from '../../../types/NodeTypeEnum'
import CustomPortWidget from '../customPort/CustomPortWidget'
import {CustomNodeModel} from './CustomNodeModel'
import {CustomPortModel} from './CustomPortModel'

export type CustomNodeWidgetProps = PropsWithChildren & {
  engine: DiagramEngine
  node: CustomNodeModel
  portTop?: CustomPortModel | null
  portBottom?: CustomPortModel | null
  portLeft?: CustomPortModel | null
  portRight?: CustomPortModel | null
  drawerDisabled?: boolean
  haveWarnings?: boolean
  isSelected?: boolean
  classNameWidgetWrapper?: string
}

export const CustomNodeWidget = ({
  engine,
  node,
  portTop,
  portBottom,
  portLeft,
  portRight,
  children,
  drawerDisabled,
  isSelected,
  haveWarnings,
  classNameWidgetWrapper,
}: CustomNodeWidgetProps) => {
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const nodeType = node.getType() as NodeTypeEnum
  const {nodeSize} = node.getOptions().extras

  const nodeExecutions = useAppSelector(state => {
    if (!isPublishedOrCompleted) return

    const nodeId = node.getID()
    const executions = state.journeyExecutionStatus.executions

    return nodeType === NodeTypeEnum.entryCondition ? executions : executions?.steps[nodeId]
  })

  const openStepConfig = useCallback(() => {
    if (drawerDisabled) return

    const {id, extras, type} = node.getOptions()
    if (!id) {
      throw new Error('Node id is not defined!')
    }

    store.dispatch(
      setCurrentNode({
        id,
        name: extras.name,
        type: type as NodeTypeEnum,
      })
    )
    node.setSelected(false)
  }, [drawerDisabled, node])

  const openStepSelector = useCallback(() => {
    const {id} = node.getOptions()
    if (!id) {
      throw new Error('Node id is not defined!')
    }

    store.dispatch(setSelectedStepId({id}))
    store.dispatch(setCurrentNode({id}))
  }, [node])

  const shouldDisplayExportRecords = nodeType === NodeTypeEnum.requiresIntervention && isPublishedOrCompleted

  return (
    <div className="relative h-fit w-fit max-w-sm" style={{minWidth: nodeSize}}>
      <div className={'relative'} onDoubleClick={openStepConfig} data-testid="step-node" data-cy="step-node">
        <div
          className={cn(classNameWidgetWrapper, [
            {
              selected: isSelected,
              warning: haveWarnings,
            },
            'flex flex-col gap-2',
          ])}
        >
          {children}
          <NodeHasWarningsMessage haveWarnings={haveWarnings} />
          <StepNodeInfos node={node} openStepConfig={openStepConfig} />
          <StepExecutionStatus info={nodeExecutions?.status} shouldDisplayExportRecords={shouldDisplayExportRecords} />
        </div>

        <StepNodeActions node={node} openStepConfig={openStepConfig} />
      </div>

      {portTop && <CustomPortWidget className="node-port node-port-top" port={portTop} engine={engine} />}
      {portBottom && <PortBottom engine={engine} portBottom={portBottom} openStepSelector={openStepSelector} />}

      {portLeft && (
        <CustomPortWidget
          className="node-port node-port-left"
          port={portLeft}
          engine={engine}
          onClick={() => {
            console.error('TODO: Adding a step after a Variant step is not implemented!')
            // Disabled to prevent unhandled errors
            // node.openStepSelector()
          }}
        />
      )}
      {portRight && (
        <CustomPortWidget
          className="node-port node-port-right"
          port={portRight}
          engine={engine}
          onClick={() => {
            console.error('TODO: Adding a step after a Variant step is not implemented!')
            // Disabled to prevent unhandled errors
            // node.openStepSelector()
          }}
        />
      )}
    </div>
  )
}

type PortBottomProps = {
  engine: DiagramEngine
  portBottom: CustomPortModel
  openStepSelector: () => void
}

const PortBottom = ({engine, portBottom, openStepSelector}: PortBottomProps) => {
  const {isPublishedOrCompleted, type} = useJourneyMetaConfig()

  const isOneTimeJourney = type === JourneyTypeSchema.enum.ONE_TIME
  const hasStepsAdded = useAppSelector(state => {
    return Object.values(state.nodesRelations.nodes).length > 1
  })

  const canAddSteps = !isPublishedOrCompleted && (!isOneTimeJourney || !hasStepsAdded)

  const onDidClick = () => {
    if (!canAddSteps) return
    openStepSelector()
  }

  return (
    <CustomPortWidget
      className={classNames('node-port node-port-bottom', [{published: !canAddSteps}])}
      port={portBottom}
      isPortBottom={true}
      isPublished={isPublishedOrCompleted}
      isAddPort={canAddSteps}
      engine={engine}
      onClick={onDidClick}
    />
  )
}

import {canvasApp} from '@/components/Canvas/Application/Application'
import {CustomNodeModel} from '@/components/Canvas/customNodes/CustomNodeModel'
import {AddStepTypeSelector} from '@/components/Drawer/AddStepTypeSelector'
import DrawerBottomButtons from '@/components/Drawer/ContentParams/DrawerBottomButtons'
import {useJourneyDrawer} from '@/components/Drawer/hooks/useJourneyDrawer'
import {useSaveNodeData} from '@/helpers/SaveNodeDataProvider/useNodeDataContext.hook'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {JourneyStatus} from '@/types/JourneyStatus'
import {NodeTypeEnum, isNodeType} from '@/types/NodeTypeEnum'
import {resetCurrentNode, resetStepsSelector, setCurrentNode, setJourneyStatus} from '@ReduxActions'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {store, useAppSelector} from '../../store'
import {ContentParamsProvider} from './ContentParamsProvider'

export const DrawerContent = () => {
  const {formatMessage} = useIntl()
  const {type, id} = useAppSelector(state => state.currentNode)
  const {selectedStepId = ''} = useAppSelector(state => state.stepsSelector)
  const nodeData = useAppSelector(state => state.nodesData[id])
  const {onCloseDrawer, checkAndUpdateNodeWarnings} = useJourneyDrawer()
  const {isNew, type: journeyType} = useJourneyMetaConfig()

  const isLastStep = useAppSelector(state => {
    if (!selectedStepId) return false
    return !state.nodesRelations.nodes[selectedStepId]?.target
  })

  const [newStep, setNewStep] = useState<CustomNodeModel>()
  const {hasUnsavedChanges, saveData} = useSaveNodeData()

  const onCancelDidClick = () => {
    if (isNew) {
      onCloseDrawer()
      return
    }

    setNewStep(undefined)

    type && checkAndUpdateNodeWarnings(id, type, nodeData)
    store.dispatch(resetCurrentNode())
    store.dispatch(resetStepsSelector())
  }

  const onAddStepDidClick = () => {
    if (!newStep?.getID() || !selectedStepId) return

    canvasApp.insertLinkedStep(newStep.getType() as NodeTypeEnum, selectedStepId, newStep.getID(), undefined, newStep)

    saveData()
    store.dispatch(resetCurrentNode())
    store.dispatch(resetStepsSelector())
    setNewStep(undefined)
  }

  const onContinueOrSaveDidClick = () => {
    saveData()
    store.dispatch(resetCurrentNode())
    store.dispatch(resetStepsSelector())
    store.dispatch(setJourneyStatus(JourneyStatus.draft))
  }

  const onSelectedStepTypeToAddDidChange = (selectedStepType: NodeTypeEnum) => {
    if (!isNodeType(selectedStepType)) {
      // Return to initial state when no valid type is selected
      store.dispatch(setCurrentNode({id: selectedStepId, type: undefined}))
      return
    }

    const newStepToAdd = new canvasApp.nodeFactory[selectedStepType]()
    store.dispatch(
      setCurrentNode({
        id: newStepToAdd.getID(),
        type: selectedStepType,
      })
    )
    setNewStep(newStepToAdd)
  }

  const isAddingNewStep = !!selectedStepId

  const newStepData = useAppSelector(state => state.nodesData[`${newStep?.getID()}`])
  const currentStepData = useAppSelector(state => state.nodesData[id])

  const haveWarnings = isAddingNewStep ? newStepData?.haveWarnings : currentStepData?.haveWarnings
  const isAddNewStepButtonDisabled = !type || haveWarnings

  const showEditingStep = shouldShowEditingStep(isNew, isAddingNewStep, type)
  const isEditSaveButtonDisabled = !hasUnsavedChanges || haveWarnings

  return (
    <div
      className="mb-12 min-h-full overflow-y-auto px-2 pb-28 text-text-secondary"
      data-cy={`drawer-content-step-${type}`}
    >
      {isAddingNewStep && (
        <>
          <h2 className="mb-2 font-bold" data-testid="add-step-header">
            {formatMessage({id: 'JOURNEY_BUILDER.ADD_NEW_STEP_HEADER'})}
          </h2>
          <p className="mb-4 text-sm">
            {formatMessage({
              id: 'JOURNEY_BUILDER.ADD_NEW_STEP_SUBHEADER',
            })}
          </p>
          <AddStepTypeSelector
            isLastStep={isLastStep}
            stepType={type}
            onChange={onSelectedStepTypeToAddDidChange}
            journeyType={journeyType}
          />
        </>
      )}

      <ContentParamsProvider type={type} />

      {isNew && (
        <DrawerBottomButtons
          testIdPrimary="continue-button"
          testIdSecondary="cancel-button"
          onClickPrimary={onContinueOrSaveDidClick}
          onClickSecondary={onCancelDidClick}
          primaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.CONTINUE_BUTTON'})}
          secondaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.CANCEL_BUTTON'})}
          isPrimaryDisabled={haveWarnings}
        />
      )}

      {isAddingNewStep && (
        <DrawerBottomButtons
          testIdPrimary="add-button"
          testIdSecondary="cancel-button"
          onClickPrimary={onAddStepDidClick}
          onClickSecondary={onCancelDidClick}
          primaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.ADD_STEP_BUTTON'})}
          secondaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.CANCEL_BUTTON'})}
          isPrimaryDisabled={isAddNewStepButtonDisabled}
        />
      )}

      {showEditingStep && (
        <DrawerBottomButtons
          testIdPrimary="save-button"
          testIdSecondary="cancel-button"
          onClickPrimary={saveData}
          onClickSecondary={onCancelDidClick}
          primaryButtonLabel={formatMessage({id: 'GENERAL.SAVE_BUTTON'})}
          secondaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.CANCEL_BUTTON'})}
          isPrimaryDisabled={isEditSaveButtonDisabled}
        />
      )}
    </div>
  )
}

const shouldShowEditingStep = (isNew: boolean, isAddingNewStep: boolean, type?: NodeTypeEnum) => {
  if (!type) return false
  const isEligibleStep = ![NodeTypeEnum.variant, NodeTypeEnum.requiresIntervention].includes(type)
  return isEligibleStep && !isNew && !isAddingNewStep
}

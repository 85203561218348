import {operatorsMapValueType} from '@/components/NotificationParamsFilterCard/NotificationParamCard'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {
  EventValuesType,
  OperatorDetailsType,
  OperatorsType,
  ParameterItem,
  TraitItemType,
  TraitsType,
  ValuesType,
} from '@/types/Traits'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  readonly events: TraitsType
  readonly eventOperators: OperatorsType
  readonly eventSelectors: ParameterItem
  readonly setEventSelectors: (value: ParameterItem | ((prevValue: ParameterItem) => ParameterItem)) => void
}

export default function NotificationEventCard({
  events,
  eventOperators,
  eventSelectors,
  setEventSelectors,
}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()

  // key is event id, value is list of all event operator details
  const eventOperatorMap = new Map<number, OperatorDetailsType[]>()
  // key is event id, value is trait name
  const eventMap = new Map<number, string>()
  // key is operator id, value is operator name
  const operatorMap = new Map<number, operatorsMapValueType>()
  // key is event id, value is list of all values
  const eventValueMap = new Map<number, ValuesType>()

  const [event, setEvent] = useState<number>(eventSelectors?.trait?.id ?? -1)
  const [opeartor, setOperator] = useState<number>(eventSelectors?.operator?.id ?? -1)
  const [eventValue, setEventValue] = useState<number>(eventSelectors?.predefinedValue?.id ?? -1)
  const [operatorList, setOperatorList] = useState<OperatorDetailsType[]>([])
  const [valueList, setValueList] = useState<EventValuesType>([])

  events?.map((event: TraitItemType) => {
    const structuredOperators = event.operators?.map((operatorCode: string) => {
      return eventOperators[operatorCode]
    })
    eventMap.set(event.id, event.name)
    eventOperatorMap.set(event.id, structuredOperators)
    eventValueMap.set(event.id, event.values)
    return {id: event.id, name: event.name}
  })

  Object.values(eventOperators).forEach(value => {
    operatorMap.set(value.id, {name: value.name, operatorType: value.operatorType})
  })

  const eventOptions = events?.map((event: TraitItemType) => (
    <option value={event.id} key={event.id}>
      {event.name}
    </option>
  ))

  const operatorOptions = operatorList?.map(operator => (
    <option value={operator.id} key={operator.id}>
      {operator.name}
    </option>
  ))

  const valueOptions = valueList?.map(item => (
    <option value={item.id} key={item.id}>
      {item.value}
    </option>
  ))

  useEffect(() => {
    handleEventChange(event)
    handleOperatorChange(opeartor)
    handleValueChange(eventValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEventChange = function (selectedEvent: number) {
    const list = eventOperatorMap.get(selectedEvent) ?? []
    const operatorId = list[0]?.id ?? -1
    setEvent(selectedEvent)
    setOperator(operatorId)

    setOperatorList(list)
    const valueList = eventValueMap.get(selectedEvent) ?? []
    setValueList(valueList)
    const valueId = valueList?.[0]?.id ?? ''
    setEventValue(valueId)
    const eventParam = {
      id: selectedEvent,
      name: eventMap.get(selectedEvent) ?? '',
    }
    const opeartorParam = {
      id: operatorId,
      name: operatorMap.get(operatorId)?.name ?? '',
    }
    const predefinedValueParam = {
      id: valueId,
      name: valueList?.[0]?.value ?? '',
    }
    setEventSelectors({
      ...eventSelectors,
      trait: eventParam,
      operator: opeartorParam,
      predefinedValue: predefinedValueParam,
    })
  }

  const handleOperatorChange = function (selectedOperator: number) {
    const newOperator = {
      id: selectedOperator,
      name: operatorMap.get(selectedOperator)?.name ?? '',
    }
    setEventSelectors({...eventSelectors, operator: newOperator})
    setOperator(selectedOperator)
  }

  const handleValueChange = function (selectedValue: number) {
    const selectedItem = valueList.find(item => item.id === selectedValue)
    const selectedValueName = selectedItem?.value ?? ''
    const predefinedValueParam = {
      id: selectedValue,
      name: selectedValueName,
    }
    setEventSelectors({...eventSelectors, predefinedValue: predefinedValueParam})
    setEventValue(selectedValue)
  }

  return (
    <div className="h-42 flex w-full flex-wrap gap-6" data-testid="notification-event-container">
      <div className="flex w-full max-w-[300px] gap-2">
        <InputSelector
          id="event"
          value={event}
          onChange={e => handleEventChange(Number(e.target.value))}
          label={formatMessage({id: 'NOTIFICATION_BUILDER.EVENT_LABEL'})}
          placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.SELECT_EVENT'})}
          required
        >
          <option hidden>{formatMessage({id: 'NOTIFICATION_BUILDER.SELECT_EVENT'})}</option>
          {eventOptions}
        </InputSelector>
      </div>
      <div className="flex w-full max-w-[300px] gap-2">
        <InputSelector
          id="event-operator"
          value={opeartor}
          onChange={e => handleOperatorChange(Number(e.target.value))}
          label={formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_OPERATOR'})}
          required
        >
          <option hidden>{formatMessage({id: 'NOTIFICATION_BUILDER.SELECT_EVENT_OPERATOR'})}</option>
          {operatorOptions}
        </InputSelector>
      </div>
      <div className="flex w-full max-w-[300px] gap-2">
        <InputSelector
          id="event-value"
          value={eventValue}
          onChange={e => handleValueChange(Number(e.target.value))}
          label={formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_VALUE'})}
          required
        >
          <option hidden>{formatMessage({id: 'NOTIFICATION_BUILDER.SELECT_EVENT_VALUE'})}</option>
          {valueOptions}
        </InputSelector>
      </div>
    </div>
  )
}

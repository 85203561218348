import {JourneysList} from '@/pages/JourneysList'
import {useIntl} from 'react-intl'

export const Home = () => {
  const {formatMessage} = useIntl()

  return (
    <div className="mt-5 flex flex-col">
      <p className="mb-4 text-3xl font-bold" data-cy="welcome-message">
        {formatMessage(
          {id: 'JOURNEY_LIST.WELCOME_MESSAGE'},
          {
            userName: 'User',
          }
        )}
      </p>
      <JourneysList />
    </div>
  )
}

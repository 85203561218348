import {NodeTypeEnum} from '../../types/NodeTypeEnum'
import {OperatorsSchema, ParamFilters} from '../../types/paramFilters/paramFilters'

export const initialParamsFiltersConfig: ParamFilters = {
  id: '001',
  type: NodeTypeEnum.entryCondition,
  reentryDays: null,
  paramsSelectors: [
    {
      id: 0,
      selectedParam: '',
      selectedParamType: '',
      conditional: null,
      selectedParamData: {
        operator: OperatorsSchema.Values.lessThan,
        value: null,
      },
    },
  ],
}

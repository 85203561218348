import {TimeExecutionsSelectorItem} from '@/components/Drawer/ContentParams/TimeExecutionsSelectorItem'
import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {cn} from '@/helpers/shadcn/utils'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {TimeExecutionWindow, TimeExecutionWindows} from '@/types/TimeExecution'
import {JourneyConfigInitialState} from '@ReduxActions'
import {uniqueId} from 'lodash'
import {ComponentPropsWithoutRef} from 'react'
import {useIntl} from 'react-intl'

export type TimeExecutionsSelectorProps = Omit<ComponentPropsWithoutRef<'select'>, 'value' | 'onChange'> & {
  id?: string
  value?: TimeExecutionWindows
  onChange?: (value?: TimeExecutionWindows) => void
}

export const TimeExecutionsSelector = ({
  value,
  onChange,
  id = 'time-executions-selector',
  ...props
}: TimeExecutionsSelectorProps) => {
  const {formatMessage} = useIntl()

  const onAddNew = () => {
    onChange?.([...(value ?? []), {start: '', end: undefined}])
  }

  const onRemove = (position: number) => {
    const values: TimeExecutionWindows = [...(value ?? [])].filter((_, index) => index !== position)
    const isEmpty = !values[0]?.start
    onChange?.(isEmpty ? JourneyConfigInitialState.timeExecutionWindows : values)
  }

  const onChangeValue = (newValue: TimeExecutionWindow, position: number) => {
    const [startHour, startMinute] = (newValue.start?.split(':') ?? []).map(Number)
    const [endHour, endMinute] = (newValue.end?.split(':') ?? []).map(Number)
    const isEndGreaterThanStart = !newValue.start
      ? false
      : endHour > startHour || (endHour === startHour && endMinute > startMinute)
    const adjustedValue = {...newValue, end: isEndGreaterThanStart ? newValue.end : undefined}

    const newTimeExecutionWindows = [...(value ?? [])]
    newTimeExecutionWindows[position] = adjustedValue
    onChange?.(newTimeExecutionWindows)
  }

  if (!isFeatureEnabled(FeatureToggle.timeExecutionWindow)) {
    return null
  }

  const canEdit = !props.disabled
  const isEmpty = !value?.length
  const canAddMore = canEdit && isEmpty

  if (!canEdit && isEmpty) {
    return null
  }

  return (
    <div className={cn('flex flex-col gap-2 text-text-secondary', props.className)} data-testid={id} data-cy={id}>
      <p className="text-sm font-bold">{formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_TITLE'})}</p>

      <div className="flex flex-col gap-4 rounded-lg border-2 border-dashed border-blue bg-layer-02 p-4 transition-all duration-300 hover:border-layer-03">
        {value?.map(({start, end}, position) => {
          const key = start && end ? `${start}_${end}` : uniqueId()

          return (
            <TimeExecutionsSelectorItem
              key={key}
              start={start}
              end={end}
              position={position}
              onChange={onChangeValue}
              onRemove={onRemove}
              disabled={!canEdit}
            />
          )
        })}

        {canAddMore && (
          <AddNewParameterButton
            data-testid="add-new-time-execution-button"
            data-cy="add-new-time-execution-button"
            onClick={onAddNew}
            className="flex w-full max-w-[280px]"
          >
            {formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_ADD_NEW_BUTTON'})}
          </AddNewParameterButton>
        )}
      </div>
    </div>
  )
}

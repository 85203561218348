import {GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {SendWhatsAppNodeModel} from './SendWhatsAppNodeModel'
import {SendWhatsAppNodeWidget} from './SendWhatsAppNodeWidget'

export class SendWhatsAppNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.sendWhatsApp)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <SendWhatsAppNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new SendWhatsAppNodeModel()
  }
}

import {OKTA_TOKEN_STORAGE_KEY} from '@/config/constants'
import {AuthState, UserClaims} from '@okta/okta-auth-js'

export type ClaimsProps = UserClaims & {
  FirstName?: string
  LastName?: string
}

export const useOktaStorage = () => {
  const oktaTokenStorage = localStorage.getItem(OKTA_TOKEN_STORAGE_KEY)
  const tokenData = oktaTokenStorage ? (JSON.parse(oktaTokenStorage) as AuthState) : null

  return {
    ...tokenData,
    claims: (tokenData?.accessToken?.claims || {}) as ClaimsProps,
  }
}

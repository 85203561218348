import {cn} from '@/helpers/shadcn/utils'
import {useIntl} from 'react-intl'

type FieldLabelProps = {
  id?: string
  label?: string
  required?: boolean
  disabled?: boolean
}

export const FieldLabel = ({id, label, required, disabled}: FieldLabelProps) => {
  const {formatMessage} = useIntl()

  return (
    (label || required) && (
      <div
        className={cn('flex w-full justify-between text-xs text-text-secondary', disabled ? 'text-text-disabled' : '')}
      >
        <label htmlFor={id}>{label}</label>
        {required && <span className="text-[10px] font-bold">{formatMessage({id: 'GENERAL.REQUIRED_FIELD'})}</span>}
      </div>
    )
  )
}

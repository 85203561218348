import {StepExecutionStatusItem} from '@/components/Canvas/customNodes/StepExecutionStatus'
import {useIntl} from 'react-intl'

export const HowtoExecutionStatus = () => {
  const {formatMessage} = useIntl()

  return (
    <>
      <p>
        <b>After the Journey is published</b>, you can track its progress by accessing the Journey viewer screen. The
        status will be displayed within each step after the journey is processed and will be updated every time a new
        execution event occurs. Additionally, you can download a detailed report of the Journey execution by clicking on
        the <i> "Download execution report" </i> button.
      </p>
      <p>
        In the <i> {formatMessage({id: 'JOURNEY_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})} </i> step, you can check the
        overall Journey execution status. Beware that the system does not yet display data for Delivery Rate, Open Rate,
        Click-Through Rate (CTR), and Conversion Rate. The statuses mentioned above are exclusively related to the CCT
        system. For each step, you can see an overview of the individual step based on the status table provided below:
      </p>
      <table>
        <thead>
          <tr>
            <th className="w-1/4">Type</th>
            <th className="w-1/4">Status</th>
            <th className="w-1/2">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatMessage({id: 'JOURNEY_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})}</td>
            <td>
              <StepExecutionStatusItem status="CREATED" />
            </td>
            <td>
              The system has detected that the template was published. It identified all records* that matched the entry
              criteria and created an executable for each audience target**
            </td>
          </tr>
          <tr>
            <td>{formatMessage({id: 'JOURNEY_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})}</td>
            <td>
              <StepExecutionStatusItem status="RUNNING" />
            </td>
            <td>
              The system is currently executing a step for at least one executable for an audience target**. This step
              can be either a notification or a "{formatMessage({id: 'JOURNEY_BUILDER.WAIT_WIDGET_LABEL'})}" step.
            </td>
          </tr>
          <tr>
            <td>{formatMessage({id: 'JOURNEY_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})}</td>
            <td>
              <StepExecutionStatusItem status="COMPLETED" />
            </td>
            <td>
              Indicates the number of audience target executables for which the system has finished executing all the
              steps in the template
            </td>
          </tr>

          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="CREATED" />
            </td>
            <td>
              The system has created the executable for the step in question for the corresponding audience target.
            </td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="RUNNING" />
            </td>
            <td>The system is currently executing the displayed quantity of the step's executable.</td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="EXECUTED" />
            </td>
            <td>The system has completed the displayed quantity of the step's executable.</td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="ON_HOLD" />
            </td>
            <td>
              The system has paused the displayed quantity of the step's executable because the threshold limit has been
              reached. Execution will resume once the limit is within the allowed range.
            </td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="SKIPPED" />
            </td>
            <td>
              The system skipped the displayed quantity of the step's executable because the user linked to the
              respective store's executable opted out of receiving notifications through that channel.
            </td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="FAILED" />
            </td>
            <td>
              The system attempted to execute the displayed quantity of the step's executable, but it encountered a
              failure. Depending on the type of failure, the system may try to execute it again.
            </td>
          </tr>
          <tr>
            <td>Step</td>
            <td>
              <StepExecutionStatusItem status="INTERRUPTED" />
            </td>
            <td>
              The system will not execute the displayed quantity of the step's executables because the records no longer
              meet the entry criteria, preventing these customers from receiving irrelevant messages.
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        * A record can be a store or any other entity.
        <br />
        ** An audience target is a store or any other entity.
      </p>
    </>
  )
}

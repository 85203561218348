import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {JourneyType} from '@/types/JourneyType'
import {useIntl} from 'react-intl'

interface Props {
  type?: JourneyType
}

export const JourneyTypeView: React.FC<Props> = ({type}) => {
  const shouldDisplay = isFeatureEnabled(FeatureToggle.journeyType) && !!type
  const {formatMessage} = useIntl()

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex w-full flex-col gap-1">
      <p className="text-xs font-bold text-text-secondary">
        {formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_LABEL'})}
      </p>
      <p className="text-xs text-text-primary" data-testid="journey-meta-type" data-cy="journey-meta-type">
        {formatMessage({id: `JOURNEY_BUILDER.JOURNEY_TYPE_${type}`})}
      </p>
    </div>
  )
}

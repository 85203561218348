import EventListTable from '@/components/EventListTable/EventListTable'
import {useIntl} from 'react-intl'
import {PrimaryButton} from '../shared-components/Button'
import {PageHeader} from '../shared-components/PageHeader'
import {PageContent} from './Template/components/PageContent'

interface Props {
  setIsOpen: (value: boolean | ((prevValue: boolean) => boolean)) => void
}

export default function NotificationList({setIsOpen}: Props): React.JSX.Element {
  const {formatMessage} = useIntl()
  const onClickCreate = () => {
    setIsOpen(true)
  }
  return (
    <PageContent data-testid="notification-list-page">
      <div className="mb-6">
        <PageHeader title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_HEADER'})} />
        <div className="grid-col-12 grid gap-4 text-base" data-testid="subtitle-content">
          <div className="subtitle grid flex-1 text-sm text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_LIST.TABLE_HEADER_SUBTITLE'})}
          </div>
          <div className="button-container ml-auto grid grid-cols-subgrid gap-4">
            <div className="justify-self-end">
              <PrimaryButton
                size="small"
                data-cy="button-create-notification"
                data-testid="button-create-notification"
                onClick={onClickCreate}
              >
                {formatMessage({id: 'NOTIFICATION_LIST.CREATE_NOTIFICATION_BUTTON'})}
              </PrimaryButton>
            </div>
          </div>
        </div>
        <EventListTable />
      </div>
    </PageContent>
  )
}

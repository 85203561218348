import {cn} from '@/helpers/shadcn/utils'
import {Icon} from 'pepsico-ds'
import {ComponentPropsWithoutRef} from 'react'
import {useIntl} from 'react-intl'

type AddNewParameterButtonProps = ComponentPropsWithoutRef<'button'>

export const AddNewParameterButton = ({onClick, className, children, ...props}: AddNewParameterButtonProps) => {
  const {formatMessage} = useIntl()

  return (
    <button
      {...props}
      className={cn(
        'shadow-none group flex w-full items-center justify-between gap-4 text-nowrap rounded-md border-2 border-dashed border-blue bg-layer-02 py-2 pl-6 pr-2 text-xs text-button-primary transition-all duration-200 hover:border-layer-03 hover:text-link-hover focus:outline-none active:outline-none',
        className
      )}
      onClick={onClick}
    >
      {children ?? formatMessage({id: 'GENERAL.ADD_NEW_PARAMETER_BUTTON'})}
      <Icon icon="add" size="small" />
    </button>
  )
}

import {defaultEventValue} from '@/components/NotificationParamsFilterCard/NotificationEventDefaultValue'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {EventStatus} from '@/types/Notifications'
import {Period} from '@/types/Period'
import {TimeExecutionWindowNotification} from '@/types/TimeExecution'
import {ParameterItem, Parameters} from '@/types/Traits'
import {useIntl} from 'react-intl'

export interface NotificationDetailsProps {
  readonly eventStatus?: EventStatus
  readonly notificationName?: string
  readonly notificationDescription?: string
  readonly notificationType?: string
  readonly eventSelectors?: ParameterItem
  readonly paramSelectors?: Parameters
  readonly event?: string
  readonly country?: string
  readonly period?: Period
  readonly timeExecutionWindow?: TimeExecutionWindowNotification
  readonly fallsOnBankHoliday?: string
  readonly subject?: string
  readonly message?: string
}

export default function NotificationDetails({
  notificationName,
  notificationDescription,
  eventSelectors = defaultEventValue,
  paramSelectors = [],
  country = '',
  period,
  timeExecutionWindow,
  fallsOnBankHoliday,
}: NotificationDetailsProps) {
  const {formatMessage} = useIntl()

  const parameterDetails = paramSelectors.map(item => {
    return (
      <div key={item.id} className="mt-8 rounded-lg border border-blue-500 p-4">
        <div className="mb-2 text-lg font-bold">{item.title}</div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_TRAIT'})}
          </div>
          <div className="mb-3 mt-1">{item.trait?.name ?? ''}</div>
        </div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_OPERATOR'})}
          </div>
          <div className="mb-3 mt-1">{item.operator?.name ?? ''}</div>
        </div>
        <div className="border-t border-border-gray text-xs">
          <div className="mb-1 mt-3 font-bold">
            {formatMessage({id: 'NOTIFICATION_DETAILS.PARAMETER_CONDITION_VALUE'})}
          </div>
          <div className="mb-3 mt-1">{item.value ?? item.predefinedValue?.name}</div>
        </div>
      </div>
    )
  })

  return (
    <div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_NAME'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{notificationName}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DESCRIPTION'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{notificationDescription}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.COUNTRY'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{country}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.EVENT'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{eventSelectors?.trait?.name ?? ''}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.EVENT_OPERATOR'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{eventSelectors?.operator?.name ?? ''}</div>
      </div>
      <div className="border-b border-border-gray">
        <div className="pt-3 text-xs font-bold text-text-secondary">
          {formatMessage({id: 'NOTIFICATION_DETAILS.EVENT_VALUE'})}
        </div>
        <div className="pb-3 pt-1 text-xs text-text-primary">{eventSelectors?.predefinedValue?.name ?? ''}</div>
      </div>

      <div className="mb-4">{parameterDetails}</div>

      {isFeatureEnabled(FeatureToggle.realTimeNotificationDuration) && period && (
        <div className="border-b border-border-gray text-xs" data-testid="rtn-duration">
          <div className="pb-1 pt-3 font-bold text-text-secondary">
            {formatMessage({id: 'NOTIFICATION_DETAILS.DURATION'})}
          </div>
          <div className="pb-3 pt-1">{formatMessage({id: 'NOTIFICATION_DETAILS.DURATION_VALUE'}, period)}</div>
        </div>
      )}

      {isFeatureEnabled(FeatureToggle.realTimeNotificationTimeExecutionWindow) && timeExecutionWindow && (
        <>
          <div className="border-b border-border-gray text-xs" data-testid="rtn-time-execution-window">
            <div className="pb-1 pt-3 font-bold text-text-secondary">
              {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_TITLE'})}
            </div>
            <div className="pb-3 pt-1">
              {formatMessage({id: 'NOTIFICATION_DETAILS.TIME_EXECUTION_WINDOW_VALUE'}, timeExecutionWindow)}
            </div>
          </div>
          {/* <div className="border-b border-border-gray text-xs">
            <div className="pb-1 pt-3 font-bold text-text-secondary">
              {formatMessage({id: 'NOTIFICATION_DETAILS.RECURRENCE_TITLE'})}
            </div>
            <div className="pb-3 pt-1">{mockData.recurrency}</div>
          </div> */}

          {fallsOnBankHoliday && (
            <div className="border-b border-border-gray text-xs" data-testid="rtn-falls-on-bank-holiday">
              <div className="pb-1 pt-3 font-bold text-text-secondary">
                {formatMessage({id: 'NOTIFICATION_DETAILS.SEND_IT_ON_TITLE'})}
              </div>
              <div className="pb-3 pt-1">{formatMessage({id: fallsOnBankHoliday})}</div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

import {publishJourneyService} from '../services/journeysService'
import {store} from '../store'
import {JourneyError} from '../types/JourneyError'
import {JourneyStatus} from '../types/JourneyStatus'
import {GetJourneyResponse} from '../types/Journeys'
import {NodeTypeEnum} from '../types/NodeTypeEnum'

export type PublishJourneyUseCaseParams = {
  id?: string
}

export async function publishJourneyUseCase({
  id,
}: PublishJourneyUseCaseParams): Promise<GetJourneyResponse | undefined> {
  if (!id) return

  if (!isStatusValid()) {
    return Promise.reject(
      new JourneyError("Journey can't be published in the current status", 'Journey can not be published')
    )
  }

  if (!hasActionStep()) {
    return Promise.reject(new JourneyError('Please add at least one action step!', 'Journey can not be published'))
  }

  if (hasWarnings()) {
    return Promise.reject(
      new JourneyError('Please fill all required configurations before publishing', 'Journey can not be published')
    )
  }

  return publishJourneyService(id)
}

const hasWarnings = () => {
  const {nodesData} = store.getState()
  return Object.values(nodesData).some(node => {
    return 'haveWarnings' in node && node.haveWarnings
  })
}

const isStatusValid = () => {
  const {status} = store.getState().journeyConfig
  return [JourneyStatus.saved].includes(status)
}

const hasActionStep = () => {
  const {nodesData} = store.getState()
  return Object.values(nodesData).some(node => {
    return [NodeTypeEnum.sendPush, NodeTypeEnum.sendSMS, NodeTypeEnum.sendWhatsApp].includes(node.type)
  })
}

export const canPublishJourney = () => {
  return !hasWarnings() && isStatusValid() && hasActionStep()
}

import {PayloadAction, createSlice} from '@reduxjs/toolkit'

type hasUnsavedChangesSliceProps = boolean

const initialState: hasUnsavedChangesSliceProps = false

const hasUnsavedChangesSlice = createSlice({
  name: 'hasUnsavedChangesSlice',
  initialState: initialState,
  reducers: {
    setHasUnsavedChanges: (_, action: PayloadAction<hasUnsavedChangesSliceProps>) => !!action.payload,
    resetHasUnsavedChanges: () => initialState,
  },
})

export const {setHasUnsavedChanges, resetHasUnsavedChanges} = hasUnsavedChangesSlice.actions

export const hasUnsavedChangesSliceReducer = hasUnsavedChangesSlice.reducer

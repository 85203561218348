import {cn} from '@/helpers/shadcn/utils'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {TemplateItem} from '@/types/StepConfigParams'
import {useIntl} from 'react-intl'

type TemplateMessageBoxProps = {
  data: TemplateItem[]
  selectedTemplateId: string
  disabled?: boolean
  onClick: (templateId: string) => void
}

export const TemplateMessageBox = ({data, selectedTemplateId, disabled, onClick}: TemplateMessageBoxProps) => {
  const {formatMessage} = useIntl()

  const error = !selectedTemplateId ? formatMessage({id: 'SEND_NOTIFICATION_CONTENT.NO_TEMPLATE_SELECTED'}) : undefined

  return (
    <div className="flex flex-col gap-4">
      <InfoOrError error={error} id="no-template-selected" />

      <div className="flex flex-wrap gap-4">
        {data.map(templateData => {
          const {id, message} = templateData
          const isSelected = id === selectedTemplateId

          return (
            <div
              key={id}
              data-testid="template-message-box"
              data-cy="template-message-box"
              data-disabled={disabled}
              className={cn(
                'message-box flex w-full max-w-xs grow bg-layer-01 data-[disabled=false]:cursor-pointer data-[disabled=false]:hover:bg-layer-01-hover',
                {isSelected}
              )}
              onClick={() => onClick(id)}
            >
              <p className="text-left text-sm leading-6 text-text-secondary">{message}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {StepSendPushNotification, StepSendSMS, StepSendWhatsApp} from '@/types/Steps'

type InitialSendPushConfig = Pick<StepSendPushNotification, 'config' | 'haveWarnings' | 'type'>
export const initialSendPushConfig: InitialSendPushConfig = {
  haveWarnings: true,
  type: NodeTypeEnum.sendPush,
  config: {
    message: '',
    to: 'storeOwner',
    subject: '',
    imageId: undefined,
    landingPageId: undefined,
  },
}

type InitialSendSMSConfig = Pick<StepSendSMS, 'config' | 'haveWarnings' | 'type'>
export const initialSendSMSConfig: InitialSendSMSConfig = {
  haveWarnings: true,
  type: NodeTypeEnum.sendSMS,
  config: {
    message: '',
    to: 'storeOwner',
  },
}

type InitialSendWhatsAppConfig = Pick<StepSendWhatsApp, 'config' | 'haveWarnings' | 'type'>
export const initialSendWhatsAppConfig: InitialSendWhatsAppConfig = {
  haveWarnings: true,
  type: NodeTypeEnum.sendWhatsApp,
  config: {
    message: '',
    to: 'storeOwner',
    template: '',
  },
}

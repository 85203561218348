import {timezones} from '@/config/constants'
import {FeatureToggle} from '@/config/featureToggles'
import {convertUtcStringToJsDate, formatJourneyPeriodDate} from '@/helpers/dateUtils'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {RecurrenceDays} from '@/types/Day'
import {Period} from '@/types/Period'
import {Recurrence, RecurrenceSchema} from '@/types/Recurrence'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

type RecurrenceSummaryViewProps = {
  value?: Recurrence
  selectedDays: RecurrenceDays
  period?: Period
}

export const RecurrenceSummaryView = ({value, selectedDays, period}: RecurrenceSummaryViewProps) => {
  const {formatMessage} = useIntl()
  const formattedRecurrenceLabel = useRecurrenceSummary({value, selectedDays, period})

  const shouldDisplay = isFeatureEnabled(FeatureToggle.recurrence) && !!value

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex w-full flex-col gap-1">
      <p className="text-xs font-bold text-text-secondary">{formatMessage({id: 'JOURNEY_BUILDER.RECURRENCE_TITLE'})}</p>
      <p className="text-xs text-text-primary" data-testid="journey-meta-recurrence" data-cy="journey-meta-recurrence">
        {formattedRecurrenceLabel}
      </p>
    </div>
  )
}

const useRecurrenceSummary = (props: RecurrenceSummaryViewProps) => {
  const formattedDaily = useSummaryMessageForDaily(props)
  const formattedWeekly = useSummaryMessageForWeekly(props)
  const formattedMonthly = useSummaryMessageForMonthly(props)

  return formattedDaily || formattedWeekly || formattedMonthly
}

const useSummaryMessageForDaily = ({value}: RecurrenceSummaryViewProps) => {
  const {formatMessage} = useIntl()

  if (value !== 'DAILY') {
    return null
  }

  return <>{formatMessage({id: `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_${value}`})}</>
}

const useSummaryMessageForWeekly = ({value, selectedDays}: RecurrenceSummaryViewProps) => {
  const {formatMessage} = useIntl()

  if (value !== RecurrenceSchema.enum.WEEKLY) {
    return null
  }

  if (!isFeatureEnabled(FeatureToggle.recurrenceDaySelector)) {
    return <>{formatMessage({id: `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_${value}`})}</>
  }

  let days = ''
  selectedDays.forEach((day, index) => {
    days += formatMessage({id: `DAYS.${day}_PLURAL_LABEL`})
    if (index === selectedDays.length - 2) {
      const isMultipleDays = selectedDays.length > 2
      days += formatMessage({id: isMultipleDays ? 'GENERAL.AND_FOR_MULTIPLE_ITEMS' : 'GENERAL.AND_FOR_ITEMS'})
    } else if (index < selectedDays.length - 1) {
      days += ', '
    }
  })

  return <>{formatMessage({id: `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_WEEKLY_DAYS`}, {days})}</>
}

const useSummaryMessageForMonthly = ({value, period}: RecurrenceSummaryViewProps) => {
  const {formatMessage} = useIntl()
  const {country} = useJourneyMetaConfig()

  const startingDate = period?.start
  const endingDate = period?.end

  const messageId = useMemo(() => {
    const hasPeriod = !!startingDate || !!endingDate
    if (value !== 'MONTHLY' || !hasPeriod) {
      return `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_${value}`
    }

    return !endingDate
      ? `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_${value}_PARTIAL`
      : `JOURNEY_BUILDER.RECURRENCE_SUMMARY_FREQUENCY_${value}_COMPLETE`
  }, [startingDate, endingDate, value])

  const timezone = timezones[country as keyof typeof timezones]
  const formattedStartDay = startingDate ? convertUtcStringToJsDate({date: startingDate, timezone})?.getDate() : null
  const formattedStartDate = formatJourneyPeriodDate(period?.start, country)
  const formattedEndDate = formatJourneyPeriodDate(period?.end, country)

  if (value !== 'MONTHLY') {
    return null
  }

  return (
    <>
      {formatMessage(
        {id: messageId},
        {
          start_day: formattedStartDay,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        }
      )}
    </>
  )
}

import {CountryCodeSchema} from '@/types/Templates'
import {z} from 'zod'
import {ThresholdMessagesSchema} from './ThresholdMessages'

const SettingsBaseSchema = z.object({
  threshold: z.object({
    messages: ThresholdMessagesSchema,
  }),
})

const CustomSettingsSchema = z.object({
  countryCode: CountryCodeSchema,
  ...SettingsBaseSchema.shape,
})

export type CustomSettings = z.infer<typeof CustomSettingsSchema>

const SettingsSchema = z.object({
  ...SettingsBaseSchema.shape,
  customSettings: z.array(CustomSettingsSchema).optional(),
})

export type Settings = z.infer<typeof SettingsSchema>

export const GetSettingsRequestSchema = z.void()
export type GetSettingsRequest = z.infer<typeof GetSettingsRequestSchema>

export const GetSettingsResponseSchema = SettingsSchema
export type GetSettingsResponse = z.infer<typeof GetSettingsResponseSchema>

export const SaveSettingsRequestSchema = SettingsSchema
export type SaveSettingsRequest = z.infer<typeof SaveSettingsRequestSchema>

export const SaveSettingsResponseSchema = z.unknown().optional()
export type SaveSettingsResponse = z.infer<typeof SaveSettingsResponseSchema>

export const HowtoPublish = () => {
  return (
    <>
      <p>To publish a communication journey, the following requirements must be met:</p>
      <ol>
        <li>All the changes must be saved as draft;</li>
        <li>All steps configurations must be fulfilled;</li>
        <li>At least one notification step type must be added;</li>
      </ol>
      <p className="border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium">
        <i>If these requirements are not met, the publish button won't be enabled!</i>
      </p>
      <p>
        <b> Attention: </b> once the journey is published, it can't be changed! You will have to delete it and create a
        new one.
      </p>
      <ol>
        <li>
          Click on the <b> Publish </b> button at the bottom right corner of the journey builder.
          <img src="/img/userGuide/publish-button.png" width="100" alt="Publish Journey button" />
        </li>
      </ol>
    </>
  )
}

import {cn} from '@/helpers/shadcn/utils'
import {EmojiPickerButton} from '@/shared-components/EmojiPickerButton'
import {useEmojiPickerButtonEvents} from '@/shared-components/EmojiPickerButton/useEmojiPickerButtonEvents.hook'
import {FieldLabel} from '@/shared-components/FieldLabel'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import React, {ChangeEventHandler, ComponentPropsWithoutRef, RefObject, useState} from 'react'

interface InputTextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  innerRef?: RefObject<HTMLTextAreaElement>
  label?: string
  error?: string
  info?: string
  required?: boolean
  maxWordsCounter?: number
  showEmojiPicker?: boolean
}

export const InputTextArea: React.FC<InputTextAreaProps> = ({
  innerRef,
  error,
  info,
  label,
  maxWordsCounter,
  required,
  showEmojiPicker,
  value = '',
  ...rest
}) => {
  const [caretPosition, setCaretPosition] = useState(Math.max(String(value).length, 0))
  const [isInputFocused, setIsInputFocused] = useState(false)

  const {handleEmojiPickEvent} = useEmojiPickerButtonEvents(
    value as string,
    rest.onChange as ChangeEventHandler,
    caretPosition,
    setCaretPosition
  )

  return (
    <div className="relative flex flex-col gap-2">
      <FieldLabel label={label} required={required} />
      <div
        data-error={error}
        className={cn(
          'flex flex-col rounded border border-border data-[error]:border-support-error-primary',
          isInputFocused ? 'border-link' : ''
        )}
      >
        <textarea
          {...rest}
          value={value}
          data-testid={`${rest.id}-textarea`}
          data-cy={`${rest.id}-textarea`}
          data-error={error}
          onClick={event => {
            setCaretPosition(event.currentTarget.selectionStart)
            rest.onClick?.(event)
          }}
          onBlur={event => {
            setCaretPosition(event.currentTarget.selectionStart)
            setIsInputFocused(false)
            rest.onBlur?.(event)
          }}
          onFocus={event => {
            setCaretPosition(event.currentTarget.selectionStart)
            setIsInputFocused(true)
            rest.onFocus?.(event)
          }}
          className={cn(
            'focus:shadow-none h-40 max-h-40 w-full rounded bg-layer-01 p-2 text-sm text-text-primary focus:border-link focus:text-link focus:outline-0 disabled:bg-button-disabled-background disabled:text-text-disabled  data-[error]:text-support-error-primary',
            rest.className
          )}
          ref={innerRef}
        />
        {showEmojiPicker && (
          <div
            className={cn(
              '-mt-2 h-8 w-full rounded bg-layer-01 ',
              rest.disabled ? 'bg-button-disabled-background' : ''
            )}
          ></div>
        )}
      </div>
      <div className="flex items-center justify-between text-xs">
        <InfoOrError info={info} error={error} id={rest.id} />

        {maxWordsCounter && (
          <p
            data-cy={`${rest.id}-textarea-char-limit`}
            className={`ml-auto flex w-fit text-xs font-bold ${error ? ' text-red-400' : 'text-text-secondary'}`}
          >{`${(value as string).length}/${maxWordsCounter}`}</p>
        )}
      </div>
      {showEmojiPicker && !rest.disabled && (
        <EmojiPickerButton onPickEmoji={handleEmojiPickEvent} emojiPickerLeftPosition={300} />
      )}
    </div>
  )
}

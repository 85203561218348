import {ComponentPropsWithoutRef} from 'react'

export const WhatsAppIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.0380249 16L1.16269 11.8913C0.468691 10.6887 0.104025 9.32533 0.104692 7.92733C0.106692 3.55667 3.66336 0 8.03336 0C10.154 0.000666667 12.1447 0.826667 13.642 2.32533C15.1387 3.824 15.9627 5.816 15.962 7.93467C15.96 12.306 12.4034 15.8627 8.03336 15.8627C6.70669 15.862 5.39936 15.5293 4.24136 14.8973L0.0380249 16ZM4.43602 13.462C5.55336 14.1253 6.62002 14.5227 8.03069 14.5233C11.6627 14.5233 14.6214 11.5673 14.6234 7.93333C14.6247 4.292 11.68 1.34 8.03602 1.33867C4.40136 1.33867 1.44469 4.29467 1.44336 7.928C1.44269 9.41133 1.87736 10.522 2.60736 11.684L1.94136 14.116L4.43602 13.462ZM12.0274 9.81933C11.978 9.73667 11.846 9.68733 11.6474 9.588C11.4494 9.48867 10.4754 9.00933 10.2934 8.94333C10.112 8.87733 9.98002 8.844 9.84736 9.04267C9.71536 9.24067 9.33536 9.68733 9.22002 9.81933C9.10469 9.95133 8.98869 9.968 8.79069 9.86867C8.59269 9.76933 7.95402 9.56067 7.19736 8.88533C6.60869 8.36 6.21069 7.71133 6.09536 7.51267C5.98002 7.31467 6.08336 7.20733 6.18202 7.10867C6.27136 7.02 6.38002 6.87733 6.47936 6.76133C6.58002 6.64667 6.61269 6.564 6.67936 6.43133C6.74536 6.29933 6.71269 6.18333 6.66269 6.084C6.61269 5.98533 6.21669 5.01 6.05202 4.61333C5.89069 4.22733 5.72736 4.27933 5.60602 4.27333L5.22602 4.26667C5.09402 4.26667 4.87936 4.316 4.69802 4.51467C4.51669 4.71333 4.00469 5.192 4.00469 6.16733C4.00469 7.14267 4.71469 8.08467 4.81336 8.21667C4.91269 8.34867 6.21002 10.35 8.19736 11.208C8.67002 11.412 9.03936 11.534 9.32669 11.6253C9.80136 11.776 10.2334 11.7547 10.5747 11.704C10.9554 11.6473 11.7467 11.2247 11.912 10.762C12.0774 10.2987 12.0774 9.902 12.0274 9.81933Z"
        fill="inherit"
      />
    </svg>
  )
}

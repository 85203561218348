import {ChangeFilterValueProps} from '@/components/ParamsFilterCard/ParamSelector/FilterOptionsByParam'
import {Button} from 'pepsico-ds'
import {useRef} from 'react'
import {useIntl} from 'react-intl'

type CSVReaderState = 'SUCCESS' | 'ERROR' | null

export type CSVReaderProps = {
  state?: CSVReaderState
  message?: string
  onChange: (values: ChangeFilterValueProps) => void
}

export const CSVReader = ({onChange, state, message}: CSVReaderProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const {formatMessage} = useIntl()

  const onLoadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const csvFile = event.target.files[0]
    const formattedValuesList = await parseCsvFile(csvFile)

    onChange({
      key: 'value',
      newValue: formattedValuesList,
    })
  }

  const onButtonClick = () => {
    inputFileRef.current?.click()
  }

  return (
    <>
      <input
        ref={inputFileRef}
        className="hidden"
        data-testid="file-input-uploader"
        data-cy="file-input-uploader"
        onChange={onLoadCsv}
        value={''}
        type="file"
        accept=".csv"
        hidden
      ></input>

      <FileFeeback state={state || null} message={message} />

      <Button
        variant="secondary"
        size="small"
        onClick={onButtonClick}
        data-testid="load-values-list-from-file-link"
        iconTrailing="uploader"
        className="flex w-full justify-center"
      >
        {formatMessage({id: 'ENTRY_CONDITION_CONTENT.LOAD_FILE_CSV_LABEL'})}
      </Button>
    </>
  )
}

const FileFeeback = ({state, message}: {state: CSVReaderState; message?: string}) => {
  return (
    <>
      {state === 'SUCCESS' && (
        <p className="text-xs text-support-success-primary" data-testid="file-success">
          {message}
        </p>
      )}

      {state === 'ERROR' && (
        <p className="text-xs text-support-error-primary" data-testid="file-error">
          {message}
        </p>
      )}
    </>
  )
}

// MARK: - Helpers

async function parseCsvFile(csvFile: File) {
  const fileUrl = URL.createObjectURL(csvFile)
  const response = await fetch(fileUrl)
  const text = await response.text()

  const bestDelimiter = findBestCSVDelimiter(text)

  const [, ...lines] = text.split('\r\n')

  return lines
    .map(line => {
      const value = line.split(bestDelimiter)[0].trim()
      return value ? `${value}`.replaceAll('""', '"') : null
    })
    .filter(Boolean)
    .join(',')
}

function findBestCSVDelimiter(text: string) {
  const textSample = text.split('\r\n').slice(0, 10).join('\r\n') // First 10 rows

  const arrDelimiters = [',', ';']
  const arrDelimitersCount = arrDelimiters.map(delimiter => textSample.split(delimiter).length)
  const maxDelimiterCountIndex = arrDelimitersCount.indexOf(Math.max(...arrDelimitersCount))

  return arrDelimiters[maxDelimiterCountIndex]
}

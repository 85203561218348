import {cn} from '@/helpers/shadcn/utils'
import {Modal as ModalPepsico} from 'pepsico-ds'
import {useIntl} from 'react-intl'

export type ModalProps = {
  isOpen: boolean
  title?: string
  content?: React.ReactNode
  primaryButtonText?: string
  secondaryButtonText?: string
  onClose?: () => void
  onClickSecondaryButton?: () => void
  onClickPrimaryButton?: () => void
  primaryButtonDisabled?: boolean
  classNameWrapper?: string
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title = '',
  content = '',
  primaryButtonText,
  secondaryButtonText,
  onClose,
  onClickSecondaryButton,
  onClickPrimaryButton,
  classNameWrapper = '',
  primaryButtonDisabled = false,
}) => {
  const {formatMessage} = useIntl()

  return (
    <ModalPepsico
      showModal={isOpen}
      title={title}
      text={''}
      showLink={false}
      onCloseModal={onClose}
      showTertiaryButton={false}
      className="[&>*]:h-fit [&>*]:w-fit [&_.modal]:!p-8"
      secondaryButtonProps={{
        iconTrailing: 'none',
        size: 'medium',
        text: secondaryButtonText ?? formatMessage({id: 'GENERAL.CANCEL_BUTTON'}),
        variant: 'secondary',
        className: 'w-fit [&>span]:!hidden',
        onClick: onClickSecondaryButton,
        'data-testid': 'modal-secondary-button',
        'data-cy': 'modal-secondary-button',
      }}
      primaryButtonProps={{
        iconTrailing: 'none',
        size: 'medium',
        text: primaryButtonText,
        variant: 'primary',
        onClick: onClickPrimaryButton,
        className: cn('ml-auto w-fit [&>span]:!hidden', !primaryButtonText ? 'hidden' : ''),
        'data-testid': 'modal-primary-button',
        'data-cy': 'modal-primary-button',
        disabled: primaryButtonDisabled,
      }}
    >
      <div data-testid="modal-wrapper" className={cn('-mt-4 overflow-y-auto', classNameWrapper)}>
        {content}
      </div>
    </ModalPepsico>
  )
}

export default Modal

import {GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {SendSMSNodeModel} from './SendSMSNodeModel'
import {SendSMSNodeWidget} from './SendSMSNodeWidget'

export class SendSMSNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.sendSMS)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <SendSMSNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new SendSMSNodeModel()
  }
}

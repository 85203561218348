import {useSendNotificationData} from '@/components/Drawer/hooks/useSendNotificationData'
import {AutoComplete} from '@/components/SendNotification/AutoComplete'
import {RecipientDropDown} from '@/components/SendNotification/RecipientDropDown'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {StepSendSMSConfig} from '@/types/Steps'
import {MAX_MESSAGE_LENGTH} from '@/utils/consts'
import {useIntl} from 'react-intl'

export const SendSmsStepConfigParams = () => {
  const {formatMessage} = useIntl()

  const {config: tempConfig, onChangeRecipient, onChangeMessage} = useSendNotificationData(NodeTypeEnum.sendSMS)
  const config = tempConfig as StepSendSMSConfig

  return (
    <div className="flex w-full max-w-xs flex-col gap-4" data-testid="send-sms-step-config-params">
      <RecipientDropDown
        value={config.to}
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.RECIPIENT_LABEL'})}
        onChange={onChangeRecipient}
        required
      />

      <AutoComplete
        inputId="send-notification-message"
        inputValue={config.message}
        onChangeInput={onChangeMessage}
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.MESSAGE_LABEL'})}
        max_message_length={MAX_MESSAGE_LENGTH}
        required
      />
    </div>
  )
}

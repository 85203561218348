import {
  NotificationEventCell,
  NotificationNameCell,
  NotificationPublishedOnCell,
  NotificationStatusCell,
  NotificationTypeCell,
} from '@/components/EventListTable/components/NotificationCustomizeCell'
import {NotificationListItem} from '@/types/Notifications'
import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getNotificationTableColumns = (
  formatMessage: IntlFormatters['formatMessage'],
  openEventNotificationDetails: (notificationId: string) => void
): ColumnDef<NotificationListItem, CustomColumnMeta>[] => {
  return [
    {
      accessorKey: 'name',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_ID_COLUMN'})} />
      ),
      cell: ({row}) => (
        <NotificationNameCell
          name={row.getValue('name')}
          onClick={() => openEventNotificationDetails(row.original.id)}
        />
      ),
      meta: {
        styles: {
          minWidth: 300,
        },
      },
    },
    {
      accessorKey: 'stepType',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_TYPE_COLUMN'})} />
      ),
      enableSorting: true,
      cell: ({row}) => <NotificationTypeCell type={row.getValue('stepType')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
    },
    {
      accessorKey: 'eventName',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_EVENT_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 200,
        },
      },
      cell: ({row}) => <NotificationEventCell eventName={row.getValue('eventName')} />,
    },
    {
      accessorKey: 'eventStatus',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_STATUS_COLUMN'})} />
      ),
      cell: ({row}) => <NotificationStatusCell status={row.getValue('eventStatus')} />,
      meta: {
        styles: {
          minWidth: 100,
        },
      },
      sortDescFirst: false,
    },
    {
      accessorKey: 'publishedOn',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NOTIFICATION_LIST.TABLE_PUBLISHED_COLUMN'})} />
      ),
      cell: ({row}) => <NotificationPublishedOnCell value={row.getValue('publishedOn')} />,
      meta: {
        styles: {
          minWidth: 200,
        },
      },
      sortDescFirst: false,
    },
  ]
}

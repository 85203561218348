import {cn} from '@/helpers/shadcn/utils'
import {PortProps, PortWidget} from '@projectstorm/react-diagrams-core'
import {Icon} from 'pepsico-ds'

class CustomPort extends PortWidget {}

const CustomPortWidget = ({
  engine,
  port,
  className,
  style,
  isPortBottom,
  isPublished,
  isAddPort,
  onClick,
}: PortProps & {onClick?: () => void; isPortBottom?: boolean; isPublished?: boolean; isAddPort?: boolean}) => {
  let portBottomStyle = isPortBottom ? '-left-[0.3rem] w-fit h-fit' : 'w-2.5 h-2.5 left-[0.15rem]'
  portBottomStyle = isPublished ? 'p-2' : portBottomStyle

  const identifier = isAddPort ? 'add-node-port' : 'node-port'

  return (
    <CustomPort className={className} port={port} engine={engine} style={style}>
      <div
        data-testid={identifier}
        data-cy={identifier}
        onClick={onClick}
        className={cn('flex cursor-pointer rounded-full bg-primary p-1', portBottomStyle)}
      >
        {isAddPort && <Icon icon="add_circle_outline" size="small" className="text-button-icon" />}
      </div>
    </CustomPort>
  )
}

export default CustomPortWidget

import {canvasApp} from '@/components/Canvas/Application/Application'
import {downloadFileHandler} from '@/helpers/downloadFileHandler'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {generateExcelFile} from '@/helpers/generateExcelFile'
import {getErrorMessage} from '@/helpers/getErrorMessage'
import {getFormattedCurrentTimestamp} from '@/helpers/getFormattedCurrentTimestamp'
import {store, useAppSelector} from '@/store'
import {JourneyError} from '@/types/JourneyError'
import {GetReportDataResponse, GetReportResponse} from '@/types/Journeys'
import {showNotification} from '@ReduxActions'
import {getRequiresInterventionReportUseCase} from '@UseCases'
import {useMutation} from '@tanstack/react-query'

export const useExportRequiresIntervention = (journeyId?: string) => {
  const {mutate, isPending: isExporting} = useMutation({
    mutationKey: ['getReport', journeyId],
    mutationFn: getRequiresInterventionReportUseCase,
  })
  const {name} = useAppSelector(state => state.journeyConfig)

  const onExportDidClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation()

    if (isExporting) return

    canvasApp.getActiveDiagram().clearSelection()

    mutate(
      {id: journeyId},
      {
        onSuccess: result => {
          const fileName = `${name} - Requires Intervention records - ${getFormattedCurrentTimestamp()}.xlsx`
          isFeatureEnabled(FeatureToggle.dataReports)
            ? generateExcelFile((result as GetReportDataResponse).content, fileName)
            : downloadFileHandler((result as GetReportResponse).path, fileName)
        },
        onError: error => {
          const isCustomError = (error as JourneyError) instanceof JourneyError
          const {title, message} = getErrorMessage(error)

          store.dispatch(
            showNotification({
              show: true,
              type: isCustomError ? 'warning' : 'error',
              title,
              message,
            })
          )
        },
      }
    )
  }

  return {
    isExporting,
    onExportDidClick,
  }
}

import {journeyTemplateListener} from '@/store/middlewares/journeyTemplateListener'
import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {journeyStatusListener} from './middlewares/journeyStatusListener'
import {nodesDataWarningsListener} from './middlewares/nodesDataWarningsListener'
import {
  currentNodeSliceReducer,
  hasUnsavedChangesSliceReducer,
  journeyConfigSliceReducer,
  nodesDataReducer,
  nodesRelationsSliceReducer,
} from './slices'
import {journeyDrawerSliceReducer} from './slices/journeyDrawer'
import {journeyExecutionStatusSliceReducer} from './slices/journeyExecutionStatusSlice'
import {notificationsReducer} from './slices/notificationsSlice'
import {stepsSelectorReducer} from './slices/stepsSelectorSlice'

export const reducers = {
  journeyDrawer: journeyDrawerSliceReducer,
  currentNode: currentNodeSliceReducer,
  journeyConfig: journeyConfigSliceReducer,
  journeyExecutionStatus: journeyExecutionStatusSliceReducer,
  nodesData: nodesDataReducer,
  nodesRelations: nodesRelationsSliceReducer,
  notifications: notificationsReducer,
  hasUnsavedChanges: hasUnsavedChangesSliceReducer,
  stepsSelector: stepsSelectorReducer,
}

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(
      journeyStatusListener.middleware,
      journeyTemplateListener.middleware,
      nodesDataWarningsListener.middleware
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import {useCallback} from 'react'
import {useBeforeUnload, unstable_useBlocker as useBlocker} from 'react-router-dom'

type HistoryLocation = {
  hash: string
  pathname: string
  search: string
}

type NextHistoryLocation = {
  currentLocation: HistoryLocation
  nextLocation: HistoryLocation
}

export function usePromptBlocker(shouldPreventNavigation: boolean, beforeUnload = false) {
  const blocker = useBlocker(
    useCallback(
      ({currentLocation, nextLocation}: NextHistoryLocation) => {
        const isSameLocation = currentLocation.pathname === nextLocation.pathname
        return shouldPreventNavigation && !isSameLocation
      },
      [shouldPreventNavigation]
    )
  )

  useBeforeUnload(
    useCallback(
      event => {
        if (beforeUnload && shouldPreventNavigation) {
          event.preventDefault()

          // We can't add custom `onbeforeunload` messages anymore, and it should be fine:
          // TL;DR: A window's onbeforeunload property no longer supports a custom string.
          // https://developer.chrome.com/blog/chrome-51-deprecations/#remove-custom-messages-in-onbeforeunload-dialogs
          // event.returnValue = message
        }
      },
      [beforeUnload, shouldPreventNavigation]
    ),
    {capture: true}
  )

  return {blocker}
}

import {DrawerConfigTitle} from '@/components/Drawer/DrawerConfigTitle'
import {useWaitData} from '@/components/Drawer/hooks/useWaitData'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {InputText} from '@/shared-components/InputText'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

export const WaitParams = () => {
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const {formatMessage} = useIntl()
  const {timeValue: timeValueRaw, setTimeValue} = useWaitData()

  const onValueDidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isPublishedOrCompleted) return

    const newValue = event.target.value
    const value = newValue === '' ? null : parseInt(newValue, 10)
    setTimeValue(value)
  }

  const [hasError, setHasError] = useState(true)
  const timeValue = timeValueRaw === null ? '' : timeValueRaw

  useEffect(() => {
    setHasError(timeValue === '' || timeValue < 1)
  }, [timeValue])

  return (
    <>
      <DrawerConfigTitle />
      <div className="max-w-xs">
        <InputText
          id="waitTime"
          type="number"
          label={formatMessage({id: 'WAIT_CONTENT.CONFIGURE_LABEL'})}
          placeholder={formatMessage({id: 'WAIT_CONTENT.AMOUNT_DAYS_PLACEHOLDER'})}
          value={timeValue}
          onChange={onValueDidChange}
          info={formatMessage({id: 'WAIT_CONTENT.AMOUNT_DAYS_VALIDATION'})}
          error={hasError ? formatMessage({id: 'WAIT_CONTENT.AMOUNT_DAYS_VALIDATION'}) : undefined}
          disabled={isPublishedOrCompleted}
          required
        />
      </div>
    </>
  )
}

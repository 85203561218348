import {cn} from '@/helpers/shadcn/utils'
import {ComponentPropsWithoutRef, MouseEventHandler, PropsWithChildren} from 'react'

interface Props extends ComponentPropsWithoutRef<'button'> {
  size: 'small' | 'large'
  selected?: boolean
  //   showDropwdown?: boolean // NOT YET IMPLEMENTED
  //   isMultiSelect?: boolean // NOT YET IMPLEMENTED
}

export const Label: React.FC<PropsWithChildren<Props>> = ({
  id,
  size,
  selected,
  children,
  onClick,
  disabled,
  ...props
}) => {
  const onClickLabel: MouseEventHandler<HTMLButtonElement> = e => {
    if (disabled) return
    onClick?.(e)
  }

  const labelSize = size === 'large' ? 'px-4 py-[5px] text-sm' : 'py-[3px] px-2 text-xs'

  return (
    <button
      {...props}
      onClick={onClickLabel}
      data-testid={`${id}-label`}
      data-cy={`${id}-label`}
      data-selected={selected || undefined}
      className={cn(
        'flex cursor-pointer items-center rounded-3xl border border-label-border bg-button-icon px-4 text-sm text-button-primary hover:bg-button-icon-hover disabled:border-layer-disabled disabled:bg-button-icon-disabled disabled:text-text-disabled disabled:hover:bg-button-icon-disabled data-[selected]:border-button-icon-active data-[selected]:bg-button-icon-hover',
        labelSize,
        props.className
      )}
    >
      {children}
    </button>
  )
}

import {RemoveParameterButton} from '@/shared-components/RemoveParameterButton'
import {TimeSelector} from '@/shared-components/TimeSelector'
import {TimeExecutionWindow} from '@/types/TimeExecution'
import {useIntl} from 'react-intl'

export const TimeExecutionsSelectorItem = ({
  start,
  end,
  position,
  onChange,
  onRemove,
  disabled,
  ...props
}: {
  start: string
  end?: string
  position: number
  onChange?: (newValue: TimeExecutionWindow, position: number) => void
  onRemove?: (position: number) => void
  disabled?: boolean
}) => {
  const {formatMessage} = useIntl()

  const onDidChange = (newValue: TimeExecutionWindow, index: number) => {
    onChange?.(newValue, index)
  }

  return (
    <div key={position} className="flex w-full items-start gap-6" data-testid="time-execution-window-item">
      <div className="w-full max-w-[280px]">
        <TimeSelector
          {...props}
          className="flex-1"
          label={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_START'})}
          empty={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_SELECT_START'})}
          id={`time-execution-window-start-${position}`}
          value={start}
          onChange={evt => onDidChange({start: evt.target.value, end: end}, position)}
          disabled={disabled}
          required
          error={!start ? formatMessage({id: 'JOURNEY_BUILDER.REQUIRED_LABEL_ERROR'}) : undefined}
        />
      </div>

      <div className="flex w-full items-center gap-4">
        <div className="w-full max-w-[280px]">
          <TimeSelector
            {...props}
            className="flex-1"
            label={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_END'})}
            empty={formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_SELECT_END'})}
            id={`time-execution-window-end-${position}`}
            value={end}
            onChange={evt => onDidChange({start, end: evt.target.value}, position)}
            startTime={start}
            disabled={disabled || !start.length}
            isEndTime
            required
            error={!end ? formatMessage({id: 'JOURNEY_BUILDER.REQUIRED_LABEL_ERROR'}) : undefined}
          />
        </div>

        {!disabled && (
          <RemoveParameterButton
            className=" mt-4 h-8 w-8 px-0.5 py-0"
            iconSize="medium"
            onClick={() => onRemove?.(position)}
          />
        )}
      </div>
    </div>
  )
}

import {RootState} from '@/store'
import {JourneyStatus} from '@/types/JourneyStatus'
import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit'
import {
  removeNodeDataRef,
  setJourneyMeta,
  setJourneyStatus,
  setNodeData,
  setPeriod,
  updateNodeParamsSelectors,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  updateNodeWaitData,
  updateReEntryDays,
} from '../slices'

export const journeyTemplateListener = createListenerMiddleware()

journeyTemplateListener.startListening({
  matcher: isAnyOf(
    removeNodeDataRef,
    setJourneyMeta,
    setNodeData,
    setPeriod,
    updateNodeParamsSelectors,
    updateNodeSendPushNotificationData,
    updateNodeSendSMSData,
    updateNodeSendWhatsAppData,
    updateNodeWaitData,
    updateReEntryDays
  ),
  effect: async (_, listener) => {
    listener.getOriginalState()
    const {status} = (listener.getOriginalState() as RootState).journeyConfig

    const shouldChangeToDraft = [JourneyStatus.saved].includes(status)

    if (shouldChangeToDraft) {
      listener.dispatch(setJourneyStatus(JourneyStatus.draft))
    }
  },
})

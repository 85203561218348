import {createSlice} from '@reduxjs/toolkit'

type StepsSelectorProps = {
  isOpen: boolean
  selectedStepId?: string
}

const initialState: StepsSelectorProps = {
  isOpen: false,
}

const stepsSelectorSlice = createSlice({
  name: 'stepsSelector',
  initialState: initialState,
  reducers: {
    showStepsSelector: state => {
      return {
        ...state,
        isOpen: true,
      }
    },
    hideStepsSelector: state => {
      return {
        ...state,
        isOpen: false,
      }
    },
    setSelectedStepId: (state, {payload}) => {
      return {
        ...state,
        selectedStepId: payload.id,
      }
    },
    toggleStepsSelector: state => {
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    },
    resetStepsSelector: () => initialState,
  },
})

export const {setSelectedStepId, resetStepsSelector} = stepsSelectorSlice.actions

export const stepsSelectorReducer = stepsSelectorSlice.reducer

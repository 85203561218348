import {StepConfigLoadingOrError} from '@/components/Drawer/ContentParams/StepConfigLoader/StepConfigLoadingOrError'
import {TemplateMessageBox} from '@/components/SendNotification/TemplateMessageBox'
import {STEP_CONFIG} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {TemplateItem} from '@/types/StepConfigParams'
import {TemplateTypeSchema} from '@/types/Templates'
import {getTemplatesUseCase} from '@/useCases/getTemplatesUseCase'
import {isSUT} from '@/utils/env'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {PropsWithChildren} from 'react'
import {useIntl} from 'react-intl'

type TemplateMessageContainerProps = PropsWithChildren & {
  selectedTemplateId: string
  onChangeTemplateAndMessage: (template: string, message: string) => void
}

export const TemplateMessageContainer = ({
  selectedTemplateId,
  onChangeTemplateAndMessage,
}: TemplateMessageContainerProps) => {
  const queryClient = useQueryClient()
  const {isFetching, isError, isSuccess, data} = useQuery({
    queryKey: [STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendWhatsApp],
    queryFn: async ({signal}: {signal: AbortSignal}) => {
      const result = await getTemplatesUseCase({type: TemplateTypeSchema.Values.WHATSAPP}, signal)
      return result?.content ?? []
    },
    retry: isSUT() ? false : 1,
    staleTime: 3 * 60 * 1000, // 3 minutes
  })

  const onDidClickTryAgain = () => {
    queryClient.invalidateQueries({queryKey: [STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendWhatsApp]})
  }

  if (isFetching || isError) {
    return (
      <StepConfigLoadingOrError isFetching={isFetching} isError={isError} onDidClickTryAgain={onDidClickTryAgain} />
    )
  }

  if (!isSuccess) {
    return null
  }

  return (
    <TemplateMessage
      data={data}
      selectedTemplateId={selectedTemplateId}
      onChangeTemplateAndMessage={onChangeTemplateAndMessage}
    />
  )
}

type TemplateMessageProps = {
  data: TemplateItem[]
  selectedTemplateId: string
  onChangeTemplateAndMessage: (template: string, message: string) => void
}

export const TemplateMessage = ({data, selectedTemplateId, onChangeTemplateAndMessage}: TemplateMessageProps) => {
  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const onTemplateDidChange = (selectedTemplateId: string) => {
    const message = data?.filter(({id}: TemplateItem) => id === selectedTemplateId)[0].message
    onChangeTemplateAndMessage(selectedTemplateId, message || '')
  }

  return (
    <div data-testid="template-message-container" className="flex flex-col gap-6">
      <p className="text-sm">{formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_TEMPLATE'})}</p>

      <TemplateMessageBox
        data={data}
        selectedTemplateId={selectedTemplateId}
        disabled={isPublishedOrCompleted}
        onClick={onTemplateDidChange}
      />
    </div>
  )
}

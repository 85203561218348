import {StepSendPushNotification} from '@/types/Steps'
import {useIntl} from 'react-intl'

type SendPushStepInfoProps = {
  stepData: StepSendPushNotification
}

export const SendPushStepInfo = ({stepData}: SendPushStepInfoProps) => {
  const {formatMessage} = useIntl()

  const shouldDisplay = stepData.config.message?.length > 0

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex flex-col gap-1" data-testid="step-info-value">
      <span
        className="line-clamp-1 w-fit max-w-56 overflow-hidden overflow-ellipsis"
        data-testid="step-info-subject-value"
      >
        {formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SUBJECT_LABEL'})} : {stepData.config.subject}
      </span>
      <span
        className="line-clamp-2 w-fit max-w-56 overflow-hidden overflow-ellipsis"
        data-testid="step-info-message-value"
      >
        {formatMessage({id: 'SEND_NOTIFICATION_CONTENT.MESSAGE_LABEL'})} : {stepData.config.message}
      </span>
    </div>
  )
}

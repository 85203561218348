// DiamondNodeModel.ts
import {setInitialNodeWaitData} from '@ReduxActions'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {store} from '../../../../store'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {removeNodeAndConnectItsParentAndTarget} from '../../utils/NodeEventUtils'
import {CustomNodeModel} from '../CustomNodeModel'
import {WaitPortModel} from './WaitPortModel'

// this can be further extended for more complicated node types
export class WaitNodeModel extends CustomNodeModel {
  constructor(id?: string) {
    const obj = {
      type: NodeTypeEnum.wait,
      id,
      extras: {
        name: 'Wait',
        priority: 4,
        nodeSize: 150,
      },
    }
    if (!id) delete obj.id

    super(obj)

    this.addPort(new WaitPortModel(PortModelAlignment.TOP))
    this.addPort(new WaitPortModel(PortModelAlignment.BOTTOM))

    store.dispatch(
      setInitialNodeWaitData({
        id: this.getID(),
        type: NodeTypeEnum.wait,
      })
    )
  }

  remove() {
    removeNodeAndConnectItsParentAndTarget(this.getID(), () => super.remove())
  }
}

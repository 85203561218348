import {ENDPOINTS} from '@/config/constants'
import api, {HTTPMethod} from '@/helpers/api'
import {pathBuilder} from '@/helpers/pathBuilder'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {
  GetSendPushStepConfigRequest,
  GetSendPushStepConfigRequestSchema,
  GetSendPushStepConfigResponse,
  GetSendPushStepConfigResponseSchema,
  GetSendWhatsAppStepConfigRequest,
  GetSendWhatsAppStepConfigRequestSchema,
  GetSendWhatsAppStepConfigResponse,
  GetSendWhatsAppStepConfigResponseSchema,
} from '@/types/StepConfigParams'

export const getSendPushStepConfigService = (params: GetSendPushStepConfigRequest, signal?: AbortSignal) => {
  return api<GetSendPushStepConfigRequest, GetSendPushStepConfigResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_STEP_CONFIG(NodeTypeEnum.sendPush)).build(),
    requestSchema: GetSendPushStepConfigRequestSchema,
    responseSchema: GetSendPushStepConfigResponseSchema,
    signal,
  })(params)
}

export const getSendWhatsAppStepConfigService = (params: GetSendWhatsAppStepConfigRequest, signal?: AbortSignal) => {
  return api<GetSendWhatsAppStepConfigRequest, GetSendWhatsAppStepConfigResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_STEP_CONFIG(NodeTypeEnum.sendWhatsApp)).build(),
    requestSchema: GetSendWhatsAppStepConfigRequestSchema,
    responseSchema: GetSendWhatsAppStepConfigResponseSchema,
    signal,
  })(params)
}

export const getFormattedCurrentTimestamp = () => {
  const currentTimestamp = new Date()

  const year = currentTimestamp.getFullYear()
  const month = String(currentTimestamp.getMonth() + 1).padStart(2, '0')
  const day = String(currentTimestamp.getDate()).padStart(2, '0')
  const hours = String(currentTimestamp.getHours()).padStart(2, '0')
  const minutes = String(currentTimestamp.getMinutes()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}`
}

import {cn} from '@/helpers/shadcn/utils'
import {Icon} from 'pepsico-ds'
import {ComponentPropsWithoutRef} from 'react'

type RemoveParameterButtonProps = ComponentPropsWithoutRef<'div'> & {
  iconSize?: 'small' | 'medium'
}

export const RemoveParameterButton = ({className, onClick, iconSize, ...props}: RemoveParameterButtonProps) => {
  const iconSizeClass = iconSize === 'small' ? '!text-xs' : '!text-xl'

  return (
    <div
      {...props}
      className={cn(
        'group h-6 w-6 cursor-pointer rounded-full border-2 border-blue bg-button-icon p-0.5 transition-all duration-300 hover:border-button-primary-hover hover:bg-button-primary-hover',
        className
      )}
      data-testid="delete-param-selector-icon"
      data-cy="delete-param-selector-icon"
      onClick={onClick}
    >
      <Icon
        icon="delete_outline"
        className={cn('ml-0.5 !text-xl text-button-primary group-hover:text-white', iconSizeClass)}
      />
    </div>
  )
}

import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../../../store'
import {ParamFilters} from '../../../../types/paramFilters/paramFilters'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {CustomPortModel} from '../CustomPortModel'
import {EntryConditionNodeModel} from './EntryConditionNodeModel'

export interface EntryConditionNodeWidgetProps extends CustomNodeWidgetProps {
  node: EntryConditionNodeModel
}

export const EntryConditionNodeWidget = ({engine, node, ...props}: EntryConditionNodeWidgetProps) => {
  const {formatMessage} = useIntl()
  const nodeId = node.getID()
  const isSelected = node.getOptions().selected

  const portBottom = node.getPort(PortModelAlignment.BOTTOM) as CustomPortModel
  const haveWarnings = useAppSelector(state => state.nodesData[nodeId] as ParamFilters).haveWarnings

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portBottom={portBottom}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      classNameWidgetWrapper="node-widget type-entry-condition"
    >
      <div className="flex items-center justify-center gap-3">
        <div className="node-icon" data-testid="entry-condition-icon">
          <Icon icon="fact_check" size="small" className="$color-icon" />
        </div>
        <div className="node-content">
          <p className="node-name">{formatMessage({id: 'JOURNEY_BUILDER.ENTRY_CONDITION_WIDGET_LABEL'})}</p>
        </div>
      </div>
    </CustomNodeWidget>
  )
}

import {z} from 'zod'

export const PeriodSchema = z.object({
  start: z.string().nullable().optional(),
  end: z.string().nullable().optional(),
})

export type Period = z.infer<typeof PeriodSchema>

export const PeriodNotificationSchema = z.object({
  start: z.string(),
  end: z.string(),
})

export type PeriodNotification = z.infer<typeof PeriodNotificationSchema>

import {JourneyConfigEditor} from '@/components/JourneyConfigEditor/JourneyConfigEditor'
import {DrawerPages} from '@ReduxActions'
import React from 'react'

const pages: Record<DrawerPages, React.ReactNode> = {
  journeyConfigEditor: <JourneyConfigEditor />,
}

const JourneyDrawerPages: React.FC<{selectedPage?: DrawerPages}> = ({selectedPage}) => {
  return selectedPage ? <div className="overflow-y-auto">{pages[selectedPage]}</div> : null
}

export default JourneyDrawerPages

import {z} from 'zod'

const ThresholdValueSchema = z.number().nullable()

export const ThresholdMessagesSchema = z.object({
  daily: ThresholdValueSchema,
  weekly: ThresholdValueSchema,
  monthly: ThresholdValueSchema,
})
export type ThresholdMessages = z.infer<typeof ThresholdMessagesSchema>

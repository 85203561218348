import {FeatureToggle} from '@/config/featureToggles'
import {isDev, isSUT} from '../utils/env'
export {FeatureToggle} from '@/config/featureToggles'

type FeatureToggleType = (typeof FeatureToggle)[keyof typeof FeatureToggle]

const isOverridable = isSUT() || isDev()

export const isFeatureEnabled = (key: FeatureToggleType) => {
  const isEnabledByEnv = import.meta.env[key] === 'true'
  const isEnabledByStorage = sessionStorage.getItem(key) === 'true'

  return isOverridable ? isEnabledByStorage || isEnabledByEnv : isEnabledByEnv
}

export const setFeatureFlagOnStorage = (key: FeatureToggleType, value: boolean) => {
  sessionStorage.removeItem(key)
  if (!isOverridable) {
    return
  }

  sessionStorage.setItem(key, `${!!value}`)
}

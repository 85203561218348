// DiamondNodeModel.ts
import {NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams'
import {CustomPortModel} from './CustomPortModel'

export interface CustomNodeModelGenerics {
  PORT: CustomPortModel
}

// this can be further extended for more complicated node types
export class CustomNodeModel extends NodeModel<NodeModelGenerics & CustomNodeModelGenerics> {
  public removeForce() {
    this.setLocked(false)
    super.remove()
  }
}

import {canvasApp} from '@/components/Canvas/Application/Application'
import {getErrorMessage} from '@/helpers/getErrorMessage'
import {store} from '@/store'
import { JourneyError } from '@/types/JourneyError'
import {showNotification} from '@ReduxActions'

export const onSaveJourney = {
  Success: (isEditing?: boolean, navigateToNewJourneyId?: () => void) => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'success',
        title: 'Success',
        message: 'Your Journey have been saved!',
      })
    )
    if (isEditing) {
      canvasApp.zoomToFitSteps()
      return
    }
    navigateToNewJourneyId?.()
  },
  Error: (error: unknown) => {
    const isCustomError = (error as JourneyError) instanceof JourneyError

    const {title, message} = getErrorMessage(error)

    store.dispatch(
      showNotification({
        show: true,
        type: isCustomError? 'warning': 'error',
        title,
        message,
      })
    )
  },
}

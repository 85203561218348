import {cleanupOrphanSteps} from '@/components/Canvas/utils/NodeEventUtils'
import {Drawer} from '@/components/Drawer/Drawer'
import {DrawerContent} from '@/components/Drawer/DrawerContent'
import {useJourneyDrawer} from '@/components/Drawer/hooks/useJourneyDrawer'
import {SaveNodeDataProvider} from '@/helpers/SaveNodeDataProvider/SaveNodeDataProvider'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {resetCurrentNode, resetStepsSelector} from '@ReduxActions'
import {useCallback, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../store'

const debouncedDelayToCleanupOrphans = 1000 / 60

const SelectedNode = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {id, type, canBeClosed} = useAppSelector(state => state.currentNode)
  const nodeData = useAppSelector(state => state.nodesData[id])
  const {onCloseDrawer: closeDrawerAndReturnToList, checkAndUpdateNodeWarnings} = useJourneyDrawer()
  const {isNew} = useJourneyMetaConfig()
  const dispatch = useAppDispatch()

  const onCloseDrawer = useCallback(() => {
    if (isNew) {
      closeDrawerAndReturnToList()
      return
    }

    if (!canBeClosed) return
    type && checkAndUpdateNodeWarnings(id, type, nodeData)
    dispatch(resetCurrentNode())
    dispatch(resetStepsSelector())
  }, [type, id, nodeData, canBeClosed, dispatch, isNew, closeDrawerAndReturnToList, checkAndUpdateNodeWarnings])

  useEffect(() => {
    const isStepSelected = !!id
    const shouldOpenDrawer = isStepSelected

    setIsOpen(prevIsOpen => {
      const didClose = prevIsOpen && !shouldOpenDrawer

      if (didClose) {
        setTimeout(() => cleanupOrphanSteps(), debouncedDelayToCleanupOrphans)
      }

      return shouldOpenDrawer
    })
  }, [id, type])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onCloseDrawer}
      // Revert: this change need to be confirmed with the design team yet!
      // direction={isPublished ? 'right' : 'bottom'}
    >
      <SaveNodeDataProvider>
        <DrawerContent />
      </SaveNodeDataProvider>
    </Drawer>
  )
}

export default SelectedNode

import {useWidgetInfo} from '@/components/Canvas/hooks/useWidgetInfo'
import {Icon} from 'pepsico-ds'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {WaitNodeModel} from './WaitNodeModel'

interface WaitNodeWidgetProps extends CustomNodeWidgetProps {
  node: WaitNodeModel
}

export const WaitNodeWidget = ({engine, node, ...props}: WaitNodeWidgetProps) => {
  const {formatMessage, haveWarnings, isSelected, portBottom, portTop} = useWidgetInfo(node)

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      portBottom={portBottom}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      classNameWidgetWrapper="node-widget type-wait"
    >
      <div data-cy="wait-node-widget" className="flex items-center justify-center gap-3">
        <div className="node-icon" data-testid="wait-icon">
          <Icon icon="watch_later" size="small" className="$color-icon" />
        </div>
        <div className="node-content">
          <p className="node-name">{formatMessage({id: 'JOURNEY_BUILDER.WAIT_WIDGET_LABEL'})}</p>
        </div>
      </div>
    </CustomNodeWidget>
  )
}

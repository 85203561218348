import {EntryConditionsStepInfo} from '@/components/Canvas/customNodes/StepNodeInfos/components/EntryConditionsStepInfo'
import {SendPushStepInfo} from '@/components/Canvas/customNodes/StepNodeInfos/components/SendPushStepInfo'
import {SendSMSStepInfo} from '@/components/Canvas/customNodes/StepNodeInfos/components/SendSMSStepInfo'
import {SendWhatsAppStepInfo} from '@/components/Canvas/customNodes/StepNodeInfos/components/SendWhatsAppStepInfo'
import {WaitStepInfo} from '@/components/Canvas/customNodes/StepNodeInfos/components/WaitStepInfo'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useAppSelector} from '@/store'
import {StepSendPushNotification, StepSendSMS, StepSendWhatsApp, StepWait} from '@/types/Steps'
import {ParamFilters} from '@/types/paramFilters/paramFilters'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeModel} from '../CustomNodeModel'

type StepInfosProps = {
  node: CustomNodeModel
  openStepConfig?: () => void
}

export const StepNodeInfos = ({node, openStepConfig}: StepInfosProps) => {
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const {isEntryConditions, isWaitStep, isSendSMS, isSendPush, isSendWhatsApp, stepData} = useStepNodeInfos({node})

  const shouldDisplay = !isPublishedOrCompleted && stepData

  if (!shouldDisplay) {
    return null
  }

  return (
    <div
      className="ml-2 flex flex-col text-xs text-text-secondary"
      data-testid="step-node-infos"
      data-cy="step-node-infos"
    >
      {isEntryConditions && (
        <EntryConditionsStepInfo stepData={stepData as ParamFilters} openStepConfig={openStepConfig} />
      )}

      {isWaitStep && <WaitStepInfo stepData={stepData as StepWait} />}
      {isSendSMS && <SendSMSStepInfo stepData={stepData as StepSendSMS} />}
      {isSendPush && <SendPushStepInfo stepData={stepData as StepSendPushNotification} />}
      {isSendWhatsApp && <SendWhatsAppStepInfo stepData={stepData as StepSendWhatsApp} />}
    </div>
  )
}

const useStepNodeInfos = ({node}: StepInfosProps) => {
  const nodeType = node.getType() as NodeTypeEnum

  const isEntryConditions = nodeType === NodeTypeEnum.entryCondition
  const isWaitStep = nodeType === NodeTypeEnum.wait
  const isSendSMS = nodeType === NodeTypeEnum.sendSMS
  const isSendPush = nodeType === NodeTypeEnum.sendPush
  const isSendWhatsApp = nodeType === NodeTypeEnum.sendWhatsApp

  const stepData = useAppSelector(state => {
    const nodeData = state.nodesData[node.getID()]

    if (isEntryConditions) return nodeData as ParamFilters
    if (isWaitStep) return nodeData as StepWait
    if (isSendSMS) return nodeData as StepSendSMS
    if (isSendPush) return nodeData as StepSendPushNotification
    if (isSendWhatsApp) return nodeData as StepSendWhatsApp

    return null
  })

  return {
    isEntryConditions,
    isWaitStep,
    isSendSMS,
    isSendPush,
    isSendWhatsApp,
    stepData,
  }
}

import {AppRouter} from '@/components/AppRouter'
import {DEFAULT_INTL} from '@/config/constants'
import {AuthProvider} from '@/helpers/Auth/AuthProvider'
import useInternationalization from '@/internationalization/useInternationalization'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {IntlProvider} from 'react-intl'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import './assets/scss/App.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

const router = createBrowserRouter([{path: '*', Component: Root}])

function Root() {
  const [language, message] = useInternationalization(DEFAULT_INTL)

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={language} messages={message}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </IntlProvider>
    </QueryClientProvider>
  )
}

export const App = () => {
  return <RouterProvider router={router} />
}

import {ROUTES} from '@/config/constants'
import {AuthContext} from '@/helpers/Auth/AuthProvider'
import {UserRoleContext, UserRoleProvider} from '@/helpers/UserRole/UserRoleProvider'
import {oktaAuth} from '@/helpers/oktaConfig'
import {Template} from '@/pages/Template'
import {useContext, useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

export const ProtectedRoutes = () => {
  return (
    <UserRoleProvider>
      <Template>
        <ProtectedRoutesContent />
      </Template>
    </UserRoleProvider>
  )
}

const ProtectedRoutesContent = () => {
  const {isAuthenticated, isReady: isAuthReady} = useContext(AuthContext)
  const {isAuthorized, isReady: isUserRoleReady, error: userRoleError} = useContext(UserRoleContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthReady) {
      return
    }

    if (!isAuthenticated) {
      oktaAuth.signInWithRedirect({originalUri: ROUTES.HOME})
      return
    }

    if (userRoleError) {
      navigate(ROUTES.ERROR_500)
      return
    }

    if (isUserRoleReady && !isAuthorized) {
      navigate(ROUTES.ERROR_403)
    }
  }, [isAuthenticated, isAuthReady, isAuthorized, isUserRoleReady, navigate, userRoleError])

  const canRenderRoute = isAuthenticated && isAuthorized

  return canRenderRoute ? <Outlet /> : null
}

import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useState} from 'react'
import {canPublishJourney} from '../../../useCases/publishJourneyUseCase'
import {PublishJourneyButton} from './PublishJourneyButton'

type JourneyPersistenceActionsProps = {
  journeyId?: string
}

export type ViewState = 'IDLE' | 'DELETING' | 'PUBLISHING'

export const JourneyPersistenceActions = ({journeyId}: JourneyPersistenceActionsProps) => {
  const [viewState, setViewState] = useState<ViewState>('IDLE')
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const isPublishButtonDisabled = !canPublishJourney() || viewState === 'DELETING' || viewState === 'PUBLISHING'

  return (
    <div className="flex min-w-max items-center gap-2" data-testid="persistence-actions">
      {!isPublishedOrCompleted && (
        <PublishJourneyButton
          journeyId={journeyId}
          isDisabled={isPublishButtonDisabled}
          onIsPublishingHandler={value => setViewState(value ? 'PUBLISHING' : 'IDLE')}
        />
      )}
    </div>
  )
}

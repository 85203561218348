import {useGoBack} from '@/components/Drawer/hooks/useGoBack'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useAppDispatch} from '@/store'
import {
  checkIfIsInvalidEntryConditionConfig,
  checkNotificationsWarnings,
  checkWaitWarnings,
} from '@/store/middlewares/nodesDataWarningsListener'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {StepSendPushNotification, StepSendSMS, StepSendWhatsApp, StepWait} from '@/types/Steps'
import {ParamFilters} from '@/types/paramFilters/paramFilters'
import {NodesData, cleanNodesWithWarnings, closeJourneyDrawer, updateNodeWarnings} from '@ReduxActions'
import {useCallback} from 'react'

export const useJourneyDrawer = () => {
  const dispatch = useAppDispatch()
  const {isNew} = useJourneyMetaConfig()

  const {goBack} = useGoBack()

  const onCloseDrawer = useCallback(() => {
    if (isNew) {
      goBack()
      return
    }
    dispatch(closeJourneyDrawer())
  }, [isNew, dispatch, goBack])

  const checkAndUpdateNodeWarnings = useCallback(
    (id: string, type: NodeTypeEnum, nodeData: NodesData) => {
      let warnings = false
      switch (type) {
        case NodeTypeEnum.entryCondition:
          warnings = checkIfIsInvalidEntryConditionConfig(nodeData as ParamFilters)
          break
        case NodeTypeEnum.wait:
          warnings = checkWaitWarnings((nodeData as StepWait).config.value)
          break
        case NodeTypeEnum.sendPush:
        case NodeTypeEnum.sendSMS:
        case NodeTypeEnum.sendWhatsApp:
          warnings = checkNotificationsWarnings(
            (nodeData as StepSendPushNotification | StepSendSMS | StepSendWhatsApp).config,
            type
          )
          break
        default:
          break
      }
      dispatch(updateNodeWarnings({id, haveWarnings: warnings}))
      dispatch(cleanNodesWithWarnings())
    },
    [dispatch]
  )

  return {
    onCloseDrawer,
    checkAndUpdateNodeWarnings,
  }
}

import {GetNotificationByIdResponse} from '@/types/Notifications'
import {getNotificationByIdService} from '../services/notificationsService'

export type GetNotificationByIdUseCaseParams = {
  id: string
}

export async function getNotificationByIdUseCase(
  {id}: GetNotificationByIdUseCaseParams,
  signal?: AbortSignal
): Promise<GetNotificationByIdResponse> {
  return getNotificationByIdService(id, signal)
}

import {NodeNotificationConfig} from '../types/NodeDataForSendNotification'

export const mockNotificationItems = (): NodeNotificationConfig[] => {
  return [
    {
      name: 'SEND_NOTIFICATION_CONTENT.STORE_OWNER_RECIPIENT',
      id: 'storeOwner',
      isEnabled: true,
    },
  ]
}

export const mockLanguageItems: Array<string> = ['Turkish']

import {JourneyStatus} from '@/types/JourneyStatus'

export const isNew = (status: JourneyStatus) => status === JourneyStatus.new

export const isStatusEditable = (status: JourneyStatus) => {
  return [JourneyStatus.new, JourneyStatus.draft].includes(status)
}

export const isStatusAllowedToSave = (status: JourneyStatus) => {
  return [JourneyStatus.draft].includes(status)
}

export const isPublishedOrCompleted = (status: JourneyStatus) => {
  return [JourneyStatus.published, JourneyStatus.completed].includes(status)
}

export const parseTime = (time: string) => {
  const [hour, minute] = (time?.split(':') ?? []).map(Number)

  const amPm = hour < 12 || hour === 24 ? 'AM' : 'PM'
  const hour12 = hour % 12 || 12

  const hourString = hour.toString().padStart(2, '0')
  const hour12String = hour12.toString().padStart(2, '0')
  const minuteString = minute.toString().padStart(2, '0')

  return {
    hour,
    minute,
    amPm,
    hour12String,
    minuteString,
    formatted24h: `${hourString}:${minuteString}`,
    formattedAmPm: `${hour12String}:${minuteString} ${amPm}`,
  }
}

import * as React from 'react'
import {CustomLinkModel} from './CustomLinkModel'

export class CustomLinkSegment extends React.Component<{
  model: CustomLinkModel
  path: string
  selected: boolean
}> {
  path!: SVGPathElement | null
  circle!: SVGCircleElement | null
  callback!: () => void | unknown
  percent: number
  mounted!: boolean

  constructor(
    props:
      | {model: CustomLinkModel; path: string; selected: boolean}
      | Readonly<{model: CustomLinkModel; path: string; selected: boolean}>
  ) {
    super(props)
    this.percent = 0
  }

  componentDidMount() {
    this.mounted = true
    this.callback = () => {
      if (!this.circle || !this.path) {
        return
      }

      this.percent += 1
      if (this.percent > 100) {
        this.percent = 0
      }

      const point = this.path.getPointAtLength(this.path.getTotalLength() * (this.percent / 100.0))

      this.circle.setAttribute('cx', '' + point.x)
      this.circle.setAttribute('cy', '' + point.y)

      if (this.mounted) {
        requestAnimationFrame(this.callback)
      }
    }

    requestAnimationFrame(this.callback)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <>
        <path
          className="stroke-primary"
          pointerEvents="auto"
          data-testid="link-path"
          fill="none"
          ref={ref => {
            this.path = ref
          }}
          strokeWidth={this.props.model.getOptions().width}
          cursor={'default'}
          d={this.props.path}
        />
        <circle
          ref={ref => {
            this.circle = ref
          }}
          r={4}
          className="fill-primary"
        />
      </>
    )
  }
}

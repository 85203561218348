import {JourneyStatusBadge} from '@/components/JourneyStatusBadge/JourneyStatusBadge'
import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'
import {JourneyListItem} from '../../../types/Journeys'
import {DatetimeCell, JourneyDescriptionCell, JourneyNameCell, JourneyTypeCell} from './CustomCells'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getTableColumns = (
  formatMessage: IntlFormatters['formatMessage']
): ColumnDef<JourneyListItem, CustomColumnMeta>[] => [
  {
    accessorKey: 'name',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_NAME_COLUMN'})} />
    ),
    cell: ({row}) => <JourneyNameCell name={row.getValue('name')} id={row.original.id} />,
    meta: {
      styles: {
        minWidth: 150,
        width: '30%',
      },
    },
  },
  {
    accessorKey: 'type',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_TYPE_COLUMN'})} />
    ),
    cell: ({row}) => <JourneyTypeCell type={row.getValue('type')} />,
    meta: {
      styles: {
        minWidth: 100,
        width: '10%',
      },
    },
  },
  {
    accessorKey: 'description',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_DESCR_COLUMN'})} />
    ),
    enableSorting: false,
    cell: ({row}) => <JourneyDescriptionCell description={row.getValue('description')} />,
    meta: {
      styles: {
        minWidth: 150,
        width: '30%',
      },
    },
  },
  {
    accessorKey: 'status',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_STATUS_COLUMN'})} />
    ),
    meta: {
      styles: {
        minWidth: 100,
        width: 100,
      },
    },
    cell: ({row}) => <JourneyStatusBadge status={row.getValue('status')} />,
  },

  {
    accessorKey: 'createdAt',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_CREATED_AT_COLUMN'})} />
    ),
    cell: ({row}) => <DatetimeCell value={row.getValue('createdAt')} />,
    meta: {
      styles: {
        minWidth: 180,
      },
    },
    sortDescFirst: false,
  },
  {
    accessorKey: 'updatedAt',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_MODIFIED_AT_COLUMN'})} />
    ),
    cell: ({row}) => <DatetimeCell value={row.getValue('updatedAt')} />,
    meta: {
      styles: {
        minWidth: 180,
      },
    },
    sortDescFirst: false,
  },
  {
    accessorKey: 'publishedAt',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_PUBLISHED_AT_COLUMN'})} />
    ),
    cell: ({row}) => <DatetimeCell value={row.getValue('publishedAt')} />,
    meta: {
      styles: {
        minWidth: 180,
      },
    },
    sortDescFirst: false,
  },
  {
    accessorKey: 'country',
    header: ({column}) => (
      <DataTableColumnHeader {...column} title={formatMessage({id: 'JOURNEY_LIST.TABLE_COUNTRY_COLUMN'})} />
    ),
    cell: ({row}) => <div className="text-sm">{row.getValue('country')}</div>,
    meta: {
      styles: {
        minWidth: 80,
        width: 80,
      },
    },
  },
]

import {UserRoleContext} from '@/helpers/UserRole/UserRoleProvider'
import {getCountries} from '@/helpers/envConfig'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {store} from '@/store'
import {setJourneyMeta} from '@ReduxActions'
import {useContext, useEffect, useMemo} from 'react'

export const useCountries = (_userAccessRules?: string[]) => {
  const {userAccessRules} = useContext(UserRoleContext)

  const countries = useMemo(() => {
    const countriesFromUser = _userAccessRules || userAccessRules
    const allCountries = getCountries()
    const filteredCountries = allCountries.filter(country => countriesFromUser.includes(country))
    return countriesFromUser.length ? filteredCountries : allCountries
  }, [_userAccessRules, userAccessRules])

  const {country} = useJourneyMetaConfig()

  useEffect(() => {
    if (country && countries.includes(country)) {
      return
    }

    store.dispatch(setJourneyMeta({country: countries[0]}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {countries, country}
}

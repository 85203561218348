import {FeatureToggle} from '@/config/featureToggles'
import {mockLanguageItems} from '@/data/mockNotificationItems'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {ComponentPropsWithoutRef} from 'react'

export const LanguageSelector: React.FC<{label: string}> = ({label}) => {
  const shouldShowNewButtons = isFeatureEnabled(FeatureToggle.sendPushLanguageButtons)

  if (!shouldShowNewButtons) return null

  return (
    <div className="flex flex-col gap-2">
      <InputSelector
        id="send-notification-language"
        label={label}
        value={'Turkish'}
        onChange={() => console.log('not implemented')}
        className="text-sm"
      >
        {mockLanguageItems.map(recipient => (
          <option value={recipient} key={recipient}>
            {recipient}
          </option>
        ))}
      </InputSelector>
    </div>
  )
}

export const AddLanguageButton = (props: ComponentPropsWithoutRef<'button'>) => {
  const shouldShowNewButtons = isFeatureEnabled(FeatureToggle.sendPushLanguageButtons)

  if (!shouldShowNewButtons) return null

  return <AddNewParameterButton {...props}>Add Additional Language</AddNewParameterButton>
}

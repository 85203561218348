import usePageTitle from '@/hooks/usePageTitle'
import {PrimaryButton} from '@/shared-components/Button'
import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'

type ErrorContentProps = {
  errorType: 'FORBIDDEN' | 'NOT_FOUND' | 'SERVER_ERROR'
  isLoading?: boolean
  onClick?: () => void
}

export const ErrorContent = ({errorType, isLoading, onClick}: ErrorContentProps) => {
  const {formatMessage} = useIntl()

  usePageTitle(formatMessage({id: `PAGE_ERROR.${errorType}_HEADER`}))

  return (
    <div className="mt-20 text-center" data-testid="error-page">
      <div className="m-auto flex w-fit items-center gap-2">
        <Icon icon="mode" size="small" />
        <p className="text-2xl font-bold">{formatMessage({id: `PAGE_ERROR.${errorType}_HEADER`})}</p>
      </div>
      <p className="mt-2">{formatMessage({id: `PAGE_ERROR.${errorType}_SUBHEADER`})}</p>

      <div className="mt-8 w-full">
        <PrimaryButton isLoading={isLoading} data-testid="btn-return-login" onClick={onClick} className="m-auto">
          {formatMessage({id: `PAGE_ERROR.${errorType}_RETURN_BUTTON`})}
        </PrimaryButton>
      </div>
    </div>
  )
}

import {useHasUnsavedChanges} from '@/components/PageLeavingWarning/useHasUnsavedChanges'
import {ResultOptions} from '@/types/ResultOptions'
import {Settings} from '@/types/Settings'
import {useMutation, useQuery} from '@tanstack/react-query'
import {useCallback, useEffect, useState} from 'react'
import {getSettingsUseCase, saveSettingsUseCase} from '../useCases/settingsUseCase'

export const useSettings = ({onError}: Pick<ResultOptions, 'onError'> = {}) => {
  const {data, error, isFetching, refetch} = useQuery({
    queryKey: ['settings'],
    queryFn: ({signal}) => getSettingsUseCase(signal),
  })

  const {mutate, isPending: isSaving} = useMutation({
    mutationKey: ['saveSettings'],
    mutationFn: saveSettingsUseCase,
  })

  const [newDataValues, setNewDataValues] = useState<Settings>()

  const {hasUnsavedChanges, resetUnsavedChanges} = useHasUnsavedChanges(data, newDataValues)

  useEffect(() => {
    if (data && !newDataValues) {
      setNewDataValues(data)
    }

    if (error) {
      onError?.(error)
    }
  }, [data, error, newDataValues, onError])

  const saveSettings = useCallback(
    ({onSuccess: onSuccessCb, onError: onErrorCb}: ResultOptions = {}) => {
      if (isSaving || !newDataValues) return

      const payload = newDataValues
      mutate(payload, {
        onSuccess: () => {
          onSuccessCb?.()

          refetch().then(() => {
            resetUnsavedChanges()
            setNewDataValues(undefined)
          })
        },
        onError: onErrorCb,
      })
    },
    [isSaving, newDataValues, mutate, resetUnsavedChanges, refetch]
  )

  return {
    data,
    error,
    isFetching,
    isSaving,
    newDataValues,
    setNewDataValues,
    saveSettings,
    hasUnsavedChanges,
  }
}

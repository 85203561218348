import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {JourneyExecutionStatus} from '../../types/JourneyExecutionStatus'

const initialState: Partial<JourneyExecutionStatus> = {}

const journeyExecutionStatusSlice = createSlice({
  name: 'journeyExecutionStatus',
  initialState: initialState,
  reducers: {
    setJourneyExecutionStatus: (state, action: PayloadAction<JourneyExecutionStatus>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetJourneyExecutionStatus: () => {
      return initialState
    },
  },
})

export const {setJourneyExecutionStatus, resetJourneyExecutionStatus} = journeyExecutionStatusSlice.actions

export const journeyExecutionStatusSliceReducer = journeyExecutionStatusSlice.reducer

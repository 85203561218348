import {NotificationDetailsProps} from '@/components/Drawer/NotificationDetails'
import {EVENT_NOTIFICATION_DETAILS_QUERY_KEY} from '@/config/constants'
import {NotificationDetailsData} from '@/types/Notifications'
import {Parameters} from '@/types/Traits'
import {getNotificationByIdUseCase} from '@/useCases/getNotificationByIdUseCase'
import {useQuery} from '@tanstack/react-query'

export const useGetEventNotificationDetails = (notificationId?: string) => {
  return useQuery({
    queryKey: [EVENT_NOTIFICATION_DETAILS_QUERY_KEY, notificationId],
    queryFn: ({signal}) => getNotificationByIdUseCase({id: notificationId!}, signal),
    enabled: !!notificationId,
  })
}

// MARK: - Helpers

export const notificationsDetailsPropsMapper = (responseData?: NotificationDetailsData): NotificationDetailsProps => {
  const params = responseData?.entry_conditions?.[0]
  const eventSelectors = {
    id: 0,
    title: 'Event',
    trait: {
      id: params?.trait?.id_trait ?? -1,
      name: params?.trait?.name ?? '',
    },
    operator: {
      id: params?.operator?.id_operator ?? -1,
      name: params?.operator?.name ?? '',
    },
    value: params?.value ?? '',
    predefinedValue: {
      id: params?.id_traitvalue ?? -1,
      name: params?.value ?? '',
    },
  }

  return {
    notificationName: responseData?.name ?? '',
    notificationDescription: responseData?.description ?? '',
    country: responseData?.country_name ?? '',
    event: responseData?.eventStatus?.name ?? '',
    eventStatus: responseData?.eventStatus,
    eventSelectors: eventSelectors,
    paramSelectors: (responseData?.entry_conditions?.[0]?.traitparameters ?? []).map((item, index) => {
      return {
        id: index,
        title: `Parameter ${index + 1}`,
        trait: {
          id: item.id_traitparameter,
          name: item.trait_name,
        },
        operator: {
          id: item.id_operator,
          name: item.operator_name,
        },
        value: item.value,
        predefinedValue: {
          id: item.id_traitparametervalue ?? item.id_operatorvalue ?? item.value,
          name: item.value,
        },
      }
    }) as Parameters,
    period: responseData?.period,
    timeExecutionWindow: responseData?.timeExecutionWindow,
    fallsOnBankHoliday: responseData?.fallsOnBankHoliday,
    notificationType: responseData?.steps?.[0]?.stepType?.id_steptype?.toString() ?? '',
    subject: responseData?.steps?.[0].message_configs?.[0].subject ?? '',
    message: responseData?.steps?.[0].message_configs?.[0].message ?? '',
  }
}

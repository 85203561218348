import {useGoBack} from '@/components/Drawer/hooks/useGoBack'
import {PageLeavingWarning} from '@/components/PageLeavingWarning'
import {UnsavedChangesWarning} from '@/components/PageLeavingWarning/UnsavedChangesWarning'
import {CustomThresholdMessagesSection} from '@/components/Settings/CustomThresholdMessagesSection'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {formatMessageRichTextOptions} from '@/helpers/formatMessageRichTextOptions'
import {useSettings} from '@/pages/SettingsPage.hooks'
import {store} from '@/store'
import {CustomSettings} from '@/types/Settings'
import {showNotification} from '@ReduxActions'
import _ from 'lodash'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {ThresholdMessagesConfig} from '../components/Settings/ThresholdMessagesConfig'
import {PrimaryButton, SecondaryButton} from '../shared-components/Button'
import {ThresholdMessages} from '../types/Settings/ThresholdMessages'
import {PageContent} from './Template/components/PageContent'

export const SettingsPage = () => {
  const {formatMessage} = useIntl()
  const {goBack} = useGoBack()

  const {data, error, isFetching, isSaving, newDataValues, setNewDataValues, saveSettings, hasUnsavedChanges} =
    useSettings({
      onError: () => {
        store.dispatch(
          showNotification({
            show: true,
            type: 'error',
            title: formatMessage({id: 'GENERAL.ERROR'}),
            message: formatMessage({id: 'SETTINGS.LOAD_ERROR'}),
          })
        )
      },
    })

  const onThresholdMessageValuesDidChange = (values: ThresholdMessages) => {
    setNewDataValues({
      ...newDataValues,
      threshold: {
        ...newDataValues?.threshold,
        messages: values,
      },
    })
  }

  const onAddNewSettings = (availableCountriesToAdd: string[]) => {
    if (!data || !newDataValues) return

    const newCustomSettings: CustomSettings[] = _.cloneDeep(newDataValues.customSettings ?? [])
    newCustomSettings.push({
      countryCode: availableCountriesToAdd[0],
      threshold: data.threshold,
    })

    setNewDataValues({
      ...newDataValues,
      customSettings: newCustomSettings,
    })
  }

  const onRemoveSettings = (countryCode: string) => {
    if (!data || !newDataValues) return

    const newCustomSettings = _.cloneDeep(newDataValues.customSettings ?? []).filter(
      ({countryCode: cc}) => cc !== countryCode
    )

    setNewDataValues({
      ...newDataValues,
      customSettings: !newCustomSettings.length && !data.customSettings?.length ? undefined : newCustomSettings,
    })
  }

  const onCustomThresholdMessageValuesDidChange = (countryCode: string, values: ThresholdMessages) => {
    if (!data || !newDataValues) return

    const idx = newDataValues.customSettings?.findIndex(({countryCode: cc}) => cc === countryCode)
    if (idx === undefined || idx === -1) return

    const newCustomSettings = _.cloneDeep(newDataValues.customSettings ?? [])
    newCustomSettings[idx].threshold.messages = values

    setNewDataValues({
      ...newDataValues,
      customSettings: newCustomSettings,
    })
  }

  const onCustomThresholdCountryCodeDidChange = (idx: number, countryCode: string) => {
    if (!data || !newDataValues) return

    const newCustomSettings = _.cloneDeep(newDataValues.customSettings ?? [])
    newCustomSettings[idx].countryCode = countryCode

    setNewDataValues({
      ...newDataValues,
      customSettings: newCustomSettings,
    })
  }

  const onSaveButtonDidClick = useCallback(() => {
    saveSettings({
      onSuccess: () => {
        store.dispatch(
          showNotification({
            show: true,
            type: 'success',
            title: formatMessage({id: 'GENERAL.SUCCESS'}),
            message: formatMessage({id: 'SETTINGS.SAVE_SUCCESS'}),
          })
        )
      },
      onError: () => {
        store.dispatch(
          showNotification({
            show: true,
            type: 'error',
            title: formatMessage({id: 'GENERAL.ERROR'}),
            message: formatMessage({id: 'SETTINGS.SAVE_ERROR'}),
          })
        )
      },
    })
  }, [formatMessage, saveSettings])

  const isSaveButtonDisabled = isSaving || !hasUnsavedChanges

  return (
    <PageContent className="pb-0" title="SETTINGS.TITLE" data-testid="settings-page" data-cy="settings-page">
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="settings-loading">
          {formatMessage({id: 'GENERAL.LOADING'})}
        </p>
      )}
      {!isFetching && !!error && (
        <p className="text-md my-16 items-center text-center" data-testid="settings-error">
          {formatMessage({id: 'GENERAL.ERROR'})}
        </p>
      )}
      {!isFetching && !error && (
        <div className="flex h-full flex-col justify-between gap-6" data-testid="settings-content">
          <div className="flex flex-col gap-6">
            <section className="flex flex-col gap-6" data-testid="threshold-messages-section">
              <div>
                <h2 className="mb-1 text-lg font-medium text-text-primary">
                  {formatMessage({id: 'SETTINGS.THRESHOLD_HEADING'})}
                </h2>
                <h4 className="text-sm leading-5 text-text-secondary">
                  {formatMessage({id: 'SETTINGS.THRESHOLD_SUBHEADING'}, formatMessageRichTextOptions())}
                </h4>
              </div>
              <ThresholdMessagesConfig
                values={newDataValues?.threshold.messages}
                onChange={onThresholdMessageValuesDidChange}
                autofocus={0}
              />
            </section>

            {isFeatureEnabled(FeatureToggle.customSettings) && (
              <CustomThresholdMessagesSection
                values={newDataValues?.customSettings}
                onAddNewSettings={onAddNewSettings}
                onRemoveSettings={onRemoveSettings}
                onChange={onCustomThresholdMessageValuesDidChange}
                onChangeCountry={onCustomThresholdCountryCodeDidChange}
              />
            )}
          </div>

          <div className="-ml-6 flex w-[calc(100%+48px)] items-center justify-end gap-4 border-t bg-layer-01 px-6 py-4">
            <UnsavedChangesWarning visible={hasUnsavedChanges} />

            <SecondaryButton
              size="medium"
              onClick={goBack}
              data-testid="settings-cancel-button"
              data-cy="settings-cancel-button"
            >
              {formatMessage({id: 'GENERAL.CANCEL_BUTTON'})}
            </SecondaryButton>

            <PrimaryButton
              onClick={onSaveButtonDidClick}
              disabled={isSaveButtonDisabled}
              isLoading={isSaving}
              isLoadingText={formatMessage({id: 'GENERAL.SAVING_BUTTON'})}
              data-testid="settings-save-button"
              data-cy="settings-save-button"
            >
              {formatMessage({id: 'GENERAL.SAVE_BUTTON'})}
            </PrimaryButton>
          </div>
        </div>
      )}

      <PageLeavingWarning shouldBlock={hasUnsavedChanges} />
    </PageContent>
  )
}

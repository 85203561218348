import {Drawer} from '@/components/Drawer/Drawer'
import JourneyDrawerPages from '@/components/Drawer/JourneyDrawerPages'
import {useJourneyDrawer} from '@/components/Drawer/hooks/useJourneyDrawer'
import {useAppSelector} from '../../store'

const JourneyDrawer = () => {
  const {isOpen, selectedPage, title} = useAppSelector(state => state.journeyDrawer)
  const {onCloseDrawer} = useJourneyDrawer()

  return (
    <Drawer headerTitle={title} isOpen={isOpen} onClose={onCloseDrawer}>
      <JourneyDrawerPages selectedPage={selectedPage} />
    </Drawer>
  )
}

export default JourneyDrawer

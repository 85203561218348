import {JourneyTypeView} from '@/components/JourneyEditor/components/JourneyTypeView'
import {RecurrenceSummaryView} from '@/components/JourneyEditor/components/RecurrenceSummaryView'
import {TimeExecutionsSummaryView} from '@/components/JourneyEditor/components/TimeExecutionsSummaryView'
import {JourneyStatusBadge} from '@/components/JourneyStatusBadge/JourneyStatusBadge'
import {FeatureToggle} from '@/config/featureToggles'
import {formatJourneyPeriodDate} from '@/helpers/dateUtils'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {cn} from '@/helpers/shadcn/utils'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {JourneyStatus} from '@/types/JourneyStatus'
import {Period} from '@/types/Period'
import {openJourneyDrawer} from '@ReduxActions'
import {Divider, Icon} from 'pepsico-ds'
import {ComponentPropsWithoutRef, PropsWithChildren, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../../store'

const Paragraph = ({children, ...props}: PropsWithChildren<ComponentPropsWithoutRef<'p'>>) => (
  <p {...props} className={cn('text-xs text-text-primary', props.className)}>
    {children}
  </p>
)

const Header = ({children, ...props}: PropsWithChildren<ComponentPropsWithoutRef<'p'>>) => (
  <Paragraph {...props} className={cn('font-bold', props.className)}>
    {children}
  </Paragraph>
)

const FlexContainer = ({children, ...props}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => (
  <div {...props} className={cn('flex w-full items-center', props.className)}>
    {children}
  </div>
)

export const JourneyMetaConfig = () => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()
  const {
    name,
    description,
    country,
    isPublishedOrCompleted,
    period,
    recurrence,
    type,
    recurrenceDays,
    timeExecutionWindows,
    publishedAt,
  } = useJourneyMetaConfig()
  const formattedPublishedAt = formatJourneyPeriodDate(publishedAt, country, 'P')

  const openEditJourneyDrawer = useCallback(() => {
    dispatch(
      openJourneyDrawer({
        selectedPage: 'journeyConfigEditor',
        title: formatMessage({id: 'JOURNEY_BUILDER.CREATE_JOURNEY_HEADER'}),
      })
    )
  }, [formatMessage, dispatch])

  const countryDisplay = country ? formatMessage({id: `COUNTRIES.${country}`}) : ''

  return (
    <div className="h-full w-full overflow-y-auto" data-testid="journey-meta-config" data-cy="journey-meta-config">
      <div className="items-left flex flex-col gap-4">
        <FlexContainer className="justify-between gap-2">
          <Header
            data-cy="journey-meta-name"
            className="break-word line-clamp-2 w-fit overflow-hidden overflow-ellipsis text-base"
          >
            {name}
          </Header>
          {!isPublishedOrCompleted && (
            <button
              onClick={openEditJourneyDrawer}
              data-testid="edit-journey-config-icon"
              data-cy="edit-journey-config-icon"
              className="cursor-pointer rounded-full border-2 border-link bg-blue-100 p-0.5 text-link  hover:border-link-hover hover:text-link-hover"
            >
              <Icon icon="mode" size="small" />
            </button>
          )}
        </FlexContainer>

        <FlexContainer className="h-full flex-col gap-4 overflow-y-auto">
          <Paragraph className="self-start" data-cy="journey-meta-description">
            {description}
          </Paragraph>
          {isPublishedOrCompleted && formattedPublishedAt && (
            <FlexContainer className="gap-1">
              <JourneyStatusBadge status={JourneyStatus.published} />
              <Paragraph data-testid="published-at-date" data-cy="published-at-date">
                {formatMessage({id: 'JOURNEY_BUILDER.PUBLISHED_AT_LABEL'}, {date: formattedPublishedAt})}
              </Paragraph>
            </FlexContainer>
          )}

          <Divider />

          <JourneyTypeView type={type} />
          <DurationLabel country={country} period={period} />
          <RecurrenceSummaryView value={recurrence} selectedDays={recurrenceDays} period={period} />
          <TimeExecutionsSummaryView value={timeExecutionWindows} />
          <FlexContainer className="flex-col items-start gap-1">
            <Header>{formatMessage({id: 'JOURNEY_BUILDER.COUNTRY'})}</Header>
            <Paragraph data-cy="journey-meta-country">{countryDisplay}</Paragraph>
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>
  )
}

const DurationLabel: React.FC<{
  period?: Period
  country: string
}> = ({period, country}) => {
  const {formatMessage} = useIntl()
  const formattedStartDate = formatJourneyPeriodDate(period?.start, country, 'P')
  const formattedEndDate = formatJourneyPeriodDate(period?.end, country, 'P')
  const showDurationLabel = isFeatureEnabled(FeatureToggle.scheduleDateRange) && !!formattedStartDate

  if (!showDurationLabel) {
    return null
  }

  return (
    <FlexContainer>
      <div className="w-1/2">
        <Header>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.START_DATE'})}</Header>
        <Paragraph data-testid="journey-meta-duration-start" data-cy="journey-meta-duration-start">
          {formattedStartDate}
        </Paragraph>
      </div>
      {formattedEndDate && (
        <div className="w-1/2">
          <Header>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.END_DATE'})}</Header>
          <Paragraph data-testid="journey-meta-duration-end" data-cy="journey-meta-duration-end">
            {formattedEndDate}
          </Paragraph>
        </div>
      )}
    </FlexContainer>
  )
}

import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {
  OperatorDetailsType,
  OperatorsType,
  ParameterItem,
  Parameters,
  TraitItemType,
  TraitsType,
  ValuesType,
} from '@/types/Traits'
import NotificationParamSelector from './NotificationParamSelector'

interface Props {
  traits: TraitsType
  operators: OperatorsType
  paramSelectors: Parameters
  setParamSelectors: (value: Parameters | ((prevValue: Parameters) => Parameters)) => void
}

export type operatorsMapValueType = {
  name: string
  operatorType: string
}

export default function NotificationParamCard({
  traits,
  operators,
  paramSelectors,
  setParamSelectors,
}: Props): React.JSX.Element {
  // key is trait id, value is trait name
  const traitMap = new Map<number, string>()
  // key is trait id, value is list of all operator details
  const traitOperatorMap = new Map<number, OperatorDetailsType[]>()
  // key is trait id, value is list of all value type
  const traitValueMap = new Map<number, ValuesType | null>()
  // key is operator id, value is operator name
  const operatorMap = new Map<number, operatorsMapValueType>()

  traits?.map((trait: TraitItemType) => {
    const structuredOperators = trait.operators?.map((operatorCode: string) => {
      return operators[operatorCode]
    })
    traitMap.set(trait.id, trait.name)
    traitOperatorMap.set(trait.id, structuredOperators)
    traitValueMap.set(trait.id, trait.values)
    return {id: trait.id, name: trait.name}
  })

  Object.values(operators).forEach(value => {
    operatorMap.set(value.id, {name: value.name, operatorType: value.operatorType})
  })

  const addParamSelector = () => {
    const index = paramSelectors.length
    const newPara: ParameterItem = {
      id: index,
      title: `Parameter ${index + 1}`,
      trait: {id: -1, name: ''},
      operator: {id: -1, name: ''},
      predefinedValue: null,
      value: null,
    }
    setParamSelectors(params => [...params, newPara])
  }

  const deleteParamSelector = (paramId: number) => {
    const updatedParams = paramSelectors
      .filter(param => param.id !== paramId)
      .map((param, index) => {
        const temp = {
          ...param,
          id: index,
          title: `Parameter ${index + 1}`,
        }
        return temp
      })
    setParamSelectors(updatedParams)
  }

  const updateParamSelector = (paramId: number, paramItem: ParameterItem) => {
    const updatedParams = paramSelectors.map(param => (param.id === paramId ? paramItem : param))
    setParamSelectors(updatedParams)
  }

  return (
    <div data-testid="notification-parameters">
      {paramSelectors.length > 0 && (
        <div>
          {paramSelectors.map(param => (
            <div
              key={param.id}
              className="mb-4 w-full max-w-[678px] rounded-lg border-2 border-dashed border-blue bg-layer-02 p-4 transition-all duration-300 hover:border-layer-03"
            >
              <NotificationParamSelector
                param={param}
                deleteParam={deleteParamSelector}
                updateParam={updateParamSelector}
                traits={traits}
                operators={operators}
                traitMap={traitMap}
                traitOperatorMap={traitOperatorMap}
                traitValueMap={traitValueMap}
                operatorMap={operatorMap}
              />
            </div>
          ))}
        </div>
      )}
      <AddNewParameterButton
        className="max-w-xs"
        data-testid="add-filter-button"
        onClick={addParamSelector}
        id="add-new-parameter"
      />
    </div>
  )
}

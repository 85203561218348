import {useDeleteStepConfirmationModal} from '@/components/Canvas/customNodes/useDeleteStepConfirmationModal.hook'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import Modal from '@/shared-components/Modal/Modal'
import {Icon} from 'pepsico-ds'
import {NodeTypeEnum} from '../../../types/NodeTypeEnum'
import {CustomNodeModel} from './CustomNodeModel'

type NodeActionsProps = {
  node: CustomNodeModel
  openStepConfig: () => void
}

export const StepNodeActions = ({node, openStepConfig}: NodeActionsProps) => {
  const nodeType = node.getType() as NodeTypeEnum

  const canStepBeDeleted = ![NodeTypeEnum.entryCondition].includes(nodeType)
  const canStepBeEdited = ![NodeTypeEnum.requiresIntervention].includes(nodeType)
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const {modalData, openDeleteModalConfirmation} = useDeleteStepConfirmationModal({node})

  const shouldDisplayNodeActions = !isPublishedOrCompleted

  if (!shouldDisplayNodeActions) {
    return null
  }

  return (
    <div className="node-actions" data-testid="node-actions">
      {canStepBeEdited && (
        <button
          title="Edit step configuration"
          className="node-action-icon node-action-icon-edit"
          data-testid="edit-step-button"
          data-cy="edit-step-button"
          onClick={openStepConfig}
        >
          <Icon icon="mode" size="small" className="!text-[13px]  text-button-icon-active" />
        </button>
      )}

      {canStepBeDeleted && (
        <>
          <button
            title="Remove step"
            className="node-action-icon node-action-icon-delete"
            data-testid="remove-step-button"
            data-cy="remove-step-button"
            onClick={openDeleteModalConfirmation}
          >
            <Icon icon="delete" size="small" className="!text-[13px] text-button-icon-active" />
          </button>

          <Modal {...modalData} />
        </>
      )}
    </div>
  )
}

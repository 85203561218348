import {useSendNotificationData} from '@/components/Drawer/hooks/useSendNotificationData'
import {RecipientDropDown} from '@/components/SendNotification/RecipientDropDown'
import {TemplateMessage, TemplateMessageContainer} from '@/components/SendNotification/TemplateMessageContainer'
import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendWhatsAppStepConfigResponse} from '@/types/StepConfigParams'
import {StepSendWhatsAppConfig} from '@/types/Steps'
import {useIntl} from 'react-intl'

type SendWhatsAppStepConfigParamsProps = {
  getData: () => GetSendWhatsAppStepConfigResponse
}

export const SendWhatsAppStepConfigParams = ({getData}: SendWhatsAppStepConfigParamsProps) => {
  const {formatMessage} = useIntl()

  const {
    config: tempConfig,
    onChangeRecipient,
    onChangeTemplateAndMessage,
  } = useSendNotificationData(NodeTypeEnum.sendWhatsApp)
  const config = tempConfig as StepSendWhatsAppConfig

  const templates = getData()?.templates ?? []

  const shouldFetchFromRemote =
    isFeatureEnabled(FeatureToggle.sendPushCustomImage) || isFeatureEnabled(FeatureToggle.sendPushCustomLandingPage)

  return (
    <>
      <div className="flex w-full max-w-xs flex-col gap-4" data-testid="send-whatsapp-step-config-params">
        <RecipientDropDown
          value={config.to}
          label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.RECIPIENT_LABEL'})}
          onChange={onChangeRecipient}
          required
        />
      </div>

      <div className="w-full max-w-[678px]">
        {shouldFetchFromRemote ? (
          <TemplateMessage
            data={templates}
            selectedTemplateId={config.template}
            onChangeTemplateAndMessage={onChangeTemplateAndMessage}
          />
        ) : (
          /* Legacy */
          <TemplateMessageContainer
            selectedTemplateId={config.template}
            onChangeTemplateAndMessage={onChangeTemplateAndMessage}
          />
        )}
      </div>
    </>
  )
}

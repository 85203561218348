import {BaseEvent, BaseEventProxy, PortModel, PortModelAlignment} from '@projectstorm/react-diagrams'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomLinkModel} from '../../customLink/CustomLinkModel'
import {onAddLinkEvent} from '../../utils/NodeEventUtils'

export class SendSMSPortModel extends PortModel {
  constructor(alignment: PortModelAlignment) {
    super({
      type: NodeTypeEnum.sendSMS,
      name: alignment,
      alignment: alignment,
    })
    this.setLocked(true)
  }

  addLink(link: CustomLinkModel): void {
    super.addLink(link)
    link.clearListeners()
    link.registerListener({
      eventDidFire(event: BaseEvent & {entity?: CustomLinkModel}) {
        if (!event.entity) return

        if ((event as BaseEventProxy).function === 'sourcePortChanged') return

        onAddLinkEvent(event.entity)
      },
    })
  }
}

import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {SecondaryButton} from '@/shared-components/Button'
import {Spinner} from '@/shared-components/Spinner'
import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'

type StepConfigLoadingOrErrorProps = {
  isFetching?: boolean
  isError?: boolean
  onDidClickTryAgain?: () => void
}

export const StepConfigLoadingOrError = ({isFetching, isError, onDidClickTryAgain}: StepConfigLoadingOrErrorProps) => {
  const {formatMessage} = useIntl()

  if (isFetching) {
    return <Spinner size="medium" data-testid="loading-step-config" />
  }

  const isStepConfigLoadedFromRemote =
    isFeatureEnabled(FeatureToggle.sendPushCustomImage) || isFeatureEnabled(FeatureToggle.sendPushCustomLandingPage)

  const errorMsgId = isStepConfigLoadedFromRemote
    ? 'SEND_NOTIFICATION_CONTENT.ERROR_LOADING_STEP'
    : 'SEND_NOTIFICATION_CONTENT.ERROR_LOADING_TEMPLATES'

  if (isError) {
    return (
      <div className="mt-2 flex flex-col items-start gap-2" data-testid="error-loading-step-config">
        <div className="flex items-center gap-2">
          <Icon icon="error_outline" size="small" className=" text-support-error-primary" />
          <p className="text-sm font-medium text-red-400">{formatMessage({id: errorMsgId})}</p>
        </div>
        <SecondaryButton size="small" onClick={onDidClickTryAgain} data-testid="loading-step-config-try-again-button">
          {formatMessage({id: 'GENERAL.TRY_AGAIN_BUTTON'})}
        </SecondaryButton>
      </div>
    )
  }

  return null
}

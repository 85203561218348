import {GetAllTraitsResponse} from '@/types/Traits'
import {getAllTraitsForParameterService, getAllTraitsService} from '../services/notificationsService'

export type GetAllTraitsUseCaseParams = {
  traitType: string
}

export async function getAllTraitsEventUseCase(
  params: GetAllTraitsUseCaseParams,
  signal?: AbortSignal
): Promise<GetAllTraitsResponse> {
  return getAllTraitsService(params, signal)
}

export async function getAllTraitsParameterUseCase(
  params: GetAllTraitsUseCaseParams,
  signal?: AbortSignal
): Promise<GetAllTraitsResponse> {
  return getAllTraitsForParameterService(params, signal)
}

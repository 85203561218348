import {useSendNotificationData} from '@/components/Drawer/hooks/useSendNotificationData'
import {AutoComplete} from '@/components/SendNotification/AutoComplete'
import {CustomImageSelector} from '@/components/SendNotification/CustomImageSelector'
import {InputWithCharLimit} from '@/components/SendNotification/InputWithCharLimit'
import {LandingPageSelector} from '@/components/SendNotification/LandingPageSelector'
import {AddLanguageButton, LanguageSelector} from '@/components/SendNotification/LanguageSelector'
import {RecipientDropDown} from '@/components/SendNotification/RecipientDropDown'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendPushStepConfigResponse} from '@/types/StepConfigParams'
import {StepSendPushNotificationConfig} from '@/types/Steps'
import {MAX_MESSAGE_LENGTH, MAX_SUBJECT_LENGTH} from '@/utils/consts'
import {useIntl} from 'react-intl'

type SendPushStepConfigParamsProps = {
  getData: () => GetSendPushStepConfigResponse
}

export const SendPushStepConfigParams = ({getData}: SendPushStepConfigParamsProps) => {
  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const {
    config: tempConfig,
    onChangeRecipient,
    onChangeSubject,
    onChangeMessage,
    onChangeCustomImage,
    onChangeLandingPage,
  } = useSendNotificationData(NodeTypeEnum.sendPush)
  const config = tempConfig as StepSendPushNotificationConfig

  const customImageOptions = getData()?.images ?? []
  const landingPageOptions = getData()?.landingPages ?? []

  return (
    <div className="flex w-full max-w-xs flex-col gap-4" data-testid="send-push-step-config-params">
      <RecipientDropDown
        value={config.to}
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.RECIPIENT_LABEL'})}
        onChange={onChangeRecipient}
        required
      />

      <InputWithCharLimit
        id="send-notification-subject"
        charLimitSize={MAX_SUBJECT_LENGTH}
        inputValue={config.subject}
        placeholder={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SUBJECT_PLACEHOLDER'})}
        onChangeInput={onChangeSubject}
        displayCharLimit={!isPublishedOrCompleted}
        inputDisabled={isPublishedOrCompleted}
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SUBJECT_LABEL'})}
        required
      />

      <LanguageSelector label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.LANGUAGE_LABEL'})} />

      <AutoComplete
        inputId="send-notification-message"
        inputValue={config.message}
        onChangeInput={onChangeMessage}
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.MESSAGE_LABEL'})}
        max_message_length={MAX_MESSAGE_LENGTH}
        required
      />

      <CustomImageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_PUSH_IMAGE'})}
        options={customImageOptions}
        value={config.imageId || customImageOptions[0]?.id}
        onChange={onChangeCustomImage}
        disabled={isPublishedOrCompleted}
      />

      <LandingPageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_LANDING_PAGE'})}
        options={landingPageOptions}
        value={config.landingPageId || landingPageOptions[0]?.id}
        onChange={onChangeLandingPage}
        disabled={isPublishedOrCompleted}
      />

      <AddLanguageButton data-testid="add-aditional-language-button" />
    </div>
  )
}

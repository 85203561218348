import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {parseTime} from '@/helpers/timeUtils'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {useIntl} from 'react-intl'

type TimeExecutionsSummaryViewProps = {
  value?: TimeExecutionWindows
}

export const TimeExecutionsSummaryView = ({value}: TimeExecutionsSummaryViewProps) => {
  const {formatMessage} = useIntl()
  const shouldDisplay = isFeatureEnabled(FeatureToggle.timeExecutionWindow) && !!value?.length

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex w-full flex-col gap-1" data-testid="journey-meta-time-execution-windows">
      <p className="text-xs font-bold text-text-secondary">
        {formatMessage({id: 'JOURNEY_BUILDER.TIME_EXECUTION_WINDOW_TITLE'})}
      </p>
      {value.map(({start, end}) => {
        const [startHour, startMinute] = (start?.split(':') ?? []).map(Number)
        const [endHour, endMinute] = (end?.split(':') ?? []).map(Number)

        const {formattedAmPm: startDisplay} = parseTime(`${startHour}:${startMinute}`)
        const {formattedAmPm: endDisplay} = parseTime(`${endHour}:${endMinute}`)

        const formatId = !end
          ? 'JOURNEY_BUILDER.TIME_EXECUTION_VALUE_START_ONLY_LABEL'
          : 'JOURNEY_BUILDER.TIME_EXECUTION_VALUE_LABEL'

        return (
          <p
            key={`${start}_${end}`}
            className="text-xs text-text-primary"
            data-testid="journey-meta-time-execution-item"
            data-cy="journey-meta-time-execution-item"
          >
            {formatMessage({id: formatId}, {start: startDisplay, end: endDisplay})}
          </p>
        )
      })}
    </div>
  )
}

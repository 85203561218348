import {AppDispatch, RootState} from '@/store'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {StepSendPushNotificationConfig, StepSendSMSConfig, StepSendWhatsAppConfig} from '@/types/Steps'
import {ParamFilters, ParamsSelector} from '@/types/paramFilters/paramFilters'
import {MAX_MESSAGE_LENGTH, MAX_SUBJECT_LENGTH, isOperatorBoolean} from '@/utils/consts'
import {TypedStartListening, createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit'
import {isInvalidReEntryDaysUseCase} from '../../useCases/entryConditionUseCase'
import {
  setCurrentNode,
  updateNodeParamsSelectors,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  updateNodeWaitData,
  updateNodeWarnings,
  updateReEntryDays,
} from '../slices'

export const nodesDataWarningsListener = createListenerMiddleware()

type AppStartListening = TypedStartListening<RootState, AppDispatch>
;(nodesDataWarningsListener.startListening as AppStartListening)({
  matcher: isAnyOf(
    updateReEntryDays,
    updateNodeParamsSelectors,
    setCurrentNode,
    updateNodeWaitData,
    updateNodeSendSMSData,
    updateNodeSendWhatsAppData,
    updateNodeSendPushNotificationData
  ),
  effect: async (action, listener) => {
    if (action.type === 'nodesData/updateReEntryDays') {
      const {reentryDays, id} = action.payload
      const currentEntryConditionData = listener.getState().nodesData[id] as ParamFilters

      const haveWarnings = checkIfIsInvalidEntryConditionConfig({
        ...currentEntryConditionData,
        reentryDays,
      })

      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    } else if (action.type === 'nodesData/updateNodeParamsSelectors') {
      const {paramsSelectors, id} = action.payload as {id: string; paramsSelectors: Array<ParamsSelector>}
      const currentEntryConditionData = listener.getState().nodesData[id] as ParamFilters

      const haveWarnings = checkIfIsInvalidEntryConditionConfig({
        ...currentEntryConditionData,
        paramsSelectors,
      })

      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    } else if (action.type === 'nodesData/updateNodeWaitData') {
      const {config, id} = action.payload

      const haveWarnings = checkWaitWarnings(config.value)
      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    } else if (action.type === 'nodesData/updateNodeSendSMSData') {
      const {config, id} = action.payload

      const haveWarnings = checkNotificationsWarnings(config, NodeTypeEnum.sendSMS)
      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    } else if (action.type === 'nodesData/updateNodeSendPushNotificationData') {
      const {config, id} = action.payload

      const haveWarnings = checkNotificationsWarnings(config, NodeTypeEnum.sendPush)
      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    } else if (action.type === 'nodesData/updateNodeSendWhatsAppData') {
      const {config, id} = action.payload

      const haveWarnings = checkNotificationsWarnings(config, NodeTypeEnum.sendWhatsApp)
      listener.dispatch(updateNodeWarnings({id, haveWarnings}))
    }
  },
})

export const checkIfIsInvalidEntryConditionConfig = (entryConditionData: ParamFilters) => {
  const {reentryDays, paramsSelectors} = entryConditionData

  const isInvalidReentryDays = isInvalidReEntryDaysUseCase(reentryDays)

  const isInvalidTraits = paramsSelectors?.some(paramSelector => {
    const {selectedParamData, selectedParam} = paramSelector
    if (!selectedParam) return false
    if (isOperatorBoolean(selectedParamData.operator)) return false
    return !selectedParamData.value
  })

  return isInvalidReentryDays || isInvalidTraits
}

export const checkWaitWarnings = (timeValueParam: number | null) => {
  return Number(timeValueParam) <= 0
}

export const checkNotificationsWarnings = (
  configParam: StepSendPushNotificationConfig | StepSendSMSConfig | StepSendWhatsAppConfig,
  nodeType: NodeTypeEnum.sendPush | NodeTypeEnum.sendSMS | NodeTypeEnum.sendWhatsApp
) => {
  if (!configParam) return false
  let isInvalidMessage
  let isInvalidSubject
  let isInvalidTemplate
  switch (nodeType) {
    case NodeTypeEnum.sendSMS:
      isInvalidMessage = configParam.message.length === 0 || configParam.message.length > MAX_MESSAGE_LENGTH
      return isInvalidMessage
    case NodeTypeEnum.sendPush:
      isInvalidMessage = configParam.message.length === 0 || configParam.message.length > MAX_MESSAGE_LENGTH
      isInvalidSubject =
        !(configParam as StepSendPushNotificationConfig).subject ||
        (configParam as StepSendPushNotificationConfig).subject.length > MAX_SUBJECT_LENGTH
      return isInvalidSubject || isInvalidMessage
    case NodeTypeEnum.sendWhatsApp:
      isInvalidMessage = configParam.message.length === 0
      isInvalidTemplate = !(configParam as StepSendWhatsAppConfig).template
      return isInvalidMessage || isInvalidTemplate
    default:
      return false
  }
}

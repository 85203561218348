import {ReportWorksheet} from '@/types/Journeys'
import _ from 'lodash'
import {utils, writeFileXLSX} from 'xlsx'

export const generateExcelFile = (data: ReportWorksheet[], fileName: string) => {
  if (!data.length) return

  const workbook = utils.book_new()

  data.forEach(worksheet => {
    const ws = utils.json_to_sheet(worksheet.records)

    utils.book_append_sheet(workbook, ws, normalizeName(worksheet.name), true)
    utils.sheet_add_aoa(ws, [worksheet.columns.map(c => c.name)], {origin: 'A1'})
    ws['!cols'] = worksheet.columns.map(c => ({wch: c.name.length + 10}))
  })

  writeFileXLSX(workbook, fileName, {
    compression: true,
  })
}

const normalizeName = (name: string, maxChars: number = 30) => {
  return _.kebabCase(name).substring(0, maxChars).replace(/-$/, '')
}

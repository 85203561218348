import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {useIntl} from 'react-intl'

export interface RequiredFieldsLabelProps {
  type: NodeTypeEnum
}

export const RequiredFieldsLabel = ({type}: RequiredFieldsLabelProps) => {
  const {formatMessage} = useIntl()

  if ([NodeTypeEnum.entryCondition, NodeTypeEnum.requiresIntervention].includes(type)) {
    return null
  }

  return (
    <p className="text-sm" data-testid="step-config-required-fields">
      {formatMessage({
        id: 'JOURNEY_BUILDER.STEP_CONFIG_REQUIRED_FIELDS',
      })}
    </p>
  )
}

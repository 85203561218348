import {JourneyError} from '../types/JourneyError'

export const getErrorMessage = (error: unknown): {title: string; message: string} => {
  let title = 'Something went wrong!'
  let message = 'Please try again later.'

  const customError = error as JourneyError
  if (customError instanceof JourneyError) {
    title = customError.title
    message = customError.message
  }

  return {title, message}
}

export const getInputErrorMessage = (
  inputValue: string,
  charLimitSize: number,
  formatMessage: (messageId: {id: string}) => string
) => {
  if (inputValue.length > charLimitSize) {
    return formatMessage({id: 'SEND_NOTIFICATION_CONTENT.INVALID_LENGTH_ERROR_ABOVE_LIMIT'})
  } else if (inputValue.length === 0) {
    return formatMessage({id: 'SEND_NOTIFICATION_CONTENT.INVALID_LENGTH_ERROR'})
  }
  return undefined
}

import {JourneyPersistenceActions} from '@/components/JourneyEditor/components/JourneyPersistenceActions'
import {PageLeavingWarning} from '@/components/PageLeavingWarning'
import {UnsavedChangesWarning} from '@/components/PageLeavingWarning/UnsavedChangesWarning'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {canSaveJourney} from '@UseCases'
import {useIntl} from 'react-intl'
import {BorderlessButton, ButtonBaseProps, PrimaryButton, SecondaryButton} from '../../shared-components/Button'
import {useAppSelector} from '../../store'

type SaveJourneyActionsProps = {
  isLoading: boolean
  isExporting: boolean
  onClickSave?: () => void
  onClickCancel?: () => void
  onClickExport?: () => void
  className?: string
  journeyId?: string
}

export const SaveJourneyActions = ({
  isLoading = false,
  isExporting = false,
  onClickSave,
  onClickCancel,
  onClickExport,
  className,
  journeyId,
}: SaveJourneyActionsProps) => {
  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const canSave = useAppSelector(() => canSaveJourney() && !isLoading)

  return (
    <div className={className} data-testid="journey-actions">
      <UnsavedChangesWarning visible={canSave} />

      <BorderlessButton data-testid="btn-cancel" data-cy="btn-cancel" onClick={onClickCancel}>
        {formatMessage({id: `JOURNEY_BUILDER.${isPublishedOrCompleted ? 'BACK_BUTTON' : 'CANCEL_BUTTON'}`})}
      </BorderlessButton>

      {!isPublishedOrCompleted ? (
        <>
          <SaveDraftButton
            label={formatMessage({id: 'JOURNEY_BUILDER.SAVE_DRAFT_BUTTON'})}
            onClick={onClickSave}
            disabled={!canSave}
            isLoading={isLoading}
            isLoadingText={formatMessage({id: 'JOURNEY_BUILDER.SAVING_BUTTON'})}
            data-cy="btn-save-draft-journey"
          />

          <PageLeavingWarning shouldBlock={canSave} />
        </>
      ) : (
        <DownloadExecutionReportButton isLoading={isExporting} onClick={onClickExport} />
      )}
      <JourneyPersistenceActions journeyId={journeyId} />
    </div>
  )
}

const SaveDraftButton = ({label, isLoading, ...props}: ButtonBaseProps & {label: string}) => {
  return (
    <SecondaryButton isLoading={isLoading} data-testid="btn-save" {...props}>
      {label}
    </SecondaryButton>
  )
}

const DownloadExecutionReportButton = (props: ButtonBaseProps) => {
  const {formatMessage} = useIntl()

  return (
    <PrimaryButton iconLeading="download" data-testid="btn-download" data-cy="btn-download-execution-report" {...props}>
      {formatMessage({id: `JOURNEY_BUILDER.EXPORT_JOURNEY`})}
    </PrimaryButton>
  )
}

import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'
import {downloadFileHandler} from '@/helpers/downloadFileHandler'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {generateExcelFile} from '@/helpers/generateExcelFile'
import {getErrorMessage} from '@/helpers/getErrorMessage'
import {getFormattedCurrentTimestamp} from '@/helpers/getFormattedCurrentTimestamp'
import {useCountries} from '@/hooks/useCountries'
import {PageHeader} from '@/shared-components/PageHeader'
import {useAppDispatch} from '@/store'
import {JourneyError} from '@/types/JourneyError'
import {JourneyStatus} from '@/types/JourneyStatus'
import {GetReportDataResponse, GetReportResponse} from '@/types/Journeys'
import {setJourneyMeta, showNotification} from '@ReduxActions'
import {getRequiresInterventionReportUseCase} from '@UseCases'
import {useMutation} from '@tanstack/react-query'
import {useRef} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {JourneysListTable, JourneysListTableHandler} from '../components/JourneysListTable'
import {ROUTES} from '../config/constants'
import {PrimaryButton, SecondaryButton} from '../shared-components/Button'
import {PageContent} from './Template/components/PageContent'

export const JourneysList = () => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {countries, country} = useCountries()

  const tableListTableRef = useRef<JourneysListTableHandler>(null)

  const {mutate, isPending: isExporting} = useMutation({
    mutationKey: ['getReport'],
    mutationFn: getRequiresInterventionReportUseCase,
  })

  if (!country) {
    return null
  }

  const onClickDownloadCSV = () => {
    if (isExporting) return

    mutate(
      {},
      {
        onSuccess: result => {
          const fileName = `Journey optimizer - Require Intervention records - ${getFormattedCurrentTimestamp()}.xlsx`
          isFeatureEnabled(FeatureToggle.dataReports)
            ? generateExcelFile((result as GetReportDataResponse).content, fileName)
            : downloadFileHandler((result as GetReportResponse).path, fileName)
        },
        onError: error => {
          const isCustomError = (error as JourneyError) instanceof JourneyError
          const {title, message} = getErrorMessage(error)
          dispatch(
            showNotification({
              show: true,
              type: isCustomError ? 'warning' : 'error',
              title,
              message,
            })
          )
        },
      }
    )
  }

  const onClickCreate = () => {
    navigate(ROUTES.CREATE_JOURNEY, {state: {prevPath: window.location.pathname + window.location.search}})
  }

  const onCountryDidChange = (value: string) => {
    dispatch(setJourneyMeta({country: value}))
    tableListTableRef.current?.reload()
  }

  return (
    <PageContent
      data-testid="journeys-list-page"
      pageContentWrapperClassName="relative"
      pageHeaderContent={
        <div className="absolute -top-12 right-0 flex flex-row items-center gap-2 text-xs font-normal">
          <p className="text-nowrap">{formatMessage({id: 'GENERAL.SELECT_MARKET'})}</p>
          <CountrySelector
            id="main-country"
            countries={countries}
            status={JourneyStatus.draft}
            value={country}
            onValueChanged={onCountryDidChange}
            className="w-fit"
          />
        </div>
      }
    >
      <div className="mb-6">
        <PageHeader title={formatMessage({id: 'JOURNEY_LIST.TABLE_HEADER'})} className="relative justify-start" />

        <div className="mt-2 flex items-start gap-4 text-base" data-testid="subtitle-content">
          <div className="subtitle flex-1 text-sm text-text-secondary">
            {formatMessage({id: 'JOURNEY_LIST.TABLE_HEADER_SUBTITLE'})}
          </div>

          <div className="button-container ml-auto flex gap-4">
            {isFeatureEnabled(FeatureToggle.stepRequiresIntervention) && (
              <SecondaryButton
                size="small"
                className="ml-auto"
                data-testid="button-download-report"
                data-cy="button-download-report"
                onClick={onClickDownloadCSV}
                isLoading={isExporting}
              >
                {formatMessage({id: 'JOURNEY_BUILDER.EXPORT_RECORDS_BUTTON'})}
              </SecondaryButton>
            )}
            <PrimaryButton
              iconTrailing="playlist_add"
              size="small"
              data-cy="button-create-journey"
              data-testid="button-new-journey"
              onClick={onClickCreate}
            >
              {formatMessage({id: 'JOURNEY_LIST.CREATE_JOURNEY_BUTTON'})}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <JourneysListTable ref={tableListTableRef} />
    </PageContent>
  )
}

import {FeatureConfig} from '@/config/featureToggles'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'

export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME: '/',
  LIST_JOURNEYS: '/journeys',
  SUCCESSFUL_LOGIN: '/success',
  CREATE_JOURNEY: '/create',
  EDIT_JOURNEY: '/journey/:id',
  REPORTS: '/reports',
  SETTINGS: '/settings',
  EVENT_NOTIFICATIONS: '/events',
  EVENT_NOTIFICATION: '/events/:id',
  EVENT_NOTIFICATION_EDIT: '/events/:id/edit',
  ERROR: '/error',
  ERROR_403: '/error/403',
  ERROR_500: '/error/500',
}

export const BASE_URL_PLACEHOLDER = {
  ADMIN_PORTAL: `{{${FeatureConfig.apiAdminUrl}}}`,
  ADMIN_PORTAL_ENDPOINT: `{{${FeatureConfig.apiAdminUrlEndpointSuffix}}}`,
  EVENTS: `{{${FeatureConfig.apiEventsUrl}}}`,
}
export const ENDPOINTS = {
  // MARK: - Journey Endpoints
  LOAD_JOURNEYS: '/journeys',
  EDIT_JOURNEY: '/journeys/:id',
  SAVE_JOURNEY: '/journeys',
  PUBLISH_JOURNEY: '/journeys/:id/publish',
  JOURNEY_STATUS: '/journeys/:id/status',
  EXPORT_JOURNEY_STATUS_REPORT: '/journeys/:id/export/executions',
  EXPORT_JOURNEY_STATUS_REPORT_DATA: '/journeys/:id/export/executions/data',
  EXPORT_REQUIRES_INTERVENTION: '/journeys/:id/export/requires-intervention',
  EXPORT_REQUIRES_INTERVENTION_DATA: '/journeys/:id/export/requires-intervention/data',
  SETTINGS: '/settings',
  LOAD_STEP_CONFIG: (type: NodeTypeEnum.sendPush | NodeTypeEnum.sendWhatsApp) => {
    switch (type) {
      case NodeTypeEnum.sendPush:
        return '/step/config/push-notification'
      case NodeTypeEnum.sendWhatsApp:
        return '/step/config/whatsapp'
      default:
        throw new Error(`Unsupported step type: ${type}`)
    }
  },
  LOAD_TEMPLATES: '/message-templates/:notificationType',
  // MARK: - Admin-Portal Endpoints
  LOAD_USER_ROLES: `${BASE_URL_PLACEHOLDER.ADMIN_PORTAL}/v1/users/auth/${BASE_URL_PLACEHOLDER.ADMIN_PORTAL_ENDPOINT}`,

  // MARK: - Real-Time Events Endpoints
  LOAD_EVENTS: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications`,
  CREATE_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications`,
  UPDATE_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications/:id`,
  LOAD_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications/:id`,
  LOAD_TRAITS_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/traitsoperators`,
  LOAD_TRAITS_PAREMETER: `${BASE_URL_PLACEHOLDER.EVENTS}/traitsparametersoperators`,
  LOAD_DYNAMIC_TAG: `${BASE_URL_PLACEHOLDER.EVENTS}/messageparameters`,
}

export const DEFAULT_INTL = 'en-US'

export const STEP_CONFIG = {
  DATA_QUERY_KEY: 'stepConfig',
  DEFAULT_NODE_Y_DISTANCE: 60,
  DEFAULT_ZOOM_LEVEL: 100,
  DEFAULT_OFFSET_POSITION: {
    x: 0,
    y: 0,
  },
  DEFAULT_ENTRY_CONDITION_POSITION: {
    x: 300,
    y: 50,
  },
}

export const JOURNEY_QUERY_KEY = 'journey'
export const JOURNEY_STATUS_EXECUTION_QUERY_KEY = 'journeyStatus'
export const EVENT_NOTIFICATION_DETAILS_QUERY_KEY = 'event-notification-details'

export const FEEDBACK_DURATION = 5 * 1000 // 5 seconds
export const EXECUTION_STATUS_POOLING_INTERVAL = 15 * 1000 // 15 seconds

export const OKTA_AUTH_CONFIG_KEY = 'okta-auth-config'
export const OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage'
export const OKTA_FORBIDDEN_ERROR = 'User is not assigned to the client application.'

export const timezones = {
  UTC: 'UTC',
  AR: 'America/Argentina/Buenos_Aires',
  BR: 'America/Sao_Paulo',
  CL: 'America/Santiago',
  MX: 'America/Mexico_City',
  PL: 'Poland',
  TR: 'Turkey',
  US: 'America/Los_Angeles',
  XX: 'SUT Timezone',
  SK: 'Europe/Bratislava',
  CO: 'America/Bogota',
  DO: 'America/Santo_Domingo',
  RU: 'Europe/Moscow',
  PT: 'Europe/Lisbon',
  RO: 'Europe/Bucharest',
  ES: 'Europe/Madrid',
} as const

export const countryCodeToId: {[key: string]: number} = {
  CL: 1, // Chile
  MX: 2, // Mexico
  AR: 3, // Argentina
  TR: 4, // Turkey
  PL: 20, // Poland
  SK: 21, // Slovakia
  CH: 22, // Switzerland
  BE: 23, // Belgium
  FR: 24, // France
  LU: 25, // Luxembourg
  MC: 26, // Monaco
  CY: 27, // Cyprus
  EE: 29, // Estonia
  FI: 30, // Finland
  ES: 40, // Spain
  RU: 41, // Russia
  PT: 42, // Portugal
  RO: 43, // Romania
  DO: 44, // Dominican Republic
  CO: 45, // Colombia
}

export const countryIdToName: {[key: number]: string} = {
  1: 'Chile',
  2: 'Mexico',
  3: 'Argentina',
  4: 'Turkey',
  20: 'Poland',
  21: 'Slovakia',
  22: 'Switzerland',
  23: 'Belgium',
  24: 'France',
  25: 'Luxembourg',
  26: 'Monaco',
  27: 'Cyprus',
  29: 'Estonia',
  30: 'Finland',
  40: 'Spain',
  41: 'Russia',
  42: 'Portugal',
  43: 'Romania',
  44: 'Dominican Republic',
  45: 'Colombia',
}

export type Timezone = (typeof timezones)[keyof typeof timezones]

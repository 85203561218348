import {CanvasModel} from '@projectstorm/react-diagrams'
import {PayloadAction, createSlice} from '@reduxjs/toolkit'

interface NodeData {
  id: string
  parent?: string
  target?: string
}

type NodesData = Record<string, NodeData>

type SerializedDiagram = ReturnType<CanvasModel['serialize']>

interface DiagramDataState {
  nodes: NodesData
  canFinishDiagram: boolean
  serializedDiagram: SerializedDiagram | null
}

const initialState: DiagramDataState = {
  nodes: {},
  canFinishDiagram: false,
  serializedDiagram: null,
}

const nodesRelationsSlice = createSlice({
  name: 'nodesRelations',
  initialState,
  reducers: {
    setNodeData: (state, action: PayloadAction<NodeData>) => {
      const currentState = {...state.nodes}
      currentState[action.payload.id] = {
        ...currentState[action.payload.id],
        ...action.payload,
      }
      state.nodes = currentState
    },
    removeNodeAndUpdateRelations: (state, action: PayloadAction<{id: string}>) => {
      const currentState = {...state.nodes}
      const nodeId = action.payload.id
      const targetNodeId = currentState[nodeId].target
      const parentNodeId = currentState[nodeId].parent

      if (!parentNodeId) {
        throw new Error('Required parentNodeId is missing.')
      }

      if (targetNodeId) {
        currentState[targetNodeId].parent = parentNodeId
        currentState[parentNodeId].target = targetNodeId
      } else {
        delete currentState[parentNodeId].target
      }

      delete currentState[nodeId]

      state.nodes = currentState
    },
    setSerializedDiagram: (state, action: PayloadAction<SerializedDiagram>) => {
      state.serializedDiagram = action.payload
    },
    setFinishedDiagram: (state, action: PayloadAction<boolean>) => {
      state.canFinishDiagram = action.payload
    },
    resetNodesRelations: () => initialState,
  },
})

export const {setNodeData, removeNodeAndUpdateRelations, resetNodesRelations} = nodesRelationsSlice.actions

export const nodesRelationsSliceReducer = nodesRelationsSlice.reducer

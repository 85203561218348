import {timezones} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'

export const useTimezone = () => {
  const {country} = useJourneyMetaConfig()

  const tz = timezones[country as keyof typeof timezones]
  if (!tz) throw new Error(`Timezone not found for country ${country}`)

  return {
    country,
    tz,
  }
}

import {format, parseISO} from 'date-fns'
import {Link} from 'pepsico-ds'

interface NotificationNameCellProps {
  name: string
  onClick: () => void
}

export const NotificationNameCell = ({name, onClick}: NotificationNameCellProps) => {
  return (
    <Link data-testid="notification-name-cell" onClick={onClick}>
      {name}
    </Link>
  )
}

export const NotificationTypeCell = ({type}: {type: string}) => {
  return <div data-testid="notification-type-cell">{type}</div>
}

export const NotificationEventCell = ({eventName}: {eventName: string}) => {
  return <div data-testid="notification-event-cell">{eventName}</div>
}

export const NotificationStatusCell = ({status}: {status: string}) => {
  return <div data-testid="notification-status-cell">{status}</div>
}

// Server response is 2024-11-06, and we need to format it to 11/06/2024
export const NotificationPublishedOnCell = ({value}: {value: string}) => {
  const formatedTime = value === null ? '' : format(parseISO(value), 'MM/dd/yyyy')
  return (
    <div data-testid="published-on-cell" className="text-sm">
      {formatedTime}
    </div>
  )
}

// DiamondNodeFactory.tsx
import {AbstractReactFactory, GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {DiagramEngine} from '@projectstorm/react-diagrams-core'
import {JSX} from 'react'
import {CustomNodeModel} from './CustomNodeModel'

export class CustomNodeFactory extends AbstractReactFactory<CustomNodeModel, DiagramEngine> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateReactWidget(_event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    // event.model is basically what's returned from generateModel()
    throw new Error('Method not implemented.')
  }

  generateModel() {
    return new CustomNodeModel({})
  }
}

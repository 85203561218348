import {useSaveNodeData} from '@/helpers/SaveNodeDataProvider/useNodeDataContext.hook'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useAppDispatch, useAppSelector} from '@/store'
import {checkWaitWarnings} from '@/store/middlewares/nodesDataWarningsListener'
import {StepWait} from '@/types/Steps'
import {resetCurrentNode, updateNodeWaitData, updateNodeWarnings} from '@ReduxActions'
import _ from 'lodash'
import {useCallback, useEffect, useState} from 'react'

export const useWaitData = () => {
  const dispatch = useAppDispatch()
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const currentNodeId = useAppSelector(state => state.currentNode.id)
  const timeValueRedux = useAppSelector(state => {
    const nodeData = state.nodesData[currentNodeId] as StepWait
    return nodeData.config?.value
  })
  const [timeValue, setTimeValue] = useState<number | null>(timeValueRedux)
  const {setHasUnsavedChanges, setSaveData} = useSaveNodeData()

  const updateWaitTimeValue = useCallback(() => {
    dispatch(updateNodeWaitData({id: currentNodeId, config: {value: timeValue}}))
  }, [currentNodeId, timeValue, dispatch])

  const saveAllTempData = useCallback(
    (hasUnsavedChanges: boolean) => {
      if (isPublishedOrCompleted) return
      if (!hasUnsavedChanges) return

      updateWaitTimeValue()
      dispatch(resetCurrentNode())
    },
    [isPublishedOrCompleted, dispatch, updateWaitTimeValue]
  )

  const checkAndUpdateWaitWarnings = useCallback(
    (timeValueParam: number | null) => {
      const haveWarnings = checkWaitWarnings(timeValueParam)

      dispatch(updateNodeWarnings({id: currentNodeId, haveWarnings}))
    },
    [currentNodeId, dispatch]
  )

  useEffect(() => {
    const hasUnsavedChanges = !_.isEqual({timeValue}, {timeValue: timeValueRedux})
    setHasUnsavedChanges(hasUnsavedChanges)

    checkAndUpdateWaitWarnings(timeValue)

    setSaveData(() => () => saveAllTempData(hasUnsavedChanges))
  }, [
    currentNodeId,
    timeValueRedux,
    timeValue,
    updateWaitTimeValue,
    dispatch,
    saveAllTempData,
    setHasUnsavedChanges,
    setSaveData,
    checkAndUpdateWaitWarnings,
  ])

  return {
    timeValue,
    setTimeValue,
  }
}

import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {CurrentNodeState, NodesData, removeNodeAndUpdateRelations, removeNodeDataRef, setNodeData} from '@ReduxActions'
import {LinkModel, NodeModel} from '@projectstorm/react-diagrams'
import {store} from '../../../store'
import {NodeTypeEnum} from '../../../types/NodeTypeEnum'
import {canvasApp} from '../Application/Application'
import {CustomLinkModel} from '../customLink/CustomLinkModel'

const onAddLinkEvent = (link: LinkModel) => {
  const sourcePort = link.getSourcePort()
  const targetPort = link.getTargetPort()
  if (!targetPort) return

  const sourceNode = sourcePort.getParent().getOptions()
  const targetNode = targetPort.getParent().getOptions()

  if (!sourceNode.id || !targetNode.id) return

  linkNodes(sourceNode.id, targetNode.id)
}

export const linkNodes = (sourceId: string, targetId: string) => {
  store.dispatch(setNodeData({id: sourceId, target: targetId}))
  store.dispatch(setNodeData({id: targetId, parent: sourceId}))
}

const removeNodeAndConnectItsParentAndTarget = (nodeId: string, onSuccessCallback: () => void) => {
  const {nodes} = store.getState().nodesRelations
  const {status} = store.getState().journeyConfig

  if (isPublishedOrCompleted(status)) {
    return
  }

  const targetNodeId = nodes[nodeId].target
  const parentNodeId = nodes[nodeId].parent

  if (targetNodeId && parentNodeId) {
    let targetNode: NodeModel | null = canvasApp.getActiveDiagram().getNode(targetNodeId)
    const parentNode = canvasApp.getActiveDiagram().getNode(parentNodeId)

    const link = new CustomLinkModel()
    link.setSourcePort(parentNode.getPort('bottom'))
    link.setTargetPort(targetNode.getPort('top'))

    canvasApp.getDiagramEngine().getModel().addLink(link)

    while (targetNode) {
      if (targetNode?.getType() === NodeTypeEnum.variant) {
        console.error('TODO: Removing a variant step is not implemented, causing infinite loop!')
        targetNode = null
        continue
      }

      const nextTargetNodeId: string | undefined = nodes[targetNode.getID()].target
      targetNode = nextTargetNodeId ? canvasApp.getActiveDiagram().getNode(nextTargetNodeId) : null
    }
  }

  onSuccessCallback()
  store.dispatch(removeNodeAndUpdateRelations({id: nodeId}))
  store.dispatch(removeNodeDataRef({id: nodeId}))
}

const getParentData = (currentNode: CurrentNodeState) => {
  const nodesData = store.getState().nodesRelations.nodes
  const parentNodeId = nodesData[currentNode.id].parent
  return parentNodeId ? nodesData[parentNodeId] : null
}

export const getNext = (stepId: string) => store.getState().nodesRelations.nodes[stepId]?.target

export const cleanupOrphanSteps = ({nodesData, nodesRelations} = store.getState()) => {
  Object.values(nodesData).forEach((step: NodesData) => {
    const isOrphan = !nodesRelations.nodes[step.id]
    if (!isOrphan) return

    store.dispatch(removeNodeDataRef({id: step.id}))
  })
}

export {getParentData, onAddLinkEvent, removeNodeAndConnectItsParentAndTarget}

import {ROUTES} from '@/config/constants'
import {ErrorContent} from '@/pages/Errors/components/ErrorContent'
import {useNavigate} from 'react-router-dom'

export const PageError404 = () => {
  const navigate = useNavigate()

  const onDidClickGotoHome = () => {
    navigate(ROUTES.HOME)
    window.location.reload()
  }

  return <ErrorContent errorType="NOT_FOUND" onClick={onDidClickGotoHome} />
}

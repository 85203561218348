import {operatorsMapValueType} from '@/components/NotificationParamsFilterCard/NotificationParamCard'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {RemoveParameterButton} from '@/shared-components/RemoveParameterButton'
import {DatePicker} from '@/shared-components/shadcn/datepicker'
import {OperatorDetailsType, OperatorsType, ParameterItem, TraitsType, ValuesType} from '@/types/Traits'
import {format} from 'date-fns'
import {Dropdown} from 'pepsico-ds'
import {DropdownItem} from 'pepsico-ds/dist/Molecules/Dropdown/DropdownChild'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

type NotificationParamSelectorProps = {
  param: ParameterItem
  deleteParam: (paramId: number) => void
  updateParam: (paramId: number, paramItem: ParameterItem) => void
  traits: TraitsType
  operators: OperatorsType
  traitMap: Map<number, string>
  traitOperatorMap: Map<number, OperatorDetailsType[]>
  traitValueMap: Map<number, ValuesType | null>
  operatorMap: Map<number, operatorsMapValueType>
}

type EnumValueType = {
  id: string
  displayText: string
  isBadge: boolean
}

const dateFormatPattern = 'yyyy-MM-dd'

export default function NotificationParamSelector({
  param,
  deleteParam,
  updateParam,
  traits,
  traitMap,
  traitOperatorMap,
  traitValueMap,
  operatorMap,
}: NotificationParamSelectorProps) {
  const {formatMessage} = useIntl()

  const [trait, setTrait] = useState<number>(param.trait?.id ?? -1)
  const [operator, setOperator] = useState<number>(param.operator?.id ?? -1)
  const [operatorList, setOperatorList] = useState<OperatorDetailsType[]>([])
  // User input value will send it with string by value filed
  // Predefined dropdown vallue need to send it with id by id_traitparametervalue field
  const [customizeValue, setCustomizeValue] = useState<string | number | Date>()
  const [predefinedValue, setPredefinedValue] = useState<DropdownItem[]>([])
  const [valueType, setValueType] = useState<string>('')
  const [isInvalidAmountValue, setIsInvalidAmountValue] = useState<boolean>(true)

  const traitOptions = traits?.map(trait => (
    <option value={trait.id} key={trait.id}>
      {trait.name}
    </option>
  ))

  const operatorOptions = operatorList?.map(operator => (
    <option value={operator.id} key={operator.id}>
      {operator.name}
    </option>
  ))

  const errorMessage = isInvalidAmountValue
    ? formatMessage({id: 'NOTIFICATION_BUILDER.OPERATOR_VALUE_VALIDATION'})
    : undefined

  useEffect(() => {
    if (
      (customizeValue === undefined || customizeValue === '') &&
      (predefinedValue === undefined || predefinedValue.length === 0)
    ) {
      setIsInvalidAmountValue(true)
    } else {
      setIsInvalidAmountValue(false)
    }
  }, [customizeValue, predefinedValue])

  function handleTraitChange(selectedTrait: number) {
    const list = traitOperatorMap.get(selectedTrait) ?? []
    setTrait(selectedTrait)
    setOperatorList(list)
    const operatorId = list[0]?.id ?? -1
    const operatorDetails = operatorMap.get(operatorId)
    setOperator(operatorId)
    setCustomizeValue(undefined)
    setValueType(operatorDetails?.operatorType ?? '') // Provide a default value for operatorType
    const traitParam = {
      id: selectedTrait,
      name: traitMap.get(selectedTrait) ?? '',
    }
    const operatorParam = {
      id: operatorId,
      name: operatorDetails?.name ?? '',
    }
    const newParam = {...param, trait: traitParam, operator: operatorParam}
    updateParam(param.id, newParam)
  }

  const predefinedValuesList: EnumValueType[] = (traitValueMap.get(trait) ?? []).map(value => ({
    id: value.id.toString(),
    displayText: value.value,
    isBadge: false,
  }))

  const handleOperatorChange = (selectedOperator: number) => {
    const operatorDetails = operatorMap.get(selectedOperator)
    setOperator(selectedOperator)
    setValueType(operatorDetails?.operatorType ?? '')

    const operatorParam = {
      id: selectedOperator,
      name: operatorDetails?.name ?? '',
    }
    const newParam = {...param, operator: operatorParam}
    updateParam(param.id, newParam)
  }

  const handleValueChange = (value: string | number | Date) => {
    setCustomizeValue(value)
    let formattedValue = value
    if (value instanceof Date) {
      formattedValue = format(value.toString(), dateFormatPattern)
    }
    const newParam = {...param, value: formattedValue as string, predefinedValue: null}
    updateParam(param.id, newParam)
  }

  const handlePredefinedValueChange = (value: DropdownItem[]) => {
    setPredefinedValue(value)
    const predefinedValueParam = {id: Number(value[0]?.id ?? -1), name: value[0]?.displayText ?? ''}
    const newParam = {...param, value: null, predefinedValue: predefinedValueParam ?? null}
    updateParam(param.id, newParam)
  }

  useEffect(() => {
    handleTraitChange(param.trait?.id ?? -1)
    handleOperatorChange(param.operator?.id ?? -1)

    const selectedPredefinedValue = predefinedValuesList.filter(
      value => value.id === param.predefinedValue?.id?.toString()
    )
    handlePredefinedValueChange(selectedPredefinedValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getValueByType = function () {
    switch (valueType) {
      case 'boolean':
        return null
      case 'integer':
        return (
          <InputText
            id="param-value-integer"
            type={'number'}
            label={formatMessage({id: 'NOTIFICATION_BUILDER.PARAMETER_CONDITION_VALUE'})}
            placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.DEFINE_VALUE_PLACEHOLDER'})}
            value={customizeValue as number}
            onChange={e => handleValueChange(Number(e.target.value))}
            error={errorMessage}
            required
          />
        )
      case 'string':
        return (
          <InputText
            id="param-value-string"
            type={'text'}
            label={formatMessage({id: 'NOTIFICATION_BUILDER.PARAMETER_CONDITION_VALUE'})}
            placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.DEFINE_VALUE_PLACEHOLDER'})}
            value={customizeValue as string}
            onChange={e => handleValueChange(e.target.value)}
            error={errorMessage}
            required
          />
        )
      case 'date':
        return (
          <DatePicker
            id="param-value-date"
            className="max-w-xs"
            title={formatMessage({id: 'NOTIFICATION_BUILDER.DEFINE_VALUE_PLACEHOLDER'})}
            date={customizeValue as Date}
            setDate={date => handleValueChange(date as Date)}
            error={errorMessage}
          />
        )
      case 'relational': {
        return (
          <>
            <Dropdown
              data-testid="param-value-dropdown"
              data-cy="param-value-dropdown"
              label={formatMessage({id: 'NOTIFICATION_BUILDER.PARAMETER_CONDITION_VALUE'})}
              selection={'single'}
              childList={predefinedValuesList}
              placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.SELECT_VALUE'})}
              selectedValue={predefinedValue}
              setSelectedValue={value => handlePredefinedValueChange(value ?? [])}
              error={!!errorMessage}
              isRequired
            />
            <InfoOrError error={errorMessage} />
          </>
        )
      }
      default:
        return null
    }
  }

  return (
    <div data-testid="notification-param-selector-container">
      <div className="mb-4 flex gap-4 text-sm font-bold leading-6">
        {param.title}
        <RemoveParameterButton
          iconSize="small"
          onClick={() => {
            deleteParam(param.id)
          }}
        />
      </div>
      <div className="relative flex w-full flex-wrap items-start justify-between gap-2">
        <div className="flex w-full max-w-[300px] gap-2">
          <InputSelector
            id="trait"
            value={trait}
            onChange={e => handleTraitChange(Number(e.target.value))}
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_TRAIT'})}
            required
          >
            <option>{formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_A_PARAMETER'})}</option>
            {traitOptions}
          </InputSelector>
        </div>
        <div className="flex w-full max-w-[300px] gap-2">
          <InputSelector
            id="operator"
            value={operator}
            onChange={e => handleOperatorChange(Number(e.target.value))}
            label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_OPERATOR'})}
            required
          >
            {operatorOptions}
          </InputSelector>
        </div>
        <div className="flex w-full  max-w-[300px] flex-col gap-2">{getValueByType()}</div>
      </div>
    </div>
  )
}

import {StepWait} from '@/types/Steps'
import {useIntl} from 'react-intl'

type WaitStepInfoProps = {
  stepData: StepWait
}

export const WaitStepInfo = ({stepData}: WaitStepInfoProps) => {
  const {formatMessage} = useIntl()

  const shouldDisplay = Number(stepData.config.value) > 0

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex gap-1" data-testid="step-info-value">
      <span>
        {formatMessage({id: 'WAIT_CONTENT.CONFIGURE_LABEL'})} : {stepData.config.value}
      </span>
    </div>
  )
}

import {PrimaryButton} from '@/shared-components/Button'
import {useIntl} from 'react-intl'

export default function NotificationSubject(): React.JSX.Element {
  const {formatMessage} = useIntl()

  return (
    <div className="mt-2 rounded-lg border border-dashed border-blue-500 p-4">
      <div className="text-sm font-bold">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_SUBTITLE'})}
      </div>
      <div className="mt-2 text-xs">TimeStamp</div>
      <div className="pb-2 pt-2">
        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/PepsiCo_logo.svg" />
      </div>
      <div className="mt-4 text-sm">
        {formatMessage({id: 'NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SECTION_DESCRIPTION'})}
      </div>
      <div className="mt-4">
        <PrimaryButton
          size="large"
          data-cy="button-go-to-page"
          data-testid="button-go-to-page"
          onClick={() => {}}
          className="flex w-full justify-center"
        >
          {formatMessage({id: 'NOTIFICATION_DETAILS.GO_TO_PAGE_BUTTON'})}
        </PrimaryButton>
      </div>
    </div>
  )
}

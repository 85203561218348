import {z} from 'zod'

export const TimeExecutionWindowSchema = z.object({
  start: z.string().length(5, 'Invalid time format "hh:mm"'),
  end: z.string().length(5, 'Invalid time format "hh:mm"').optional(),
})

export const TimeExecutionWindowNotificationSchema = z.object({
  start: z.string().length(5, 'Invalid time format "hh:mm"'),
  end: z.string().length(5, 'Invalid time format "hh:mm"'),
})

export type TimeExecutionWindow = z.infer<typeof TimeExecutionWindowSchema>
export type TimeExecutionWindowNotification = z.infer<typeof TimeExecutionWindowNotificationSchema>

export const TimeExecutionWindowsSchema = z.array(TimeExecutionWindowSchema)
export type TimeExecutionWindows = z.infer<typeof TimeExecutionWindowsSchema>

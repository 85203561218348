import {getSettingsService, saveSettingsService} from '../services/settingsService'
import {GetSettingsResponse, SaveSettingsRequest, SaveSettingsResponse} from '../types/Settings'

export async function getSettingsUseCase(signal?: AbortSignal): Promise<GetSettingsResponse> {
  return getSettingsService(signal)
}

export async function saveSettingsUseCase(data: SaveSettingsRequest): Promise<SaveSettingsResponse> {
  return saveSettingsService(data)
}

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {cn} from '@/helpers/shadcn/utils'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {Spinner} from '@/shared-components/Spinner'
import {CustomImageItem} from '@/types/StepConfigParams'
import React, {ComponentPropsWithoutRef} from 'react'

export type CustomImageSelectorProps = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
  label: string
  options: CustomImageItem[]
  value?: string
  disabled?: boolean
  onChange: (newValue: string) => void
}

export const CustomImageSelector = ({
  label,
  options,
  value,
  onChange,
  className,
  disabled,
  ...props
}: CustomImageSelectorProps) => {
  const imageUrl = options.find(option => option.id === value)?.url
  const [isImageLoading, setIsImageLoading] = React.useState(!!imageUrl)

  const onChangeSelection = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const imageId = evt.target.value

    // Set isLoading only when the image is different from the current one,
    // otherwise the loading spinner will be shown forever
    const currentImgUrl = options.find(option => option.id === value)?.url
    const newImgUrl = options.find(option => option.id === imageId)?.url
    const willImageLoad = !!newImgUrl && currentImgUrl !== newImgUrl
    setIsImageLoading(willImageLoad)

    onChange(imageId)
  }

  const onLoadImageHandler = () => {
    setIsImageLoading(false)
  }

  if (!isFeatureEnabled(FeatureToggle.sendPushCustomImage) || !options.length) {
    return null
  }

  const isDisabled = disabled || options.length <= 1

  return (
    <div data-testid="custom-image" className={cn('flex gap-4', className)} {...props}>
      <InputSelector
        id="custom-image"
        label={label}
        value={value}
        onChange={onChangeSelection}
        disabled={isDisabled}
        className="justify-start align-top"
      >
        {options.map((option, idx) => (
          <option value={option.id} key={option.id}>
            {option.title ?? `Image ${idx + 1}`}
          </option>
        ))}
      </InputSelector>

      {imageUrl && (
        <div
          className="relative flex h-20 w-20 min-w-20 justify-center border border-layer-01-hover align-middle"
          data-testid="custom-image-preview-wrapper"
        >
          {isImageLoading && (
            <div className="absolute p-6" data-testid="custom-image-preview-loader">
              <Spinner />
            </div>
          )}
          <img
            src={imageUrl}
            alt={`${options.find(option => option.id === value)?.title}`}
            onLoad={onLoadImageHandler}
            className={cn('h-full w-full object-contain', isImageLoading ? 'hidden' : '')}
            data-testid="custom-image-preview"
            data-cy="custom-image-preview"
          />
        </div>
      )}
    </div>
  )
}

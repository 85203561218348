import {WhatsAppIcon} from '@Icons'
import {Icon} from 'pepsico-ds'
import {FeatureToggle, isFeatureEnabled} from '../helpers/featureToggle'
import {NodeTypeEnum} from '../types/NodeTypeEnum'

const defaultCfg = {
  color: 'rgba(255,255,255, 0.75)',
  isEnabled: () => true,
}

export const nodesConfig = [
  {
    ...defaultCfg,
    name: 'Wait',
    type: NodeTypeEnum.wait,
    icon: <Icon icon="watch_later" size="small" className="text-button-icon" />,
  },
  {
    ...defaultCfg,
    name: 'Variant: Yes / No',
    type: NodeTypeEnum.variant,
    icon: <Icon icon="filter_alt" size="small" className="text-button-icon" />,
    isEnabled: () => false,
  },
  {
    ...defaultCfg,
    name: 'Send Push',
    type: NodeTypeEnum.sendPush,
    icon: <Icon icon="mail" size="small" className="text-button-icon" />,
    isEnabled: () => isFeatureEnabled(FeatureToggle.stepSendPush),
  },
  {
    ...defaultCfg,
    name: 'Send SMS',
    type: NodeTypeEnum.sendSMS,
    icon: <Icon icon="sms" size="small" className="text-button-icon" />,
    isEnabled: () => isFeatureEnabled(FeatureToggle.stepSendSms),
  },
  {
    ...defaultCfg,
    name: 'Send WhatsApp',
    type: NodeTypeEnum.sendWhatsApp,
    icon: <WhatsAppIcon color="white" />,
    isEnabled: () => isFeatureEnabled(FeatureToggle.stepSendWhatsApp),
  },
  {
    ...defaultCfg,
    name: 'Requires Intervention',
    type: NodeTypeEnum.requiresIntervention,
    icon: <Icon icon="pan_tool" size="small" className="text-button-icon" />,
    isEnabled: () => isFeatureEnabled(FeatureToggle.stepRequiresIntervention),
  },
]

import {mockNotificationItems} from '@/data/mockNotificationItems'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  value: string
  onChange: (newValue: string) => void
  label?: string
  required?: boolean
  className?: string
}

export const RecipientDropDown: React.FC<Props> = ({value, onChange, label, required, className}) => {
  const {formatMessage} = useIntl()

  const onChangeRecipient = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const recipient = evt.target.value
    onChange(recipient)
  }

  const items = mockNotificationItems()
  const isDisabled = items.length <= 1

  return (
    <div data-testid="send-notification-recipient" className={className}>
      <InputSelector
        id="send-notification-recipient"
        label={label}
        value={value}
        onChange={onChangeRecipient}
        disabled={isDisabled}
        required={required}
      >
        {items.map(recipient => (
          <option disabled={!recipient.isEnabled} value={recipient.id} key={recipient.id}>
            {formatMessage({id: recipient.name})}
          </option>
        ))}
      </InputSelector>
    </div>
  )
}

import {CustomNodeModel} from '@/components/Canvas/customNodes/CustomNodeModel'
import {CustomPortModel} from '@/components/Canvas/customNodes/CustomPortModel'
import {useAppSelector} from '@/store'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {useIntl} from 'react-intl'

export const useWidgetInfo = (node: CustomNodeModel) => {
  const isSelected = node.getOptions().selected
  const nodeId = node.getID()
  const {formatMessage} = useIntl()
  const haveWarnings = useAppSelector(state => !!state.nodesData[nodeId]?.haveWarnings)

  const portTop = node.getPort(PortModelAlignment.TOP) as CustomPortModel
  const portBottom = node.getPort(PortModelAlignment.BOTTOM) as CustomPortModel

  return {
    isSelected,
    haveWarnings,
    portTop,
    portBottom,
    formatMessage,
  }
}

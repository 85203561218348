import {GenerateWidgetEvent} from '@projectstorm/react-diagrams'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {SendPushNodeModel} from './SendPushNodeModel'
import {SendPushNodeWidget} from './SendPushNodeWidget'

export class SendPushNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.sendPush)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <SendPushNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new SendPushNodeModel()
  }
}

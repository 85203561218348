import {cn} from '@/helpers/shadcn/utils'
import {LoadingSpinner} from 'pepsico-ds'
import {ComponentProps} from 'react'

type SpinnerProps = ComponentProps<'div'> & {
  size?: 'small' | 'medium' | 'large'
}

export const Spinner = ({size = 'medium', className, ...props}: SpinnerProps) => {
  const sizeMap = {
    small: 'h-4 w-4',
    medium: 'h-6 w-6',
    large: 'h-8 w-8',
  }

  return (
    <div className={cn(sizeMap[size], className)}>
      <LoadingSpinner className="!h-full !w-full" {...props} />
    </div>
  )
}

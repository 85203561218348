import {RequiredFieldsLabel} from '@/components/Drawer/RequiredFieldsLabel'
import {useAppSelector} from '@/store'
import {useIntl} from 'react-intl'

type DrawerConfigTitleProps = {
  subtitle?: string
}

export const DrawerConfigTitle = ({subtitle}: DrawerConfigTitleProps) => {
  const {selectedStepId} = useAppSelector(state => state.stepsSelector)
  const stepType = useAppSelector(state => state.currentNode.type)

  const isAddingNewStep = !!selectedStepId
  const {formatMessage} = useIntl()

  const title = formatMessage({id: `STEP_NAME.${stepType}`})

  return (
    <div className="mb-4 flex flex-col gap-4">
      {isAddingNewStep ? (
        stepType && <RequiredFieldsLabel type={stepType} />
      ) : (
        <>
          {title && <p className="text-md font-bold">{title}</p>}
          {stepType && <RequiredFieldsLabel type={stepType} />}
          {subtitle && <p className="text-sm">{subtitle}</p>}
        </>
      )}
    </div>
  )
}

import {z} from 'zod'

export const UserRoleEnumSchema = z.enum(['admin', 'member'])
export type UserRoleEnum = z.infer<typeof UserRoleEnumSchema>

const UserAccessRulesSchema = z.array(
  z.object({
    name: z.string(),
    values: z.array(z.string()),
  })
)
export type UserAccessRules = z.infer<typeof UserAccessRulesSchema>

export const UserRoleSchema = z.array(
  z.object({
    role: UserRoleEnumSchema,
    accessRules: UserAccessRulesSchema.optional(),
  })
)

export type UserRole = z.infer<typeof UserRoleSchema>

export const GetUserRoleRequestSchema = z.void()
export const GetUserRoleResponseSchema = UserRoleSchema

export type GetUserRoleRequest = z.infer<typeof GetUserRoleRequestSchema>
export type GetUserRoleResponse = z.infer<typeof GetUserRoleResponseSchema>

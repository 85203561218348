import {cn} from '@/helpers/shadcn/utils'
import usePageTitle from '@/hooks/usePageTitle'
import {ComponentPropsWithoutRef, PropsWithChildren, useMemo} from 'react'
import {useParams} from 'react-router-dom'

type PageHeaderProps = PropsWithChildren<ComponentPropsWithoutRef<'div'>> & {
  title: string
}

export const PageHeader = ({title, className, children}: PageHeaderProps) => {
  const params = useParams()

  const pageTitle = useMemo(() => {
    const {id} = params
    const pageSubContext = id ? ` [${id}]` : ''
    return title + pageSubContext
  }, [params, title])

  usePageTitle(pageTitle)

  return (
    <div className={cn('flex w-full items-center justify-between gap-4 font-bold text-text-primary', className)}>
      <h1 className="flex-wrap text-2xl">{title}</h1>
      {children}
    </div>
  )
}

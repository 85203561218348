import {store} from '@/store'
import {DeleteJourneyResponse} from '@/types/Journeys'
import {resetHasUnsavedChanges} from '@ReduxActions'
import {deleteJourneyService} from '../services/journeysService'

export type DeleteJourneyUseCaseParams = {
  id?: string
}

export async function deleteJourneyUseCase({
  id,
}: DeleteJourneyUseCaseParams): Promise<DeleteJourneyResponse | undefined> {
  if (!id) return

  return deleteJourneyService(id).then(result => {
    store.dispatch(resetHasUnsavedChanges())
    return result
  })
}

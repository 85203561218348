import {setInitialNodeSendNotificationData} from '@ReduxActions'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {store} from '../../../../store'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {removeNodeAndConnectItsParentAndTarget} from '../../utils/NodeEventUtils'
import {CustomNodeModel} from '../CustomNodeModel'
import {SendSMSPortModel} from './SendSMSPortModel'

// this can be further extended for more complicated node types
export class SendSMSNodeModel extends CustomNodeModel {
  constructor(id?: string) {
    const obj = {
      type: NodeTypeEnum.sendSMS,
      id,
      extras: {
        name: 'Send SMS',
        priority: 4,
        nodeSize: 250,
      },
    }
    if (!id) delete obj.id

    super(obj)

    this.addPort(new SendSMSPortModel(PortModelAlignment.TOP))
    this.addPort(new SendSMSPortModel(PortModelAlignment.BOTTOM))

    store.dispatch(
      setInitialNodeSendNotificationData({
        id: this.getID(),
        type: NodeTypeEnum.sendSMS,
      })
    )
  }

  remove() {
    removeNodeAndConnectItsParentAndTarget(this.getID(), () => super.remove())
  }
}

import NotificationDrawer from '@/components/Drawer/NotificationDrawer'
import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'
import {ROUTES} from '@/config/constants'
import {useCountries} from '@/hooks/useCountries'
import {useAppDispatch} from '@/store'
import {JourneyStatus} from '@/types/JourneyStatus'
import {setJourneyMeta} from '@ReduxActions'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import NotificationList from './NotificationList'

export default function TriggerBasedNotification(): React.JSX.Element {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const {countries, country} = useCountries()

  const navigate = useNavigate()
  const {pathname} = useLocation()
  const isEditing = pathname?.includes('/edit')

  const onSetIsOpen = (value: boolean) => {
    setIsOpen(value)

    if (!value) {
      navigate(ROUTES.EVENT_NOTIFICATIONS)
    }
  }

  const onCountryDidChange = (value: string) => {
    dispatch(setJourneyMeta({country: value}))
  }

  return (
    <div className="mt-5 grid gap-4" data-testid="trigger-based-notification-container">
      <div className="flex items-center justify-between">
        <p className="text-3xl font-bold">Welcome Back, User!</p>
        <div className="right-0 flex flex-row items-center gap-2 text-xs font-normal">
          <p className="text-nowrap">{formatMessage({id: 'GENERAL.SELECT_MARKET'})}</p>
          <CountrySelector
            id="main-country"
            status={JourneyStatus.draft}
            countries={countries}
            value={country}
            onValueChanged={onCountryDidChange}
          />
        </div>
      </div>

      <div className="grid">
        <NotificationList setIsOpen={setIsOpen} />
      </div>
      <NotificationDrawer isOpen={isOpen || isEditing} setIsOpen={onSetIsOpen} />
    </div>
  )
}

import {
  GetUserRoleRequest,
  GetUserRoleRequestSchema,
  GetUserRoleResponse,
  GetUserRoleResponseSchema,
} from '@/types/UserRole'
import {ENDPOINTS} from '../config/constants'
import api, {HTTPMethod} from '../helpers/api'

export const getUserRoleService = (signal?: AbortSignal) => {
  return api<GetUserRoleRequest, GetUserRoleResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_USER_ROLES,
    requestSchema: GetUserRoleRequestSchema,
    responseSchema: GetUserRoleResponseSchema,
    signal,
  })()
}

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {cn} from '@/helpers/shadcn/utils'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {LandingPageItem} from '@/types/StepConfigParams'
import React, {ComponentPropsWithoutRef} from 'react'

export type LandingPageSelectorProps = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
  label: string
  options: LandingPageItem[]
  value?: string
  disabled?: boolean
  onChange: (newValue: string) => void
}

export const LandingPageSelector = ({
  label,
  options,
  value,
  onChange,
  className,
  disabled,
  ...props
}: LandingPageSelectorProps) => {
  const onChangeSelection = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const landingPageId = evt.target.value
    onChange(landingPageId)
  }

  if (!isFeatureEnabled(FeatureToggle.sendPushCustomLandingPage) || !options.length) {
    return null
  }

  const isDisabled = disabled || options.length <= 1

  return (
    <div data-testid="landing-page" className={cn('flex gap-4', className)} {...props}>
      <InputSelector
        id="landing-page"
        label={label}
        value={value}
        onChange={onChangeSelection}
        disabled={isDisabled}
        className="justify-start align-top"
      >
        {options.map(option => (
          <option value={option.id} key={option.id}>
            {option.title}
          </option>
        ))}
      </InputSelector>
    </div>
  )
}

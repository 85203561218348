import {mapKeys, reduce} from 'lodash'
import {defineMessages} from 'react-intl'

import enUS from './i18n/en-us'
import esES from './i18n/es-es'

export function flattenObject(nestedMessages: Record<string, Record<string, string>>) {
  return reduce(
    nestedMessages,
    (messages, message, messageKey) =>
      Object.assign(
        messages,
        mapKeys(message, (_, key) => `${messageKey}.${key}`)
      ),
    {}
  )
}

const useInternationalization = (language = 'en-us'): [string, Record<string, string>] => {
  const DEFAULT_LANGUAGES_KEY = 'enUS'
  const LANGUAGE_MESSAGES = {
    enUS,
    esES,
  }
  const LANGUAGE_MESSAGES_KEYS = Object.keys(LANGUAGE_MESSAGES) as Array<keyof typeof LANGUAGE_MESSAGES>
  const languageToUse =
    LANGUAGE_MESSAGES_KEYS.find(key => key.toLocaleLowerCase() === language.toLocaleLowerCase().replace('-', '')) ??
    DEFAULT_LANGUAGES_KEY
  const messagesByLanguage = LANGUAGE_MESSAGES[languageToUse]
  const messages = defineMessages(flattenObject(messagesByLanguage))
  return [language, messages]
}

export default useInternationalization

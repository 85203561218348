import {isStatusEditable} from '@/helpers/statusHandler'
import {createListenerMiddleware} from '@reduxjs/toolkit'
import {setHasUnsavedChanges, setJourneyStatus} from '../slices'

export const journeyStatusListener = createListenerMiddleware()

journeyStatusListener.startListening({
  actionCreator: setJourneyStatus,
  effect: async (action, listener) => {
    const status = action.payload

    const hasUnsavedChanges = isStatusEditable(status)
    listener.dispatch(setHasUnsavedChanges(hasUnsavedChanges))
  },
})

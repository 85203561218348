import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {InputText} from '@/shared-components/InputText'
import {JourneyTypeSchema} from '@/types/JourneyType'
import {isInvalidReEntryDaysUseCase} from '@/useCases/entryConditionUseCase'
import {useIntl} from 'react-intl'

interface Props {
  reentryDays: number | null
  setReentryDays: (value: number | null) => void
}

export const ReentryDaysInput: React.FC<Props> = ({reentryDays, setReentryDays}) => {
  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted, type} = useJourneyMetaConfig()

  const invalidReentryDays = isInvalidReEntryDaysUseCase(reentryDays)

  const updateReEntryDaysValue = (newValue: string) => {
    if (isPublishedOrCompleted) return
    const valueFiltered = newValue === '' ? null : parseInt(newValue, 10)
    setReentryDays(valueFiltered)
  }

  const displayReentryDays = reentryDays ?? ''

  if (type === JourneyTypeSchema.enum.ONE_TIME) return null

  return (
    <div className="max-w-xs" data-cy="reentry-days">
      <InputText
        id="reentry-days"
        onChange={evt => updateReEntryDaysValue(evt.target.value)}
        type="number"
        disabled={isPublishedOrCompleted}
        value={displayReentryDays}
        autoFocus
        label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.REENTRY_DAYS'})}
        info={formatMessage({id: 'ENTRY_CONDITION_CONTENT.REENTRY_DAYS_HELP'})}
        error={invalidReentryDays ? formatMessage({id: 'ENTRY_CONDITION_CONTENT.REENTRY_DAYS_ERROR'}) : undefined}
      />
    </div>
  )
}

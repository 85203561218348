import {nodesConfig} from '@/config/canvas'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

export type AddStepTypeSelectorProps = {
  onChange: (stepType: NodeTypeEnum) => void
  journeyType?: JourneyType
  stepType?: NodeTypeEnum
  isLastStep?: boolean
}

export const AddStepTypeSelector = ({isLastStep, stepType, onChange, journeyType}: AddStepTypeSelectorProps) => {
  const {formatMessage} = useIntl()

  const options = useMemo(() => {
    return nodesConfig
      .filter(node => node.isEnabled())
      .filter(hideRequiresInterventionStep(isLastStep))
      .filter(hideOneTimeSteps(journeyType))
  }, [isLastStep, journeyType])

  return (
    <>
      <InputSelector
        id="step-type"
        label={formatMessage({id: 'JOURNEY_BUILDER.ADD_NEW_STEP_TYPE_SELECT'})}
        value={stepType ?? ''}
        onChange={evt => onChange(evt.target.value as NodeTypeEnum)}
        className="shadow-none mb-4 w-full max-w-xs border-border text-sm outline-none hover:border-border-hover"
      >
        <option value="-">{formatMessage({id: 'JOURNEY_BUILDER.ADD_NEW_SELECT_STEP_EMPTY_OPTION'})}</option>
        {options.map(({name: label, type: optionType}) => {
          return (
            <option value={optionType} key={optionType}>
              {label}
            </option>
          )
        })}
      </InputSelector>
    </>
  )
}

const hideRequiresInterventionStep = (isLastStep = false) => {
  return ({type}: {type: NodeTypeEnum}) => {
    return type !== NodeTypeEnum.requiresIntervention || isLastStep
  }
}

const hideOneTimeSteps = (journeyType?: JourneyType) => {
  return ({type}: {type: NodeTypeEnum}) => {
    if (journeyType === JourneyTypeSchema.enum.ONE_TIME) {
      return ![NodeTypeEnum.wait, NodeTypeEnum.requiresIntervention].includes(type)
    }
    return true
  }
}

import {AbstractModelFactory, GenerateModelEvent} from '@projectstorm/react-canvas-core'
import {DiagramEngine, PortModel} from '@projectstorm/react-diagrams'

export class CustomPortFactory extends AbstractModelFactory<PortModel, DiagramEngine> {
  cb: (initialConfig?: unknown) => PortModel

  constructor(type: string, cb: (initialConfig?: unknown) => PortModel) {
    super(type)
    this.cb = cb
  }

  generateModel(event: GenerateModelEvent): PortModel {
    return this.cb(event.initialConfig)
  }
}

import {JourneyStatus} from '@/types/JourneyStatus'
import {useMutation} from '@tanstack/react-query'
import {DateTime} from 'luxon'
import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {PrimaryButton} from '../../../shared-components/Button'
import Modal from '../../../shared-components/Modal/Modal'
import {store, useAppDispatch, useAppSelector} from '../../../store'
import {setJourneyMeta, setJourneyStatus, showNotification} from '../../../store/slices'
import {NodeTypeEnum} from '../../../types/NodeTypeEnum'
import {ParamFilters} from '../../../types/paramFilters/paramFilters'
import {publishJourneyUseCase} from '../../../useCases'

type PublishJourneyButtonProps = {
  journeyId?: string
  isDisabled: boolean
  onIsPublishingHandler?: (isPublishing: boolean) => void
}

export const PublishJourneyButton = ({journeyId, isDisabled, onIsPublishingHandler}: PublishJourneyButtonProps) => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()

  const [isPublishWarningModalOpen, setIsPublishWarningModalOpen] = useState(false)
  const [currentModalData, setCurrentModalData] = useState<ModalContent>({
    content: '',
    title: '',
    confirmLabel: '',
    onConfirm: () => setIsPublishWarningModalOpen(false),
  })

  const {mutate: mutatePublish, isPending: isLoading} = useMutation({
    mutationKey: ['publishJourney'],
    mutationFn: publishJourneyUseCase,
  })

  const nodesData = useAppSelector(state => state.nodesData)
  const {period} = useAppSelector(state => state.journeyConfig)

  const checkIfJourneyHaveAnyValidTraits = useCallback(() => {
    const entryConditionNode = Object.values(nodesData).find(
      (node): node is ParamFilters => node.type === NodeTypeEnum.entryCondition
    )
    if (!entryConditionNode) return false

    const isAllParamSelectorValid = entryConditionNode.paramsSelectors.filter(
      paramSelector => paramSelector.selectedParam === ''
    )

    return !!isAllParamSelectorValid.length
  }, [nodesData])

  const publish = useCallback(() => {
    if (isDisabled) return

    onIsPublishingHandler?.(true)
    mutatePublish(
      {id: journeyId},
      {
        onSuccess: result => {
          onIsPublishingHandler?.(false)
          onPublishJourney.Success()
          dispatch(setJourneyMeta({publishedAt: result?.publishedAt}))
          dispatch(setJourneyStatus(JourneyStatus.published))
        },
        onError: () => {
          onIsPublishingHandler?.(false)
          onPublishJourney.Error()
        },
      }
    )
    setIsPublishWarningModalOpen(false)
  }, [isDisabled, onIsPublishingHandler, mutatePublish, journeyId, dispatch])

  const showPublishConfirmationModal = useCallback(() => {
    const diffInDays = period?.start ? DateTime.utc().diff(DateTime.fromISO(period.start), 'days').days : 0
    const isStartingDatePast = diffInDays > 1

    setCurrentModalData({
      content: (
        <div className="flex flex-col gap-4 text-sm">
          <p data-testid="publish-confirmation-modal-message">
            {formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_CONFIRM_MODAL_MESSAGE'})}
          </p>
          {isStartingDatePast && (
            <p className="text-xs italic" data-testid="publish-confirmation-modal-start-date-warning">
              {formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_CONFIRM_MODAL_START_DATE_WARNING'})}
            </p>
          )}
        </div>
      ),
      title: formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_CONFIRM_MODAL_TITLE'}),
      confirmLabel: formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_CONFIRM_MODAL_CONFIRM'}),
      onConfirm: publish,
    })
    setIsPublishWarningModalOpen(true)
  }, [formatMessage, period, publish])

  const showPublishAnywayWarningModal = useCallback(() => {
    setIsPublishWarningModalOpen(true)
    setCurrentModalData({
      content: (
        <p className="text-sm" data-testid="no-content-warning-modal-message">
          {formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_WARNING_MODAL_MESSAGE'})}
        </p>
      ),
      title: formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_WARNING_MODAL_TITLE'}),
      confirmLabel: formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_WARNING_MODAL_CONFIRM'}),
      onConfirm: showPublishConfirmationModal,
    })
  }, [formatMessage, showPublishConfirmationModal])

  const onClickPublish = useCallback(() => {
    if (checkIfJourneyHaveAnyValidTraits()) {
      showPublishAnywayWarningModal()
      return
    }

    showPublishConfirmationModal()
  }, [checkIfJourneyHaveAnyValidTraits, showPublishConfirmationModal, showPublishAnywayWarningModal])

  return (
    <>
      <PrimaryButton
        data-testid="publish-button"
        data-cy="publish-button"
        onClick={onClickPublish}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {formatMessage({id: 'JOURNEY_BUILDER.PUBLISH_BUTTON'})}
      </PrimaryButton>

      <Modal
        onClose={() => setIsPublishWarningModalOpen(false)}
        onClickSecondaryButton={() => setIsPublishWarningModalOpen(false)}
        onClickPrimaryButton={currentModalData.onConfirm}
        isOpen={isPublishWarningModalOpen}
        title={currentModalData.title}
        primaryButtonText={currentModalData.confirmLabel}
        content={currentModalData.content}
      />
    </>
  )
}

// MARK: - Publish Journey action

const onPublishJourney = {
  Success: () => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'success',
        title: 'Success',
        message: 'Your Journey have been published!',
      })
    )
  },
  Error: () => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'error',
        title: 'Something went wrong!',
        message: 'Please try again later.',
      })
    )
  },
}

interface ModalContent {
  content: React.ReactNode
  title: string
  confirmLabel: string
  onConfirm: () => void
}

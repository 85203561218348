import classNames from 'classnames'
import {Icon} from 'pepsico-ds'
import {HTMLAttributes} from 'react'

interface DataTableColumnHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  getCanSort: () => boolean
  getNextSortingOrder: () => 'asc' | 'desc' | false
  clearSorting: () => void
  toggleSorting: (isDesc: boolean) => void
  getIsSorted: () => 'asc' | 'desc' | false
}

export function DataTableColumnHeader({
  title,
  getCanSort,
  getNextSortingOrder,
  clearSorting,
  toggleSorting,
  getIsSorted,
  className,
}: DataTableColumnHeaderProps) {
  if (!getCanSort()) {
    return (
      <div className={className} data-testid="column-header" data-cy="column-header">
        {title}
      </div>
    )
  }

  const onSortingChangeDidClick = () => {
    !getNextSortingOrder() ? clearSorting() : toggleSorting(getNextSortingOrder() === 'desc')
  }

  return (
    <div
      className={classNames('flex cursor-pointer select-none items-center', className)}
      onClick={onSortingChangeDidClick}
      data-testid="column-header"
      data-cy="column-header"
    >
      <span className="text-xs font-bold text-text-secondary">{title}</span>

      <span className="w-8">
        {getIsSorted() === 'asc' && (
          <Icon icon="expand_less" size="small" data-testid="sorting-asc" data-cy="sorting-asc" />
        )}
        {getIsSorted() === 'desc' && (
          <Icon icon="expand_more" size="small" data-testid="sorting-desc" data-cy="sorting-desc" />
        )}
      </span>
    </div>
  )
}

import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {AlertNotification} from '../../types/AlertNotification'

type NotificationsProps = {
  show: boolean
  type: AlertNotification
  title?: string
  message: string
}

const initialState: NotificationsProps = {
  show: false,
  type: 'success',
  title: '',
  message: '',
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<NotificationsProps>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetNotifications: () => initialState,
  },
})

export const {showNotification, resetNotifications} = notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer

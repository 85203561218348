import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {InputSelector, InputSelectorProps} from '@/shared-components/InputSelector/InputSelector'
import {JourneyStatus} from '@/types/JourneyStatus'
import {useIntl} from 'react-intl'

export type CountrySelectorProps = InputSelectorProps & {
  status: JourneyStatus
  countries: string[]
  onValueChanged?: (countryCode: string) => void
}

export const CountrySelector = ({status, countries, onValueChanged, disabled, ...props}: CountrySelectorProps) => {
  const {formatMessage} = useIntl()

  const isDisabled = disabled || countries.length <= 1 || isPublishedOrCompleted(status)

  return (
    <InputSelector {...props} onChange={evt => onValueChanged?.(evt.target.value)} disabled={isDisabled}>
      {countries.map((country: string) => (
        <option key={country} value={country}>
          {formatMessage({id: `COUNTRIES.${country}`})}
        </option>
      ))}
    </InputSelector>
  )
}

import {CustomNodeModel} from '@/components/Canvas/customNodes/CustomNodeModel'
import {Action, ActionEvent, DragCanvasState, InputType, State} from '@projectstorm/react-canvas-core'
import {DiagramEngine} from '@projectstorm/react-diagrams-core'
import {MouseEvent} from 'react'

export class DefaultState extends State<DiagramEngine> {
  dragCanvas: DragCanvasState

  constructor() {
    super({name: 'journey-optimizer-custom-state'})

    this.dragCanvas = new DragCanvasState()

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: event => {
          const element = this.engine.getActionEventBus().getModelForEvent(event as ActionEvent<MouseEvent>)
          if (element) {
            return
          }

          // The canvas was clicked on, transition to the dragging canvas state
          this.transitionWithEvent(this.dragCanvas, event)
        },
      })
    )
    this.registerAction(
      new Action({
        type: InputType.MOUSE_UP,
        fire: event => {
          const element = this.engine.getActionEventBus().getModelForEvent(event as ActionEvent<MouseEvent>)

          this.engine.getModel().clearSelection()

          // Select clicked node
          if (element instanceof CustomNodeModel) {
            element.setSelected(true)
          }
        },
      })
    )
  }
}

import JourneyDrawer from '@/components/Drawer/JourneyDrawer'
import {CreateJourneyCopyButton} from '@/components/JourneyEditor/components/CreateJourneyCopyButton'
import {DeleteJourneyButton} from '@/components/JourneyEditor/components/DeleteJourneyButton'
import {ViewState} from '@/components/JourneyEditor/components/JourneyPersistenceActions'
import {JourneyStatusBadge} from '@/components/JourneyStatusBadge/JourneyStatusBadge'
import {downloadFileHandler} from '@/helpers/downloadFileHandler'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {generateExcelFile} from '@/helpers/generateExcelFile'
import {getFormattedCurrentTimestamp} from '@/helpers/getFormattedCurrentTimestamp'
import {onSaveJourney} from '@/helpers/onSaveJourney'
import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {Spinner} from '@/shared-components/Spinner'
import {GetJourneyStatusResponse} from '@/types/JourneyExecutionStatus'
import {JourneyTypeSchema} from '@/types/JourneyType'
import {
  openJourneyDrawer,
  resetCurrentNode,
  resetHasUnsavedChanges,
  resetJourneyConfig,
  resetNodesData,
  resetNodesRelations,
  setJourneyMeta,
  setJourneyStatus,
  showNotification,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  updateNodeWaitData,
} from '@ReduxActions'
import {getJourneyStatusReportUseCase, getJourneyStatusUseCase, getJourneyUseCase, saveJourneyUseCase} from '@UseCases'
import {useMutation, useQuery} from '@tanstack/react-query'
import _ from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {NavigateFunction, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Canvas} from '../components/Canvas'
import {canvasApp} from '../components/Canvas/Application/Application'
import SelectedNode from '../components/Drawer/SelectedNode'
import {SaveJourneyActions} from '../components/JourneyEditor/SaveJourneyActions'
import {JourneyMetaConfig} from '../components/JourneyEditor/components/JourneyMetaConfig'
import {
  EXECUTION_STATUS_POOLING_INTERVAL,
  JOURNEY_QUERY_KEY,
  JOURNEY_STATUS_EXECUTION_QUERY_KEY,
  ROUTES,
} from '../config/constants'
import {store, useAppDispatch} from '../store'
import {resetJourneyExecutionStatus, setJourneyExecutionStatus} from '../store/slices/journeyExecutionStatusSlice'
import {JourneyStatus} from '../types/JourneyStatus'
import {GetJourneyResponse, GetReportDataResponse, GetReportResponse} from '../types/Journeys'
import {NodeTypeEnum} from '../types/NodeTypeEnum'
import {Step, Steps} from '../types/Steps'
import {PageContent} from './Template/components/PageContent'

const useJourneyEditorHooks = (
  isEditing: boolean,
  journeyData: GetJourneyResponse | undefined,
  error: Error | null,
  navigate: NavigateFunction,
  journeyStatusData: GetJourneyStatusResponse | undefined,
  isFetching: boolean
) => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()

  useEffect(() => {
    if (isEditing) return

    setTimeout(() => {
      dispatch(
        openJourneyDrawer({
          selectedPage: 'journeyConfigEditor',
          title: formatMessage({id: 'JOURNEY_BUILDER.CREATE_JOURNEY_HEADER'}),
          canBeClosed: false,
        })
      )
    }, 100)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetJourney()
    if (!isEditing) {
      canvasApp.initializeEntryCondition()
    }

    if (journeyData) {
      onLoadJourney.Success(journeyData)
    }

    if (error) {
      navigate(ROUTES.LIST_JOURNEYS)
      onLoadJourney.Error()
    }

    return () => {
      resetJourney()
    }
  }, [error, isEditing, journeyData, navigate])

  useEffect(() => {
    if (journeyStatusData) {
      store.dispatch(setJourneyExecutionStatus(journeyStatusData))
    }

    return () => {
      resetJourneyExecutionStatus()
    }
  }, [isFetching, journeyStatusData])
}

export const JourneyEditor = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const journeyConfig = useJourneyMetaConfig()
  const {id: journeyId} = useParams()
  const {formatMessage} = useIntl()

  const isEditing = !!journeyId
  const prevPath = location.state ? location.state.prevPath : ROUTES.LIST_JOURNEYS

  const {
    isFetching: isFetchingJourney,
    data: journeyData,
    error,
  } = useQuery({
    queryKey: [JOURNEY_QUERY_KEY, journeyId],
    queryFn: ({signal}: {signal: AbortSignal}) => getJourneyUseCase({id: journeyId}, signal),
    retry: 1,
    enabled: isEditing,
  })

  const shouldLoadStatus = useMemo(() => {
    const isCountrySet = journeyData?.country === journeyConfig.country
    return !!journeyData?.country && isPublishedOrCompleted(journeyConfig.status) && isCountrySet
  }, [journeyConfig.country, journeyConfig.status, journeyData?.country])

  const {isFetching: isFetchingStatus, data: journeyStatusData} = useQuery({
    queryKey: [JOURNEY_STATUS_EXECUTION_QUERY_KEY, journeyId, journeyConfig.country],
    queryFn: ({signal}: {signal: AbortSignal}) => getJourneyStatusUseCase({id: journeyId}, signal),
    retry: 0,
    enabled: shouldLoadStatus,
    staleTime: 0,
    refetchInterval: EXECUTION_STATUS_POOLING_INTERVAL,
    refetchOnWindowFocus: true,
  })

  const {mutate, isPending: isSaving} = useMutation({
    mutationKey: ['saveJourney'],
    mutationFn: saveJourneyUseCase,
  })

  const {mutate: mutateReport, isPending: isExporting} = useMutation({
    mutationKey: ['getJourneyReport', journeyId],
    mutationFn: getJourneyStatusReportUseCase,
  })

  const onClickCancel = useCallback(() => {
    navigate(prevPath)
  }, [navigate, prevPath])

  const onClickJourneyReport = useCallback(() => {
    if (isExporting) return

    mutateReport(
      {id: journeyId!},
      {
        onSuccess: result => {
          const fileName = `${journeyConfig.name} - Execution Report - ${getFormattedCurrentTimestamp()}.xlsx`
          isFeatureEnabled(FeatureToggle.dataReports)
            ? generateExcelFile((result as GetReportDataResponse).content, fileName)
            : downloadFileHandler((result as GetReportResponse).path, fileName)
        },
        onError: onSaveJourney.Error,
      }
    )
  }, [isExporting, journeyConfig.name, journeyId, mutateReport])

  const onClickSave = useCallback(() => {
    if (isSaving) return

    mutate(
      {
        ...journeyConfig,
        id: journeyId,
      },
      {
        onSuccess: result => {
          onSaveJourney.Success(isEditing, () =>
            navigate(ROUTES.EDIT_JOURNEY.replace(':id', result.id), {
              state: {
                prevPath,
              },
            })
          )
        },
        onError: onSaveJourney.Error,
      }
    )
  }, [isSaving, mutate, journeyId, journeyConfig, isEditing, navigate, prevPath])

  const isFetching = isFetchingJourney || isFetchingStatus
  useJourneyEditorHooks(isEditing, journeyData, error, navigate, journeyStatusData, isFetching)

  const contentHeader = formatMessage({
    id: !isEditing ? 'JOURNEY_BUILDER.CONTENT_HEADER_NEW' : 'JOURNEY_BUILDER.CONTENT_HEADER_SAVED',
  })

  return (
    <PageContent title="JOURNEY_BUILDER.TITLE" data-testid="journey">
      {isFetchingJourney ? (
        <Spinner size="medium" />
      ) : (
        <>
          <div className="flex h-full flex-col" data-testid="journey-builder" data-cy="journey-builder">
            <div className="mb-6 flex flex-col gap-2">
              <p className="text-lg font-bold">{contentHeader}</p>
              <JourneyEditorSubtitleAndButtons journeyId={journeyId} />
            </div>
            <div className="flex h-full max-h-[calc(100%-8rem)] gap-6">
              <div className="w-1/3 min-w-52 max-w-xs items-center justify-between rounded-lg border border-solid border-blue p-4">
                <JourneyMetaConfig />
              </div>
              <div className="relative h-full max-h-screen flex-1 border border-layer-01-hover">
                <Canvas app={canvasApp} />
                <div className=" absolute right-2 top-2">
                  <JourneyStatusBadge status={journeyConfig.status} />
                </div>
              </div>
            </div>

            <SaveJourneyActions
              isLoading={isSaving}
              isExporting={isExporting}
              onClickSave={onClickSave}
              onClickCancel={onClickCancel}
              onClickExport={onClickJourneyReport}
              journeyId={journeyId}
              className="mt-6 flex w-full items-center justify-end gap-3"
            />
          </div>
          <SelectedNode />
          <JourneyDrawer />
        </>
      )}
    </PageContent>
  )
}

const JourneyEditorSubtitleAndButtons: React.FC<{journeyId?: string}> = ({journeyId}) => {
  const {formatMessage} = useIntl()
  const [viewState, setViewState] = useState<ViewState>('IDLE')

  const isDeleteButtonDisabled = viewState !== 'IDLE'

  return (
    <div className="flex items-center ">
      <p className="text-sm font-normal text-text-secondary">
        {formatMessage({id: 'JOURNEY_BUILDER.CONTENT_SUBHEADER'})}
      </p>
      <div data-testid="journey-buttons-wrapper" className="ml-auto flex gap-2">
        <CreateJourneyCopyButton />
        <DeleteJourneyButton
          journeyId={journeyId}
          isDisabled={isDeleteButtonDisabled}
          onIsDeletingHandler={value => setViewState(value ? 'DELETING' : 'IDLE')}
        />
      </div>
    </div>
  )
}

const resetJourney = () => {
  canvasApp.reset()
  store.dispatch(resetNodesData())
  store.dispatch(resetCurrentNode())
  store.dispatch(resetNodesRelations())
  store.dispatch(resetJourneyConfig())
  store.dispatch(resetJourneyExecutionStatus())
  store.dispatch(resetHasUnsavedChanges())
}

// MARK: - onLoadJourney

const onLoadJourney = {
  Success: (result: GetJourneyResponse) => {
    journeyFactory(result)
    canvasApp.zoomToFitSteps()
  },
  Error: () => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'error',
        title: 'Something went wrong!',
        message: 'The journey could not be loaded.',
      })
    )
  },
}

// MARK: - JourneyFactory

const journeyFactory = (result: GetJourneyResponse) => {
  const {status, ...config} = _.omit(result, ['id', 'steps'])
  store.dispatch(
    setJourneyMeta({
      type: JourneyTypeSchema.enum.RECURRENT,
      ...config,
    })
  )

  const {entryCondition, steps} = result
  canvasApp.initializeEntryCondition(entryCondition)
  addSteps(steps, entryCondition.next)

  store.dispatch(setJourneyStatus(isPublishedOrCompleted(status) ? status : JourneyStatus.saved))
}

const addSteps = (steps: Steps, stepId?: string) => {
  if (!stepId || !steps[stepId]) return

  const step = steps[stepId]
  canvasApp.addLinkedStep(step.type, stepId)
  setStepConfig(step)
  addSteps(steps, step.next)
}

const setStepConfig = (step: Step) => {
  switch (step.type) {
    case NodeTypeEnum.wait:
      store.dispatch(
        updateNodeWaitData({
          id: step.id,
          config: step.config,
        })
      )
      break
    case NodeTypeEnum.sendPush:
      store.dispatch(
        updateNodeSendPushNotificationData({
          id: step.id,
          config: step.config,
        })
      )
      break
    case NodeTypeEnum.sendSMS:
      store.dispatch(
        updateNodeSendSMSData({
          id: step.id,
          config: step.config,
        })
      )
      break
    case NodeTypeEnum.sendWhatsApp:
      store.dispatch(
        updateNodeSendWhatsAppData({
          id: step.id,
          config: step.config,
        })
      )
  }
}

import {DrawerConfigTitle} from '@/components/Drawer/DrawerConfigTitle'
import {useEntryConditionsData} from '@/components/Drawer/hooks/useEntryConditionsData'
import {ParamsFilterCard} from '@/components/ParamsFilterCard/ParamsFilterCard'
import {ReentryDaysInput} from '@/components/ParamsFilterCard/ReentryDaysInput'
import {useIntl} from 'react-intl'

export const FilterParams = () => {
  const {formatMessage} = useIntl()
  const {
    paramsSelectors: tempParamsSelectors,
    reentryDays: tempReentryDays,
    setParamsSelectors: setTempParamsSelectors,
    setReentryDays: setTempReentryDays,
  } = useEntryConditionsData()

  return (
    <>
      <DrawerConfigTitle subtitle={formatMessage({id: 'ENTRY_CONDITION_CONTENT.CONFIG_SUBTITLE'})} />

      <div className="flex flex-col gap-4" data-testid="entry-conditions-content">
        <ReentryDaysInput setReentryDays={setTempReentryDays} reentryDays={tempReentryDays} />
        <ParamsFilterCard paramsSelectors={tempParamsSelectors} setParamsSelectors={setTempParamsSelectors} />
      </div>
    </>
  )
}

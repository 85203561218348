import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'
import {ThresholdMessagesConfig} from '@/components/Settings/ThresholdMessagesConfig'
import {formatMessageRichTextOptions} from '@/helpers/formatMessageRichTextOptions'
import {useCountries} from '@/hooks/useCountries'
import {BorderlessButton} from '@/shared-components/Button'
import {JourneyStatus} from '@/types/JourneyStatus'
import {CustomSettings} from '@/types/Settings'
import {ThresholdMessages} from '@/types/Settings/ThresholdMessages'
import {CountryCode} from '@/types/Templates'
import {TrashIcon} from 'lucide-react'
import {Icon} from 'pepsico-ds'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

export type CustomThresholdMessagesSectionProps = {
  values?: CustomSettings[]
  onAddNewSettings?: (availableCountriesToAdd: string[]) => void
  onRemoveSettings?: (countryCode: string) => void
  onChange?: (countryCode: string, value: ThresholdMessages) => void
  onChangeCountry?: (idx: number, countryCode: string) => void
}

export const CustomThresholdMessagesSection = ({
  values = [],
  onAddNewSettings,
  onRemoveSettings,
  onChange,
  onChangeCountry,
}: CustomThresholdMessagesSectionProps) => {
  const {formatMessage} = useIntl()
  const {countries} = useCountries()

  const availableCountriesToAdd = useMemo(() => {
    return countries.filter((item: CountryCode) => !values.find(({countryCode}) => countryCode === item))
  }, [countries, values])

  const canAddMore = availableCountriesToAdd.length > 0

  return (
    <section className="" data-testid="custom-threshold-messages-section">
      <div className="mb-6">
        <h2 className="mb-1 text-lg font-medium text-text-primary">
          {formatMessage({id: 'SETTINGS.CUSTOM_THRESHOLD_HEADING'})}
        </h2>
        <h4 className="mb-4 text-sm leading-5 text-text-secondary">
          {formatMessage({id: 'SETTINGS.CUSTOM_THRESHOLD_SUBHEADING'}, formatMessageRichTextOptions())}
        </h4>
        <BorderlessButton
          disabled={!canAddMore}
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
            evt.preventDefault()
            onAddNewSettings?.(availableCountriesToAdd)
          }}
          data-testid="custom-threshold-add-more"
        >
          <Icon icon="add_circle_outline" size="small" />
          {formatMessage({id: 'SETTINGS.CUSTOM_THRESHOLD_ADD_BUTTON'})}
        </BorderlessButton>
      </div>

      <div className="flex flex-col gap-6">
        {values.map(({countryCode, threshold}, idx) => {
          return (
            <div
              key={countryCode}
              className="ml-1 flex w-full max-w-xs flex-col gap-4 border-l-4 border-layer-01-hover pl-4 transition-all"
              data-testid={`custom-threshold-messages`}
            >
              <div className="flex items-center gap-4">
                <CountrySelector
                  id={`custom-threshold-country`}
                  countries={[countryCode, ...availableCountriesToAdd]}
                  status={JourneyStatus.draft}
                  value={countryCode}
                  onValueChanged={value => onChangeCountry?.(idx, value)}
                  disabled={!canAddMore}
                  className="flex flex-1"
                />
                <BorderlessButton
                  className="text-xs enabled:hover:text-button-red-hover"
                  onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                    evt.preventDefault()
                    onRemoveSettings?.(countryCode)
                  }}
                  data-testid="custom-threshold-remove"
                >
                  <TrashIcon className="h-3 w-3" />
                  {formatMessage({id: 'SETTINGS.CUSTOM_THRESHOLD_REMOVE_BUTTON'})}
                </BorderlessButton>
              </div>

              <ThresholdMessagesConfig values={threshold.messages} onChange={value => onChange?.(countryCode, value)} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */

import {isMoving} from '../components/Canvas/utils/EventUtils'
import {isDebug, isSUT} from './env'

const shouldLog = () => !isSUT() && isDebug()

export const logEvent = {
  log: (msg: string, event?: any, ...rest: any[]) => {
    if (!shouldLog() || isMoving(event)) return
    console.log(`${msg}`, event, ...rest)
  },
  info: (msg: string, event?: any, ...rest: any[]) => {
    if (!shouldLog() || isMoving(event)) return
    console.info(`${msg}`, event, ...rest)
  },
  debug: (msg: string, event?: any, ...rest: any[]) => {
    if (!shouldLog() || isMoving(event)) return
    console.debug(`${msg}`, event, ...rest)
  },
  error: (msg: string, event?: any, ...rest: any[]) => {
    if (!shouldLog() || isMoving(event)) return
    console.error(`${msg}`, event, ...rest)
  },
}

import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {NodeTypeEnum} from '../../types/NodeTypeEnum'

export interface CurrentNodeState {
  id: string
  name?: string
  type?: NodeTypeEnum
  canBeClosed?: boolean
}

const initialState: CurrentNodeState = {
  id: '',
  name: undefined,
  type: undefined,
  canBeClosed: true,
}

const currentNodeSlice = createSlice({
  name: 'currentNode',
  initialState,
  reducers: {
    setCurrentNode: (state, action: PayloadAction<CurrentNodeState>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetCurrentNode: () => initialState,
  },
})

export const {setCurrentNode, resetCurrentNode} = currentNodeSlice.actions

export const currentNodeSliceReducer = currentNodeSlice.reducer

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {JourneyStatus} from '@/types/JourneyStatus'
import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  status: JourneyStatus
  type?: JourneyType
  onJourneyTypeDidChange: (value: JourneyType) => void
}

export const JourneyTypeSelector: React.FC<Props> = ({status, onJourneyTypeDidChange, type}) => {
  const {formatMessage} = useIntl()
  const {type: persistedType} = useJourneyMetaConfig()

  const options = Object.values(JourneyTypeSchema.Values).map(journeyType => {
    return {
      value: journeyType,
      label: formatMessage({id: `JOURNEY_BUILDER.JOURNEY_TYPE_${journeyType}`}),
    }
  })

  const infoText = useMemo(() => {
    if (persistedType === JourneyTypeSchema.enum.RECURRENT) {
      return formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_HINT2'})
    }

    if (type === JourneyTypeSchema.enum.RECURRENT) {
      return formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_HINT'})
    }

    return undefined
  }, [formatMessage, persistedType, type])

  const isDisabled = useMemo(() => {
    return isPublishedOrCompleted(status) || persistedType === JourneyTypeSchema.enum.RECURRENT
  }, [persistedType, status])

  const shouldDisplay = isFeatureEnabled(FeatureToggle.journeyType)

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="h-42 flex w-full max-w-xs flex-col gap-1">
      <InputSelector
        label={formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_LABEL'})}
        value={type}
        onChange={e => onJourneyTypeDidChange(e.target.value as JourneyType)}
        id="journey-type"
        required
        disabled={isDisabled}
        error={!type ? formatMessage({id: 'JOURNEY_BUILDER.REQUIRED_LABEL_ERROR'}) : undefined}
        info={infoText}
      >
        <option hidden key="empty" value="">
          {formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_PLACEHOLDER'})}
        </option>
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </InputSelector>
    </div>
  )
}

import {PeriodNotificationSchema} from '@/types/Period'
import {TimeExecutionWindowNotificationSchema} from '@/types/TimeExecution'
import {z} from 'zod'

export const GetNotificationsRequestSchema = z.object({
  idCountry: z.number().optional(),
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  sortBy: z.string().optional(),
})

const NotificationListItemSchema = z.object({
  id: z.string(),
  name: z.string().max(100),
  stepType: z.string().optional(),
  eventName: z.string().nullish(), // Temp fix (?), some notifications have null event name
  eventStatus: z.string().optional(),
  publishedOn: z.string().nullable().optional(),
})

const NotificationPaginationSchema = z.object({
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
})

const TraitParameterSchema = z.object({
  id_traitparameter: z.number().nullish(),
  id_operator: z.number().nullish(),
  id_traitparametervalue: z.number().nullable(),
  id_operatorvalue: z.number().nullish(), // [Task 13374760] should be id_traitparametervalue
  value: z.union([z.string(), z.number()]).nullable(),
})

const EntryConditionSchema = z.object({
  id_trait: z.number(),
  id_operator: z.number(),
  id_traitvalue: z.number(),
  value: z.string().optional().nullable(),
  is_primary: z.boolean().optional(),
  traitparameters: z.array(TraitParameterSchema).nullish(),
})

const MessageConfigSchema = z.object({
  id_language: z.number(),
  name_language: z.string(),
  subject: z.string().nullish(),
  message: z.string().nullish(),
})

const StepSchema = z.object({
  id_step: z.number(),
  id_steptype: z.number(),
  id_stepstatus: z.number(),
  message_configs: z.array(MessageConfigSchema),
})

export const CreateNotificationRequestSchema = z.object({
  id_country: z.number(),
  id_eventstatus: z.number(),
  name: z.string(),
  description: z.string(),
  active: z.boolean(),
  redirect_page: z.string().url().optional(),
  entry_conditions: z.array(EntryConditionSchema),
  steps: z.array(StepSchema),
})

export const CreateNotificationResponseSchema = z.object({
  success: z.boolean(),
  data: z.unknown(),
})

export type NotificationListItem = z.infer<typeof NotificationListItemSchema>

export const GetNotificationsResponseSchema = z.object({
  content: z.array(NotificationListItemSchema),
  // [Task 13382646] This is a temp solution. Pagination is missing on the Dev API. Need to make it required if API fixes it.
  pagination: NotificationPaginationSchema.optional(),
  success: z.boolean().optional(),
  errorMessage: z.string().nullable().optional(),
})

const notificationTypeSchema = z.object({
  id_step: z.string(),
  id_redirectpage: z.string().nullish(),
  redirect_page: z.string().nullish(),
  image_url: z.string().nullish(),

  stepStatus: z
    .object({
      id_stepstatus: z.number(),
      name: z.string(),
      description: z.string().optional(),
    })
    .optional(),

  stepType: z
    .object({
      id_steptype: z.number().optional(),
      name: z.string().optional(),
      description: z.string().optional(),
    })
    .optional(),

  message_configs: z.array(MessageConfigSchema),
})

const traitSchema = z.object({
  name: z.string(),
  description: z.string().nullable().optional(),
  id_trait: z.number(),
})

const operatorSchema = z.object({
  name: z.string(),
  description: z.string().nullable().optional(),
  id_operator: z.number(),
})

const parameterSchema = z.object({
  id_traitparameter: z.number(),
  trait_name: z.string().nullish(),
  id_operator: z.number(),
  operator_name: z.string().nullish(),
  id_traitparametervalue: z.number().nullish(),
  id_operatorvalue: z.number().nullish(), // [Task 13374760] should be id_traitparametervalue
  value: z.string().nullish(),
})

const EventStatusSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  id_eventstatus: z.number().optional(),
})

export enum EventStatusIds {
  Draft = 1,
  Published = 2,
}

const notificationDetailsSchema = z.object({
  code: z.string().nullish(),
  // [Task 13382646] This field is missing from Get Notification by ID Dev API. Need to make it required if API fixes it.
  country_name: z.string().optional(),
  description: z.string(),
  active: z.boolean().optional(),
  entry_conditions: z.array(
    z.object({
      trait: traitSchema,
      operator: operatorSchema,
      value: z.string().nullish(),
      traitparameters: z.array(parameterSchema).nullish(),
      id_entrycondition: z.string().optional(),
      id_traitvalue: z.number().optional(),
    })
  ),
  eventStatus: EventStatusSchema,
  id: z.string(),
  id_country: z.number(),
  fallsOnBankHoliday: z.string().optional(),
  name: z.string().max(100),
  period: PeriodNotificationSchema.optional(),
  redirect_page: z.string().nullable().optional(),
  steps: z.array(notificationTypeSchema),
  timeExecutionWindow: TimeExecutionWindowNotificationSchema.optional(),
})

export const GetNotificationByIdRequestSchema = z.void()
export const GetNotificationByIdResponseSchema = z.object({
  data: notificationDetailsSchema,
  success: z.boolean().optional(),
  errorMessage: z.string().nullable().optional(),
})

export type NotificationDetailsData = z.infer<typeof notificationDetailsSchema>
export type EventStatus = z.infer<typeof EventStatusSchema>

export type GetNotificationByIdRequest = z.infer<typeof GetNotificationByIdRequestSchema>
export type GetNotificationByIdResponse = z.infer<typeof GetNotificationByIdResponseSchema>
export type GetNotificationsRequest = z.infer<typeof GetNotificationsRequestSchema>
export type GetNotificationsResponse = z.infer<typeof GetNotificationsResponseSchema>
export type CreateNotificationRequest = z.infer<typeof CreateNotificationRequestSchema>
export type CreateNotificationResponse = z.infer<typeof CreateNotificationResponseSchema>
export type TraitParameter = z.infer<typeof TraitParameterSchema>
export type EntryCondition = z.infer<typeof EntryConditionSchema>
export type MessageConfig = z.infer<typeof MessageConfigSchema>
export type Step = z.infer<typeof StepSchema>

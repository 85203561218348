import {useAppSelector} from '@/store'
import {Icon} from 'pepsico-ds'
import React, {CSSProperties, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useOnClickOutside} from 'usehooks-ts'

type Direction = 'left' | 'top' | 'right' | 'bottom'

export const Drawer: React.FC<{
  isOpen: boolean
  children: React.ReactNode
  headerTitle?: string
  onClose?: () => void
  direction?: Direction
  size?: string
}> = ({children, isOpen, headerTitle, onClose, direction = 'bottom', size}) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    if (!isOpen) return
    onClose?.()
  })

  return (
    <div>
      <BgOverlay isOpen={isOpen} />
      <div
        ref={ref}
        className="fixed z-[10000] flex flex-col bg-layer-01 px-4 py-4"
        style={{
          transition: '0.25s ease all, 0.1s ease visibility',
          ...stylesWrapper(isOpen, size)[direction],
        }}
      >
        {isOpen && (
          <>
            <div className="mb-4 flex items-center justify-end px-2">
              <HeaderTitle headerTitle={headerTitle} />

              <button onClick={onClose} data-cy="close-drawer-content-btn" data-testid="drawer-close-button">
                <Icon icon="close" size="medium" className="mr-1 text-icon hover:text-button-icon-active" />
              </button>
            </div>
            {children}
          </>
        )}
      </div>
    </div>
  )
}

const stylesWrapper = (isOpen: boolean, size?: string): Record<Direction, CSSProperties> => {
  const defaultProps: CSSProperties = {
    visibility: isOpen ? 'visible' : 'hidden',
  }

  const horizontal: CSSProperties = {
    width: isOpen ? size ?? '30%' : '0',
    height: '100vh',
  }

  const vertical: CSSProperties = {
    width: '100vw',
    height: isOpen ? size ?? 'calc(100% - 11rem)' : '0',
  }

  return {
    left: {
      ...defaultProps,
      ...horizontal,
      top: 0,
      left: 0,
    },
    right: {
      ...defaultProps,
      ...horizontal,
      top: 0,
      right: 0,
    },
    top: {
      ...defaultProps,
      ...vertical,
      top: 0,
      left: 0,
    },
    bottom: {
      ...defaultProps,
      ...vertical,
      bottom: 0,
      left: 0,
    },
  }
}

const HeaderTitle = ({headerTitle}: {headerTitle?: string}) => {
  const {formatMessage} = useIntl()
  const {selectedStepId} = useAppSelector(state => state.stepsSelector)
  const {title} = useAppSelector(state => state.journeyDrawer)
  const isAddingNewStep = !!selectedStepId

  return !isAddingNewStep && !title ? null : (
    <p className="flex-1 text-lg font-bold text-text-primary">
      {headerTitle ?? formatMessage({id: 'JOURNEY_BUILDER.DRAWER_TITLE'})}
    </p>
  )
}

const BgOverlay = ({isOpen}: {isOpen: boolean}) => {
  return !isOpen ? null : (
    <div className="fixed inset-0 top-0 z-[9999] bg-layer-01-inverted/80" data-testid="drawer" data-cy="drawer" />
  )
}

import {JourneyStatus} from '@/types/JourneyStatus'
import {Tag} from 'pepsico-ds'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

type JourneyStatusBadgeProps = {
  status: JourneyStatus
}

export const JourneyStatusBadge = ({status}: JourneyStatusBadgeProps) => {
  const {formatMessage} = useIntl()
  const label = formatMessage({id: `STATUS.${status}`})

  const normalizedStatus = useMemo(() => {
    switch (status) {
      case JourneyStatus.new:
      case JourneyStatus.draft:
      case JourneyStatus.saved:
        return JourneyStatus.draft
      default:
        return status
    }
  }, [status])

  return (
    <Tag
      data-testid={`journey-status-${normalizedStatus}-badge`}
      data-cy={`journey-status-${normalizedStatus}-badge`}
      color="transparent"
      className={`journey-status-${normalizedStatus}-badge`}
      size="large"
      text={label}
      isCopyable={false}
      isRemovable={false}
    />
  )
}

import {parseTime} from '@/helpers/timeUtils'
import {InputSelector, InputSelectorProps} from '@/shared-components/InputSelector/InputSelector'
import {useMemo} from 'react'

type TimeSelectorProps = InputSelectorProps & {
  label: string
  empty: string
  startTime?: string
  isEndTime?: boolean
}

export const TimeSelector = ({empty, startTime, isEndTime, ...props}: TimeSelectorProps) => {
  const timeOptions = useMemo(() => {
    const valueOptions = []

    const [startHour, startMinute] = (startTime?.split(':') ?? []).map(Number)

    for (let hour = startHour ?? 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const isBeforeStartTime = hour === startHour && minute <= startMinute
        const isAfterMidnight = hour + minute >= 24 + 30
        if (isBeforeStartTime || isAfterMidnight) {
          continue
        }

        const {formatted24h, formattedAmPm} = parseTime(`${hour}:${minute}`)
        valueOptions.push({
          display: formattedAmPm,
          value: formatted24h,
        })
      }
    }

    if (isEndTime) {
      const {formatted24h, formattedAmPm} = parseTime('23:59')
      valueOptions.push({
        display: formattedAmPm,
        value: formatted24h,
      })
    }

    return valueOptions
  }, [isEndTime, startTime])

  const isDisabled = !timeOptions.length || props.disabled

  return (
    <InputSelector {...props} disabled={isDisabled}>
      <option key="empty" value="">
        {empty}
      </option>

      {timeOptions.map(({display, value}, index) => (
        <option key={index} value={value}>
          {display}
        </option>
      ))}
    </InputSelector>
  )
}

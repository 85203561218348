import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {JourneyError} from '@/types/JourneyError'
import {GetReportDataResponse, GetReportResponse} from '@/types/Journeys'
import {getJourneyStatusReportDataService, getJourneyStatusReportService} from '../services/journeysService'

type GetJourneyStatusReportUseCaseParams = {
  id: string
  startDate?: string
  endDate?: string
}

type ReportsResult = GetReportResponse | GetReportDataResponse

export async function getJourneyStatusReportUseCase(
  params: GetJourneyStatusReportUseCaseParams,
  signal?: AbortSignal
): Promise<ReportsResult> {
  return isFeatureEnabled(FeatureToggle.dataReports) ? getReportData(params, signal) : getReport(params, signal)
}

const getReport = async (
  {id, ...params}: GetJourneyStatusReportUseCaseParams,
  signal?: AbortSignal
): Promise<GetReportResponse> => {
  const result = (await getJourneyStatusReportService(id, params, signal)) as GetReportResponse

  if (!result?.path?.length) {
    throw new JourneyError('No report available', 'Export error')
  }

  return result
}

const getReportData = async (
  {id, ...params}: GetJourneyStatusReportUseCaseParams,
  signal?: AbortSignal
): Promise<GetReportDataResponse> => {
  const result = (await getJourneyStatusReportDataService(id, params, signal)) as GetReportDataResponse

  if (!result?.content?.length) {
    throw new JourneyError('No report available', 'Export error')
  }

  return result
}

import {cn} from '@/helpers/shadcn/utils'
import {EmojiPickerButton} from '@/shared-components/EmojiPickerButton'
import {useEmojiPickerButtonEvents} from '@/shared-components/EmojiPickerButton/useEmojiPickerButtonEvents.hook'
import {FieldLabel} from '@/shared-components/FieldLabel'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {ChangeEventHandler, ComponentPropsWithoutRef, useState} from 'react'

type InputTextProps = ComponentPropsWithoutRef<'input'> & {
  label?: string
  required?: boolean
  info?: string
  error?: string
  type?: 'text' | 'number'
  showEmojiPicker?: boolean
}

export const InputText = ({
  id,
  label,
  error,
  info,
  required,
  className,
  showEmojiPicker,
  value = '',
  ...rest
}: InputTextProps) => {
  const [caretPosition, setCaretPosition] = useState(Math.max(String(value).length, 0))
  const [isInputFocused, setIsInputFocused] = useState(false)
  const {handleEmojiPickEvent} = useEmojiPickerButtonEvents(
    value as string,
    rest.onChange as ChangeEventHandler,
    caretPosition,
    setCaretPosition
  )

  const onKeyDownDidPressed = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (rest.type === 'number' && evt.key.toLowerCase() === 'e') {
      evt.preventDefault()
    }
  }

  const onDidBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.target.value = evt.target.value?.trim()
    rest.onChange?.(evt)
  }

  const hasErrorOrInfo = Boolean(error || info)

  return (
    <div className="relative flex flex-col gap-2 break-words text-xs">
      <FieldLabel id={id} label={label} required={required} />

      <div
        data-error={error}
        className={cn(
          'flex rounded border border-border data-[error]:border-support-error-primary',
          isInputFocused ? 'border-link' : ''
        )}
      >
        <input
          className={cn(
            'focus:shadow-none shadow-none h-8 w-full rounded  bg-layer-01 px-2 text-sm leading-4 text-text-primary outline-none focus:border-link focus:text-link focus:outline-0 disabled:rounded-e-none disabled:bg-button-disabled-background disabled:text-text-disabled  data-[error]:text-support-error-primary',
            className
          )}
          value={value}
          data-testid={`${id}-input`}
          data-cy={`${id}-input`}
          onKeyDown={onKeyDownDidPressed}
          onClick={event => {
            setCaretPosition(Number(event.currentTarget.selectionStart))
            rest.onClick?.(event)
          }}
          onBlur={event => {
            setCaretPosition(Number(event.currentTarget.selectionStart))
            onDidBlur(event)
            setIsInputFocused(false)
            rest.onBlur?.(event)
          }}
          onFocus={event => {
            setCaretPosition(Number(event.currentTarget.selectionStart))
            setIsInputFocused(true)
            rest.onFocus?.(event)
          }}
          {...rest}
        />

        {showEmojiPicker && (
          <div
            className={cn(
              'h-8 w-8 rounded bg-layer-01',
              rest.disabled ? 'w-0 rounded-none bg-button-disabled-background' : ''
            )}
          ></div>
        )}
      </div>

      <InfoOrError info={info} error={error} id={id} />
      {showEmojiPicker && !rest.disabled && (
        <EmojiPickerButton
          className={cn('left-auto right-1', hasErrorOrInfo ? 'bottom-6' : 'bottom-0')}
          onPickEmoji={handleEmojiPickEvent}
        />
      )}
    </div>
  )
}

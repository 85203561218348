import {PortModelAlignment} from '@projectstorm/react-diagrams'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomPortModel} from '../CustomPortModel'

export class EntryConditionPortModel extends CustomPortModel {
  constructor(alignment: PortModelAlignment) {
    super({
      type: NodeTypeEnum.entryCondition,
      name: alignment,
      alignment: alignment,
    })
  }
}

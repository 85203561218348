import styled from '@emotion/styled'
import {CanvasWidget} from '@projectstorm/react-canvas-core'
import {Application} from '../Application/Application'

export interface BodyWidgetProps {
  app: Application
}

export const BodyWidget = ({app}: BodyWidgetProps) => {
  const clearInvalidLinks = () => {
    const diagramModel = app.getActiveDiagram()
    const engine = app.getDiagramEngine()

    if (!diagramModel || !engine) return

    const links = diagramModel.getLinks()
    links.forEach(link => {
      const sourcePortId = link.getSourcePort()?.getID()
      const targetPortId = link.getTargetPort()?.getID()

      const hasSourceTargetPortIds = !!sourcePortId && !!targetPortId
      const hasSourceTargetDifferentPorts = sourcePortId !== targetPortId

      const isValidLink = hasSourceTargetPortIds && hasSourceTargetDifferentPorts
      if (isValidLink) {
        return
      }

      diagramModel.removeLink(link)
    })

    engine.repaintCanvas()
  }

  return (
    <div
      data-testid="canvas-container-wrapper"
      data-cy="canvas-container-wrapper"
      className="relative flex h-full flex-grow flex-col"
      onMouseUp={clearInvalidLinks}
      onMouseLeave={clearInvalidLinks}
    >
      <S.Container color="#DFEAF9">
        <CanvasWidget engine={app.getDiagramEngine()} />
      </S.Container>
    </div>
  )
}

const S = {
  Container: styled.div<{color: string}>`
    height: 100%;
    background-color: #fbfcfe;
    background-size: 25px 25px;
    display: flex;
    > * {
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
    background-image: linear-gradient(
        0deg,
        transparent 24%,
        ${p => p.color} 25%,
        ${p => p.color} 26%,
        transparent 27%,
        transparent 74%,
        ${p => p.color} 75%,
        ${p => p.color} 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        90deg,
        transparent 24%,
        ${p => p.color} 25%,
        ${p => p.color} 26%,
        transparent 27%,
        transparent 74%,
        ${p => p.color} 75%,
        ${p => p.color} 76%,
        transparent 77%,
        transparent
      );
  `,
}

import {BaseEvent, BaseEventProxy, PortModel, PortModelOptions} from '@projectstorm/react-diagrams'
import {CustomLinkModel} from '../customLink/CustomLinkModel'
import {onAddLinkEvent} from '../utils/NodeEventUtils'

export class CustomPortModel extends PortModel {
  constructor(config: PortModelOptions) {
    super(config)
    this.setLocked(true)
  }

  createLinkModel(): CustomLinkModel {
    return new CustomLinkModel()
  }

  addLink(link: CustomLinkModel): void {
    super.addLink(link)
    link.clearListeners()
    link.registerListener({
      eventDidFire(event: BaseEvent & {entity?: CustomLinkModel}) {
        if (!event.entity) return

        if ((event as BaseEventProxy).function === 'sourcePortChanged') return

        onAddLinkEvent(event.entity)
      },
    })
  }
}

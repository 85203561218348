import {getJourneyStatusService} from '../services/journeysService'
import {GetJourneyStatusResponse} from '../types/JourneyExecutionStatus'

export type GetJourneyStatusUseCaseParams = {
  id?: string
}

export async function getJourneyStatusUseCase(
  {id}: GetJourneyStatusUseCaseParams,
  signal?: AbortSignal
): Promise<GetJourneyStatusResponse | undefined> {
  if (!id) return

  return getJourneyStatusService(id, signal)
}

import {GetTemplatesResponse, TemplateType} from '@/types/Templates'
import {getTemplatesService} from '../services/journeysService'

export type GetTemplatesUseCaseParams = {
  type?: TemplateType
}

export async function getTemplatesUseCase(
  params?: GetTemplatesUseCaseParams,
  signal?: AbortSignal
): Promise<GetTemplatesResponse> {
  return getTemplatesService(params ?? {}, signal)
}

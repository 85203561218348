import {GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {EntryConditionNodeModel} from './EntryConditionNodeModel'
import {EntryConditionNodeWidget} from './EntryConditionNodeWidget'

export class EntryConditionNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.entryCondition)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <EntryConditionNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new EntryConditionNodeModel()
  }
}

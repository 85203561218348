import {cn} from '@/helpers/shadcn/utils'
import {AlertNotification} from '@/types/AlertNotification'
import {cva} from 'class-variance-authority'
import {Icon} from 'pepsico-ds'
import {useEffect} from 'react'

type AlertProps = {
  show: boolean
  type: AlertNotification
  message: string
  onCloseClick?: () => void
}

export const Alert = ({type, message, show = false, onCloseClick}: AlertProps) => {
  useEffect(() => {
    const itv = setTimeout(() => onCloseClick?.(), 5000)
    return () => clearTimeout(itv)
  }, [message, onCloseClick, show, type])

  const alertVariants = cva(
    `absolute right-4 top-6 z-40 flex w-full rounded-md px-4 py-2 text-text-secondary max-w-[calc(100vw-7.5rem)]`,
    {
      variants: {
        type: {
          success: 'bg-support-success-primary',
          error: 'bg-support-error-primary',
          info: 'bg-support-info-primary',
          warning: 'bg-support-warning-primary',
        },
      },
    }
  )

  if (!show) {
    return null
  }

  return (
    <div data-testid={`alert-${type}`} className={cn(alertVariants({type}))} role="alert">
      <div className="inline-flex h-6 w-6 items-center justify-center rounded-lg">
        {type === 'success' && <Icon icon="check_circle_outline" size="medium" className="text-button-icon" />}
        {type === 'error' && <Icon icon="error" size="medium" className="text-button-icon" />}
        {type === 'info' && <Icon icon="check_circle_outline" size="medium" className="text-button-icon" />}
        {type === 'warning' && <Icon icon="warning_amber" size="medium" className="text-text-primary" />}
      </div>
      <div className="text-md ml-3 flex w-full items-center">
        {message && <div className={`text-sm ${type === 'warning' ? 'text-black' : 'text-white'}`}>{message}</div>}
      </div>
      <button
        type="button"
        className="mt-1 inline-flex h-4 w-4 items-center justify-center text-icon"
        data-dismiss-target={`#alert-${type}`}
        data-testid="alert-close-btn"
        aria-label="Close"
        onClick={() => onCloseClick?.()}
      >
        <span className="sr-only">Close</span>
        <Icon icon="close" size="small" className={type === 'warning' ? 'text-text-primary' : 'text-button-icon'} />
      </button>
    </div>
  )
}

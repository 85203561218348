import {CustomPortModel} from '@/components/Canvas/customNodes/CustomPortModel'
import {useAppSelector} from '@/store'
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import classnames from 'classnames'
import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {RequiresInterventionNodeModel} from './RequiresInterventionNodeModel'

export interface RequiresInterventionNodeWidgetProps extends CustomNodeWidgetProps {
  node: RequiresInterventionNodeModel
}

export const RequiresInterventionNodeWidget = ({engine, node, ...props}: RequiresInterventionNodeWidgetProps) => {
  const {formatMessage} = useIntl()
  const isSelected = node.getOptions().selected
  const nodeId = node.getID()
  const {haveWarnings} = useAppSelector(state => state.nodesData[nodeId])

  const portTop = node.getPort(PortModelAlignment.TOP) as CustomPortModel

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      drawerDisabled
      classNameWidgetWrapper={classnames('node-widget type-requires-intervention group', [
        {
          selected: isSelected,
          warning: haveWarnings,
        },
      ])}
    >
      <div className={`flex items-center justify-center ${haveWarnings ? 'gap-6' : 'gap-3'}`}>
        <div className="node-content flex flex-col">
          <div className="flex items-start gap-3">
            <div className="node-icon">
              <Icon icon="pan_tool" size="small" className="text-icon" />
            </div>
            <div className="flex flex-col gap-1 pt-0.5">
              <p className="node-name">{formatMessage({id: 'JOURNEY_BUILDER.REQUIRES_INTERVENTION_WIDGET_LABEL'})}</p>
            </div>
          </div>
        </div>
      </div>
    </CustomNodeWidget>
  )
}

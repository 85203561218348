import {getJourneyService} from '../services/journeysService'
import {GetJourneyResponse} from '../types/Journeys'

export type GetJourneyUseCaseParams = {
  id?: string
}

export async function getJourneyUseCase(
  {id}: GetJourneyUseCaseParams,
  signal?: AbortSignal
): Promise<GetJourneyResponse | undefined> {
  if (!id) return

  return getJourneyService(id, signal)
}

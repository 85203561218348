import {AutoComplete} from '@/components/SendNotification/AutoComplete'
import {CustomImageSelector} from '@/components/SendNotification/CustomImageSelector'
import {LandingPageSelector} from '@/components/SendNotification/LandingPageSelector'
import {GetSendPushStepConfigResponse} from '@/types/StepConfigParams'
import {getAllDynamicTagsRtnUseCase} from '@/useCases/getAllTagsUseCase'
import {MAX_MESSAGE_LENGTH} from '@/utils/consts'
import {useQuery} from '@tanstack/react-query'
import {useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  getData: () => GetSendPushStepConfigResponse
  setSubject: (value: string) => void
  setMessage: (value: string) => void
  subject: string
  message: string
}

export default function SendPushParams({getData, setMessage, setSubject, subject, message}: Props) {
  const {formatMessage} = useIntl()
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null)
  const [selectedPage, setSelectedPage] = useState<string | null>(null)
  const landingPageOptions = getData()?.landingPages ?? []
  const imageOptions = getData()?.images ?? []

  const handleImageChange = (imageId: string) => {
    setSelectedImageId(imageId)
  }

  const handlePageChange = (pageId: string) => {
    setSelectedPage(pageId)
  }

  const {
    isFetching: isFetchingTags,
    data: dataTags,
    error: errorTags,
  } = useQuery({
    queryKey: ['dynamicTagsRtn', ['dynamicTagsRtn']],
    queryFn: ({signal}) => getAllDynamicTagsRtnUseCase({}, signal),
  })

  const dynamicTagSuggestions = (dataTags?.data.messageParameters ?? [])
    .filter(item => item.active)
    .map(item => item.name)

  return (
    <div className="h-42 flex w-full max-w-xs flex-col gap-3" data-testid="send-push-params">
      {isFetchingTags && <div>Tags is Loading...</div>}
      {!isFetchingTags && !errorTags && (
        <>
          <AutoComplete
            label={formatMessage({id: 'NOTIFICATION_BUILDER.SUBJECT_LABEL'})}
            inputId="notification-subject"
            inputValue={subject}
            onChangeInput={setSubject}
            max_message_length={MAX_MESSAGE_LENGTH}
            dynamicTagSuggestions={dynamicTagSuggestions}
            tagOffset={-100}
            required
          />
          <AutoComplete
            label={formatMessage({id: 'NOTIFICATION_BUILDER.MESSAGE_LABEL'})}
            inputId="notification-message"
            inputValue={message}
            onChangeInput={setMessage}
            max_message_length={MAX_MESSAGE_LENGTH}
            dynamicTagSuggestions={dynamicTagSuggestions}
            tagOffset={-100}
            required
          />
        </>
      )}

      <LandingPageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_LANDING_PAGE'})}
        options={landingPageOptions}
        value={selectedPage || landingPageOptions[0]?.id}
        onChange={handlePageChange}
        disabled={false}
      />
      <CustomImageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_PUSH_IMAGE'})}
        options={imageOptions}
        value={selectedImageId || imageOptions[0]?.id}
        onChange={handleImageChange}
        disabled={false}
      />
    </div>
  )
}

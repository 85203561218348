import classNames from 'classnames'
import {Icon} from 'pepsico-ds'
import {HTMLAttributes} from 'react'
import {FormattedMessage} from 'react-intl'

type UnsavedChangesWarningProps = HTMLAttributes<HTMLParagraphElement> & {
  visible?: boolean
}

export const UnsavedChangesWarning = ({visible = false, ...props}: UnsavedChangesWarningProps) => {
  if (!visible) return null

  return (
    <p
      {...props}
      className={classNames(
        'mr-4 flex items-center gap-1 text-sm text-support-error-primary motion-safe:animate-pulse',
        props.className
      )}
      data-testid="warning-unsaved-changes"
      data-cy="warning-unsaved-changes"
    >
      <FormattedMessage
        {...{
          id: `UNSAVED_CHANGES.WARNING_LABEL`,
          values: {
            icon: <Icon icon="error_outline" size="small" />,
          },
        }}
      ></FormattedMessage>
    </p>
  )
}

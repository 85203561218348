import {ENDPOINTS} from '../config/constants'
import api, {HTTPMethod} from '../helpers/api'
import {
  GetSettingsRequest,
  GetSettingsRequestSchema,
  GetSettingsResponse,
  GetSettingsResponseSchema,
  SaveSettingsRequest,
  SaveSettingsRequestSchema,
  SaveSettingsResponse,
  SaveSettingsResponseSchema,
} from '../types/Settings/'

export const getSettingsService = (signal?: AbortSignal) => {
  return api<GetSettingsRequest, GetSettingsResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.SETTINGS,
    requestSchema: GetSettingsRequestSchema,
    responseSchema: GetSettingsResponseSchema,
    signal,
  })()
}

export const saveSettingsService = (payload: SaveSettingsRequest) =>
  api<SaveSettingsRequest, SaveSettingsResponse>({
    method: HTTPMethod.PUT,
    path: ENDPOINTS.SETTINGS,
    requestSchema: SaveSettingsRequestSchema,
    responseSchema: SaveSettingsResponseSchema,
  })(payload)

import {useSaveNodeData} from '@/helpers/SaveNodeDataProvider/useNodeDataContext.hook'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useAppDispatch, useAppSelector} from '@/store'
import {checkNotificationsWarnings} from '@/store/middlewares/nodesDataWarningsListener'
import {NodeTypeForSendNotificationEnum} from '@/types/NodeDataForSendNotification'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {
  StepSendPushNotification,
  StepSendPushNotificationConfig,
  StepSendSMS,
  StepSendSMSConfig,
  StepSendWhatsApp,
  StepSendWhatsAppConfig,
} from '@/types/Steps'
import {
  resetCurrentNode,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  updateNodeWarnings,
} from '@ReduxActions'
import _ from 'lodash'
import {useCallback, useEffect, useState} from 'react'

export const useSendNotificationData = (type: NodeTypeForSendNotificationEnum) => {
  const {id: currentNodeId} = useAppSelector(state => state.currentNode)
  const configRedux = useAppSelector(state => {
    const node = state.nodesData[currentNodeId] as StepSendPushNotification | StepSendSMS | StepSendWhatsApp
    return node?.config
  })
  const {setHasUnsavedChanges, setSaveData} = useSaveNodeData()
  const [config, setConfig] = useState(configRedux)
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const dispatch = useAppDispatch()

  useEffect(() => {
    setConfig(configRedux)
  }, [configRedux, currentNodeId])

  const updateSendNotificationNode = useCallback(() => {
    switch (type) {
      case NodeTypeEnum.sendPush:
        dispatch(
          updateNodeSendPushNotificationData({
            id: currentNodeId,
            config: config as StepSendPushNotificationConfig,
          })
        )
        break
      case NodeTypeEnum.sendSMS:
        dispatch(
          updateNodeSendSMSData({
            id: currentNodeId,
            config: config as StepSendSMSConfig,
          })
        )
        break
      case NodeTypeEnum.sendWhatsApp:
        dispatch(
          updateNodeSendWhatsAppData({
            id: currentNodeId,
            config: config as StepSendWhatsAppConfig,
          })
        )
        break
    }
  }, [type, dispatch, currentNodeId, config])

  const saveAllTempData = useCallback(
    (hasUnsavedChanges: boolean) => {
      if (isPublishedOrCompleted) return
      if (!hasUnsavedChanges) return

      updateSendNotificationNode()
      dispatch(resetCurrentNode())
    },
    [isPublishedOrCompleted, dispatch, updateSendNotificationNode]
  )

  const checkAndUpdateNotificationsWarnings = useCallback(
    (configParam: typeof configRedux) => {
      const haveWarnings = checkNotificationsWarnings(configParam, type)
      dispatch(updateNodeWarnings({id: currentNodeId, haveWarnings}))
    },
    [currentNodeId, type, dispatch]
  )

  useEffect(() => {
    const hasUnsavedChanges = !_.isEqual(
      {
        config,
      },
      {
        config: configRedux,
      }
    )
    setHasUnsavedChanges(hasUnsavedChanges)

    checkAndUpdateNotificationsWarnings(config)

    setSaveData(() => () => saveAllTempData(hasUnsavedChanges))
  }, [config, configRedux, setHasUnsavedChanges, setSaveData, saveAllTempData, checkAndUpdateNotificationsWarnings])

  const onChangeTemplateAndMessage = useCallback(
    (template: string, message: string) => {
      if (isPublishedOrCompleted) return
      setConfig({...config, template, message})
    },
    [isPublishedOrCompleted, config]
  )

  const onChangeMessage = useCallback(
    (message: string) => {
      setConfig({...config, message})
    },
    [config]
  )

  const onChangeRecipient = useCallback(
    (to: string) => {
      if (isPublishedOrCompleted) return
      setConfig({...config, to})
    },
    [config, isPublishedOrCompleted]
  )

  const onChangeSubject = useCallback(
    (subject: string) => {
      if (isPublishedOrCompleted) return
      setConfig({...config, subject})
    },
    [config, isPublishedOrCompleted]
  )

  const onChangeCustomImage = useCallback(
    (imageId: string) => {
      if (isPublishedOrCompleted) return
      setConfig({...config, imageId})
    },
    [config, isPublishedOrCompleted]
  )

  const onChangeLandingPage = useCallback(
    (landingPageId: string) => {
      if (isPublishedOrCompleted) return
      setConfig({...config, landingPageId})
    },
    [config, isPublishedOrCompleted]
  )

  return {
    config,
    onChangeTemplateAndMessage,
    onChangeMessage,
    onChangeRecipient,
    onChangeSubject,
    onChangeCustomImage,
    onChangeLandingPage,
  }
}

import {cn} from '@/helpers/shadcn/utils'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {Icon} from 'pepsico-ds'
import {useRef, useState} from 'react'
import {useOnClickOutside} from 'usehooks-ts'

interface Props {
  className?: string
  onPickEmoji: (emoji: string) => void
  emojiPickerLeftPosition?: number
}

export const EmojiPickerButton: React.FC<Props> = ({className, onPickEmoji, emojiPickerLeftPosition = 0}) => {
  const emojiButtonRef = useRef<HTMLButtonElement>(null)
  const emojiPickerWrapperRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const position = emojiButtonRef?.current?.getBoundingClientRect()

  useOnClickOutside(emojiPickerWrapperRef, () => {
    if (!open) return
    setOpen(false)
  })

  return (
    <div ref={emojiPickerWrapperRef} className={cn('z-1 absolute bottom-6 left-2', className)}>
      <button
        ref={emojiButtonRef}
        onClick={() => setOpen(prevOpen => (prevOpen ? false : !prevOpen))}
        data-testid="emoji-picker-button"
        data-cy="emoji-picker-button"
        className="cursor-pointer rounded-full bg-blue-100 fill-link hover:bg-layer-02 hover:fill-link-hover"
      >
        <Icon icon="emoji_emotions" size="medium" className={open ? 'text-link' : 'text-link-disabled'} />
      </button>

      {open && (
        <div
          className="fixed z-50"
          data-testid="emoji-picker-wrapper"
          data-cy="emoji-picker-wrapper"
          style={{
            top: position ? position?.top - 180 : 0,
            left: position ? position.left + 40 + emojiPickerLeftPosition : 0,
          }}
        >
          <Picker
            data={data}
            previewPosition="none"
            onEmojiSelect={(e: {native: string}) => {
              onPickEmoji(e.native)
            }}
          />
        </div>
      )}
    </div>
  )
}

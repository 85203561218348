import {ErrorContent} from '@/pages/Errors/components/ErrorContent'
import {useOktaAuth} from '@okta/okta-react'
import {useState} from 'react'

interface PageError403Props {
  error?: Error
}

export const PageError403 = ({error}: PageError403Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const {oktaAuth} = useOktaAuth()

  const onClickReturn = () => {
    setIsLoading(true)
    oktaAuth.signOut()
  }

  return (
    <>
      <p className="mt-4 w-full text-center">{error?.message}</p>
      <ErrorContent errorType="FORBIDDEN" isLoading={isLoading} onClick={onClickReturn} />
    </>
  )
}

import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

const usePageTitle = (title: string) => {
  const {formatMessage} = useIntl()
  const location = useLocation()

  useEffect(() => {
    const appName = formatMessage({id: 'GENERAL.APP_NAME'})
    document.title = title ? `${appName} - ${title}` : appName
  }, [formatMessage, location, title])
}

export default usePageTitle

import {Pagination} from 'pepsico-ds'

interface DataTablePaginationProps {
  currentPage: number
  totalPages: number
  setPageIndex: (pageNumber: number) => void
}

export function DataTablePagination({currentPage, totalPages, setPageIndex}: DataTablePaginationProps) {
  const onPageNumberDidClick = (pageNumber: number) => {
    setPageIndex(pageNumber - 1)
  }

  return (
    <Pagination
      currentPage={currentPage}
      pageCount={totalPages}
      onUpdate={onPageNumberDidClick}
      className="flex w-full items-center justify-center"
      data-testid="datatable-pagination"
      data-cy="datatable-pagination"
    />
  )
}

import {useIntl} from 'react-intl'

export const RequiresInterventionParams = () => {
  const {formatMessage} = useIntl()

  return (
    <p className="max-w-[678px] text-sm" data-testid="requires-intervention-message">
      {formatMessage({id: 'JOURNEY_BUILDER.REQUIRES_INTERVENTION_MESSAGE'})}
    </p>
  )
}

import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {isStatusAllowedToSave} from '@/helpers/statusHandler'
import {Days} from '@/types/Day'
import {JourneyType} from '@/types/JourneyType'
import {Period} from '@/types/Period'
import {Recurrence, RecurrenceSchema} from '@/types/Recurrence'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {setJourneyStatus} from '@ReduxActions'
import {cleanupOrphanSteps, getNext} from '../components/Canvas/utils/NodeEventUtils'
import {saveJourneyService, updateJourneyService} from '../services/journeysService'
import {store} from '../store'
import {JourneyError} from '../types/JourneyError'
import {JourneyStatus} from '../types/JourneyStatus'
import {SaveJourneyRequest, SaveJourneyResponse} from '../types/Journeys'
import {NodeTypeEnum} from '../types/NodeTypeEnum'
import {EntryConditionConfig, Step} from '../types/Steps'
import {OperatorTypeSchema, OperatorsType, ParamFilters} from '../types/paramFilters/paramFilters'

export type SaveJourneyUseCaseParams = {
  id?: string
  name: string
  description: string
  country: string
  type?: JourneyType
  period?: Period
  isCopy?: boolean
  recurrenceDays?: Array<Days>
  recurrence?: Recurrence
  timeExecutionWindows?: TimeExecutionWindows
}

export async function saveJourneyUseCase(params: SaveJourneyUseCaseParams): Promise<SaveJourneyResponse> {
  const {id, reentryDays, paramsSelectors} = getEntryConditionData()
  const entryConditionNextId = getNext(id) ?? ''

  const data = paramsDataAdapter(params)

  cleanupOrphanSteps()

  try {
    validateJourneyName(data.name)
    validateJourneyType(data.type)
    validateEntryConditionHasNextStep(entryConditionNextId)

    if (!params.isCopy) {
      validateCanSaveJourney()
    }
  } catch (error) {
    throw new JourneyError((error as Error).message, 'Journey can not be saved')
  }

  const steps = getStepsData()

  const config: EntryConditionConfig = []
  paramsSelectors.forEach(paramSelector => {
    const {selectedParam, selectedParamData, selectedParamType} = paramSelector

    if (!selectedParam) {
      return
    }

    config.push({
      field: selectedParam,
      operator: selectedParamData.operator,
      value: prepareValue(selectedParamType, selectedParamData.value),
    })
  })

  const payload: SaveJourneyRequest = {
    ...data,
    entryCondition: {
      id,
      type: NodeTypeEnum.entryCondition,
      reentryDays,
      next: entryConditionNextId,
      config,
    },
    steps,
  }

  const {id: journeyId} = payload
  const isEditting = !!journeyId

  return (isEditting ? updateJourneyService(journeyId, payload) : saveJourneyService(payload)).then(result => {
    store.dispatch(setJourneyStatus(JourneyStatus.saved))
    return result
  })
}

const paramsDataAdapter = (params: SaveJourneyUseCaseParams) => {
  return {
    ...params,
    name: params.name.trim(),
    description: params.description.trim(),
    recurrenceDays: params.recurrence === RecurrenceSchema.Values.WEEKLY ? params.recurrenceDays : undefined,
  }
}

const prepareValue = (type: OperatorsType, value: string | number | null) => {
  if (type === OperatorTypeSchema.Values.integer && value !== null) {
    return Number(value)
  }
  return value
}

export const canSaveJourney = () => {
  const {status} = store.getState().journeyConfig
  const {hasUnsavedChanges} = store.getState()

  return isStatusAllowedToSave(status) && hasUnsavedChanges
}

const getEntryConditionData = () => {
  const {nodesData} = store.getState()

  return Object.values(nodesData).find(step => step.type === NodeTypeEnum.entryCondition) as ParamFilters
}

const getStepsData = () => {
  const {nodesData} = store.getState()

  const nonEntryConditionSteps = Object.values(nodesData).filter(step => step.type !== NodeTypeEnum.entryCondition)
  return Object.fromEntries(
    nonEntryConditionSteps.map((step: Step) => [
      step.id,
      {
        ...step,
        next: getNext(step.id),
      },
    ])
  )
}

// MARK: - Validations

const validateJourneyName = (value?: string) => {
  if (!value?.length) {
    throw new Error('Please define the journey name')
  }
}

const validateJourneyType = (value?: string) => {
  if (!isFeatureEnabled(FeatureToggle.journeyType)) {
    return
  }

  if (!value?.length) {
    throw new Error('Please define the journey type')
  }
}

const validateEntryConditionHasNextStep = (nextStepId?: string) => {
  if (!nextStepId) {
    throw new Error('Please link the Entry Condition to at least one step')
  }
}

const validateCanSaveJourney = () => {
  if (!canSaveJourney()) {
    throw new Error("The Journey can't be saved in the current status")
  }
}

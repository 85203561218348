import {AuthContext} from '@/helpers/Auth/AuthProvider'
import {Alert} from '@/shared-components/Alert'
import {resetNotifications} from '@ReduxActions'
import {PropsWithChildren, useCallback, useContext} from 'react'
import logo from '../../assets/img/logo-pepsico-h.svg'
import {Header} from '../../shared-components/Header'
import {SidebarMenu} from '../../shared-components/SidebarMenu'
import {store, useAppSelector} from '../../store'
import {HeaderExtraItems} from './components/HeaderExtraItems'

export const Template = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useContext(AuthContext)
  return isAuthenticated ? <Logged>{children}</Logged> : <NotLogged>{children}</NotLogged>
}

const Logged = (props: PropsWithChildren) => {
  const {show, type, message} = useAppSelector(state => state.notifications)

  const onCloseAlert = useCallback(() => {
    store.dispatch(resetNotifications())
  }, [])

  return (
    <>
      <Header logo={logo} title="Journey Optimizer">
        <HeaderExtraItems />
      </Header>
      <div data-testid="content" className="relative flex h-full max-h-[calc(100vh-4rem)]">
        <SidebarMenu />
        <main
          data-testid="content-main"
          data-cy="content-main"
          className="ml-20 w-full overflow-auto bg-background pl-7 pr-6 pt-8 text-zinc-800"
          {...props}
        />
        <Alert data-cy="feedback-alert" type={type} message={message} show={show} onCloseClick={onCloseAlert} />
      </div>
    </>
  )
}

const NotLogged = (props: PropsWithChildren) => {
  return (
    <div data-testid="content" className="relative flex h-full">
      <main data-testid="content-main-guest" className="h-full w-full bg-background px-4 text-zinc-800" {...props} />
    </div>
  )
}

export enum NodeTypeEnum {
  entryCondition = 'ENTRY_CONDITION',
  variant = 'VARIANT',
  wait = 'WAIT',
  sendPush = 'SEND_PUSH',
  sendSMS = 'SEND_SMS',
  sendWhatsApp = 'SEND_WHATSAPP',
  requiresIntervention = 'REQUIRES_INTERVENTION',
}

export const isNodeType = (type?: string): type is NodeTypeEnum => {
  return !!type && Object.values(NodeTypeEnum).includes(type as NodeTypeEnum)
}

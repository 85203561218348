import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {cn} from '@/helpers/shadcn/utils'
import {FieldLabel} from '@/shared-components/FieldLabel'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {Label} from '@/shared-components/Label/Label'
import {DAYS, Days, RecurrenceDays} from '@/types/Day'
import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {Recurrence, RecurrenceSchema} from '@/types/Recurrence'
import {ComponentPropsWithoutRef, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'

export type RecurrenceSelectorProps = Omit<ComponentPropsWithoutRef<'select'>, 'onChange'> & {
  value?: Recurrence
  onChange?: (value?: Recurrence) => void
  type?: JourneyType
  recurrenceDays: RecurrenceDays
  onRecurrenceDaysDidChange: (days: RecurrenceDays) => void
}

export const RecurrenceSelector = ({
  value,
  onChange,
  id = 'journey-recurrence',
  type,
  recurrenceDays,
  onRecurrenceDaysDidChange,
  ...props
}: RecurrenceSelectorProps) => {
  const {formatMessage} = useIntl()
  const isOnetimeJourneyType = useMemo(() => type === JourneyTypeSchema.enum.ONE_TIME, [type])

  const options = Object.values(RecurrenceSchema.Values).map(recurrence => ({
    value: recurrence,
    label: formatMessage({id: `JOURNEY_BUILDER.RECURRENCE_OPTION_${recurrence}`}),
  }))

  const showDaySelector =
    value === RecurrenceSchema.enum.WEEKLY && isFeatureEnabled(FeatureToggle.recurrenceDaySelector)
  const hintInfoId =
    value === RecurrenceSchema.enum.MONTHLY
      ? 'JOURNEY_BUILDER.RECURRENCE_INFO_MONTHLY'
      : 'JOURNEY_BUILDER.RECURRENCE_INFO'

  const onDidChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.((evt.target.value as Recurrence) || undefined)
  }

  if (!isFeatureEnabled(FeatureToggle.recurrence) || isOnetimeJourneyType) {
    return null
  }

  return (
    <div className="flex items-center gap-7">
      <div className={cn('flex flex-col gap-2 text-text-secondary', props.className)}>
        <p className="text-sm font-bold">{formatMessage({id: 'JOURNEY_BUILDER.RECURRENCE_TITLE'})}</p>
        <InputSelector
          {...props}
          label={formatMessage({id: 'JOURNEY_BUILDER.RECURRENCE_LABEL'})}
          info={value ? formatMessage({id: hintInfoId}) : undefined}
          id={id}
          value={value}
          onChange={onDidChange}
        >
          <option key="empty" value="">
            {formatMessage({id: 'JOURNEY_BUILDER.RECURRENCE_SELECT'})}
          </option>
          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </InputSelector>
      </div>
      {showDaySelector && <DaySelector days={recurrenceDays} onDaysDidChange={onRecurrenceDaysDidChange} />}
    </div>
  )
}
type DayInfo = {
  value: Days
  label: string
  isSelected: boolean
}

const DaySelector: React.FC<{
  days: RecurrenceDays
  onDaysDidChange: (days: RecurrenceDays) => void
}> = ({days, onDaysDidChange}) => {
  const {formatMessage} = useIntl()
  const intialDaysInfo = DAYS.map(day => ({
    value: day,
    label: formatMessage({id: `DAYS.${day}`}),
    isSelected: days.includes(day),
  }))
  const [daysInfo, setDays] = useState<Array<DayInfo>>(intialDaysInfo)

  const onClickDay = (day: DayInfo) => {
    const selectedDays: RecurrenceDays = []
    const newDays = daysInfo.map(d => {
      if (d.value === day.value) {
        return {...d, isSelected: !d.isSelected}
      }
      return d
    })
    newDays.forEach(d => {
      if (d.isSelected) {
        selectedDays.push(d.value)
      }
    })
    if (selectedDays.length === 0) {
      return
    }
    setDays(newDays)
    onDaysDidChange(selectedDays)
  }

  return (
    <div className="flex w-full max-w-xs flex-col gap-2" data-testid="day-selector">
      <FieldLabel required label="On a specific day" />

      <div className="mt-1 flex w-full items-center gap-1.5">
        {daysInfo.map(day => (
          <Label
            id={`day-${day.value}`}
            selected={day.isSelected}
            key={day.value}
            className="min-w-10"
            size="small"
            onClick={() => onClickDay(day)}
          >
            {day.label}
          </Label>
        ))}
      </div>
    </div>
  )
}

import {useGoBack} from '@/components/Drawer/hooks/useGoBack'
import {BorderlessButton} from '@/shared-components/Button'
import {useMutation} from '@tanstack/react-query'
import {Icon} from 'pepsico-ds'
import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import Modal from '../../../shared-components/Modal/Modal'
import {store} from '../../../store'
import {showNotification} from '../../../store/slices'
import {deleteJourneyUseCase} from '../../../useCases'

type DeleteJourneyButtonProps = {
  journeyId?: string
  isDisabled: boolean
  onIsDeletingHandler?: (isDeleting: boolean) => void
  setIsFloatingMenuOpen?: (isOpen: boolean) => void
}

export const DeleteJourneyButton = ({
  journeyId,
  isDisabled,
  onIsDeletingHandler,
  setIsFloatingMenuOpen,
}: DeleteJourneyButtonProps) => {
  const {formatMessage} = useIntl()
  const {goBack} = useGoBack()
  const location = useLocation()

  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] = useState(false)
  const [currentModalData, setCurrentModalData] = useState<ModalContent>({
    content: '',
    title: '',
    confirmLabel: '',
    onConfirm: () => setIsDeleteWarningModalOpen(false),
  })

  const {mutate: mutateDeleteJourney, isPending: isLoading} = useMutation({
    mutationKey: ['deleteJourney'],
    mutationFn: deleteJourneyUseCase,
  })

  const isJourneySavedAtLeastOnce = location.pathname?.includes('journey/')
  const isOptionDisabled = !isJourneySavedAtLeastOnce || isDisabled

  const deleteJourney = useCallback(() => {
    if (isDisabled) return

    onIsDeletingHandler?.(true)
    mutateDeleteJourney(
      {
        id: journeyId,
      },
      {
        onSuccess: () => {
          onIsDeletingHandler?.(false)
          onDeleteJourney.Success()
          goBack()
        },
        onError: () => {
          onIsDeletingHandler?.(false)
          onDeleteJourney.Error()
        },
      }
    )
    setIsFloatingMenuOpen?.(false)
    setIsDeleteWarningModalOpen(false)
  }, [isDisabled, onIsDeletingHandler, mutateDeleteJourney, journeyId, setIsFloatingMenuOpen, goBack])

  const showDeleteConfirmationModal = useCallback(() => {
    if (isOptionDisabled) return
    setCurrentModalData({
      content: (
        <p className="text-sm" data-testid="delete-draft-modal-message">
          {formatMessage({id: `JOURNEY_BUILDER.DELETE_WARNING_MODAL_MESSAGE`})}
        </p>
      ),
      title: formatMessage({id: `JOURNEY_BUILDER.DELETE_WARNING_MODAL_TITLE`}),
      confirmLabel: formatMessage({id: `JOURNEY_BUILDER.DELETE_WARNING_MODAL_CONFIRM`}),
      onConfirm: deleteJourney,
    })
    setIsDeleteWarningModalOpen(true)
  }, [isOptionDisabled, formatMessage, deleteJourney])

  return (
    <>
      <BorderlessButton
        size="small"
        disabled={isOptionDisabled}
        data-testid="delete-draft-button"
        data-cy="delete-draft-button"
        className={`flex items-center gap-1 fill-primary text-primary hover:fill-button-primary-hover hover:text-button-primary-hover disabled:fill-link-disabled disabled:text-link-disabled`}
        onClick={showDeleteConfirmationModal}
        isLoading={isLoading}
      >
        <Icon icon="delete_outline" size="small" />
        <p className={`text-xs font-bold `}>{formatMessage({id: 'JOURNEY_BUILDER.DELETE_BUTTON'})}</p>
      </BorderlessButton>

      <Modal
        onClose={() => {
          setIsFloatingMenuOpen?.(false)
          setIsDeleteWarningModalOpen(false)
        }}
        onClickSecondaryButton={() => {
          setIsFloatingMenuOpen?.(false)
          setIsDeleteWarningModalOpen(false)
        }}
        onClickPrimaryButton={currentModalData.onConfirm}
        isOpen={isDeleteWarningModalOpen}
        title={currentModalData.title}
        primaryButtonText={currentModalData.confirmLabel}
        content={currentModalData.content}
      />
    </>
  )
}

const onDeleteJourney = {
  Success: () => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'success',
        title: 'Success',
        message: 'Your Journey have been deleted!',
      })
    )
  },
  Error: () => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'error',
        title: 'Something went wrong!',
        message: 'Please try again later.',
      })
    )
  },
}

interface ModalContent {
  content: React.ReactNode
  title: string
  confirmLabel: string
  onConfirm: () => void
}

// DiamondNodeWidget.tsx
import {useWidgetInfo} from '@/components/Canvas/hooks/useWidgetInfo'
import {Icon} from 'pepsico-ds'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {SendPushNodeModel} from './SendPushNodeModel'

export interface SendPushNodeWidgetProps extends CustomNodeWidgetProps {
  node: SendPushNodeModel
}

export const SendPushNodeWidget = ({engine, node, ...props}: SendPushNodeWidgetProps) => {
  const {formatMessage, haveWarnings, isSelected, portBottom, portTop} = useWidgetInfo(node)

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      portBottom={portBottom}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      classNameWidgetWrapper="node-widget type-send-push"
    >
      <div className="flex items-center justify-center gap-3">
        <div className={'node-icon'} data-testid="send-push-icon">
          {/* <EmailIcon className="h-4 w-4 fill-icon" /> */}
          <Icon icon="mail" size="small" className="$color-icon" />
        </div>
        <div className="node-content">
          <p className="node-name">{formatMessage({id: 'JOURNEY_BUILDER.SEND_PUSH_WIDGET_LABEL'})}</p>
        </div>
      </div>
    </CustomNodeWidget>
  )
}

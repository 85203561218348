import {z} from 'zod'

const messageParameter = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  createDate: z.string(),
  updateDate: z.string(),
  active: z.boolean(),
  definition: z.string(),
  value: z.string(),
})

const messageParameters = z.array(messageParameter)

export const GetAllDynamicTagsRtnRequestSchema = z.object({})

export const GetAllDynamicTagsRtnResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    messageParameters: messageParameters,
  }),
  errorMessage: z.string().nullish(),
})

export type getAllDynamicTagsRtnRequest = z.infer<typeof GetAllDynamicTagsRtnRequestSchema>
export type getAllDynamicTagsRtnResponse = z.infer<typeof GetAllDynamicTagsRtnResponseSchema>

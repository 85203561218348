import {usePromptBlocker} from '@/components/PageLeavingWarning/usePromptBlocker'
import Modal, {ModalProps} from '@/shared-components/Modal/Modal'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'

export type PageLeavingWarningProps = Omit<ModalProps, 'isOpen' | 'mode' | 'title' | 'content'> & {
  shouldBlock: boolean
  beforeUnload?: boolean
  title?: string
  content?: string
}

export const PageLeavingWarning = ({shouldBlock, beforeUnload = true, ...props}: PageLeavingWarningProps) => {
  const {formatMessage} = useIntl()

  const {blocker} = usePromptBlocker(shouldBlock, beforeUnload)

  const modalProps = {
    title: props.title ?? formatMessage({id: `UNSAVED_CHANGES.MODAL_TITLE`}),
    content: props.content ?? formatMessage({id: `UNSAVED_CHANGES.MODAL_CONTENT`}),
    primaryButtonText: props.primaryButtonText ?? formatMessage({id: `UNSAVED_CHANGES.MODAL_SECONDARY_BUTTON`}),
    secondaryButtonText: props.secondaryButtonText ?? formatMessage({id: `UNSAVED_CHANGES.MODAL_PRIMARY_BUTTON`}),
  }

  const onDidClose = useCallback(() => {
    props.onClose?.()
    blocker.reset?.()
  }, [blocker, props])

  const onDidConfirm = useCallback(() => {
    props.onClickPrimaryButton?.()
    blocker.reset?.()
  }, [blocker, props])

  const onDidCancel = useCallback(() => {
    props.onClickSecondaryButton?.()
    blocker.proceed?.()
  }, [blocker, props])

  return (
    <Modal
      {...modalProps}
      isOpen={blocker.state === 'blocked'}
      onClose={onDidClose}
      onClickPrimaryButton={onDidConfirm}
      onClickSecondaryButton={onDidCancel}
    />
  )
}

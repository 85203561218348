import {SecondaryButton} from '@/shared-components/Button'
import {useOktaAuth} from '@okta/okta-react'
import {useIntl} from 'react-intl'

export const Logout = () => {
  const {oktaAuth} = useOktaAuth()
  const {formatMessage} = useIntl()

  const onClickLogout = () => {
    oktaAuth.signOut()
  }

  return (
    <div className="flex justify-end">
      <SecondaryButton data-testid="btn-logout" onClick={onClickLogout}>
        {formatMessage({id: 'LOGOUT.LOGOUT_BUTTON'})}
      </SecondaryButton>
    </div>
  )
}

import {z} from 'zod'

// MARK: - Send Push Step

export const GetSendPushStepConfigRequestSchema = z.object({}).nullish()
export type GetSendPushStepConfigRequest = z.infer<typeof GetSendPushStepConfigRequestSchema>

export const CustomImageItemSchema = z.object({
  id: z.string(),
  url: z.string(),
  title: z.string().nullish(),
})
export type CustomImageItem = z.infer<typeof CustomImageItemSchema>

export const LandingPageItemSchema = z.object({
  id: z.string(),
  value: z.string(),
  title: z.string().nullish(),
})
export type LandingPageItem = z.infer<typeof LandingPageItemSchema>

export const GetSendPushStepConfigResponseSchema = z.object({
  images: z.array(CustomImageItemSchema).nullish(),
  landingPages: z.array(LandingPageItemSchema).nullish(),
})
export type GetSendPushStepConfigResponse = z.infer<typeof GetSendPushStepConfigResponseSchema>

// MARK: - Send WhatsApp Step

export const GetSendWhatsAppStepConfigRequestSchema = z.object({}).nullish()
export type GetSendWhatsAppStepConfigRequest = z.infer<typeof GetSendWhatsAppStepConfigRequestSchema>

export const TemplateItemSchema = z.object({
  id: z.string(),
  message: z.string(),
})
export type TemplateItem = z.infer<typeof TemplateItemSchema>

export const GetSendWhatsAppStepConfigResponseSchema = z.object({
  templates: z.array(TemplateItemSchema),
})
export type GetSendWhatsAppStepConfigResponse = z.infer<typeof GetSendWhatsAppStepConfigResponseSchema>

import {z} from 'zod'

export const TemplateTypeSchema = z.enum(['PUSH_NOTIFICATION', 'SMS', 'WHATSAPP'])
export type TemplateType = z.infer<typeof TemplateTypeSchema>

export const GetTemplatesRequestSchema = z.object({
  type: TemplateTypeSchema.optional(),
})
export type GetTemplatesRequest = z.infer<typeof GetTemplatesRequestSchema>

export const CountryCodeSchema = z.string().max(2)

export type CountryCode = z.infer<typeof CountryCodeSchema>

export const TemplateSchema = z.object({
  id: z.string(),
  message: z.string(),
  type: TemplateTypeSchema.optional(),
  country: CountryCodeSchema.optional(),
})

export const GetTemplatesResponseSchema = z.object({
  content: z.array(TemplateSchema),
})

export type GetTemplatesResponse = z.infer<typeof GetTemplatesResponseSchema>

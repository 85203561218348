import {cn} from '@/helpers/shadcn/utils'
import {ComponentProps} from 'react'

type LinkProps = ComponentProps<'button'>

export const Link = (props: LinkProps) => {
  return (
    <button
      {...props}
      className={cn(
        'text-sx group z-10 flex items-center gap-1 fill-link enabled:text-link disabled:fill-link-disabled disabled:text-link-disabled',
        props.className
      )}
    />
  )
}

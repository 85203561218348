import {ChangeEvent, ChangeEventHandler} from 'react'

export const useEmojiPickerButtonEvents = (
  value: string,
  onChangeInput: ChangeEventHandler,
  caretPosition: number,
  setCaretPosition: (pos: number) => void
) => {
  const handleEmojiPickEvent = (emoji: string) => {
    if (value) {
      const newValue = value.slice(0, caretPosition) + emoji + value.slice(caretPosition)
      onChangeInput({target: {value: newValue}} as ChangeEvent<HTMLInputElement>)
      setCaretPosition(caretPosition + emoji.length)
    } else {
      onChangeInput({target: {value: emoji}} as ChangeEvent<HTMLInputElement>)
      setCaretPosition(emoji.length)
    }
  }

  return {handleEmojiPickEvent}
}

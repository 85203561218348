import {isSUT} from '@/utils/env'
import React, {createContext, useMemo, useState} from 'react'

const initialFunction = () => {
  if (isSUT()) return
  console.error('Save node data context not initiliazed')
}

interface ContextProps {
  saveData: () => void
  setSaveData: (val: () => void) => void
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (val: boolean) => void
}

// Create the context
export const SaveNodeDataContext = createContext<ContextProps>({
  saveData: initialFunction,
  setSaveData: initialFunction,
  hasUnsavedChanges: false,
  setHasUnsavedChanges: initialFunction,
})

// Create the provider component
export const SaveNodeDataProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [saveData, setSaveData] = useState(() => initialFunction)

  const value = useMemo(
    () => ({hasUnsavedChanges, saveData, setHasUnsavedChanges, setSaveData}),
    [hasUnsavedChanges, saveData]
  )

  return <SaveNodeDataContext.Provider value={value}>{children}</SaveNodeDataContext.Provider>
}

import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {PrimaryButton, SecondaryButton} from '@/shared-components/Button'
import React from 'react'
import {useIntl} from 'react-intl'

export type DrawerBottomButtonsProps = {
  primaryButtonLabel: string
  secondaryButtonLabel: string
  testIdPrimary: string
  testIdSecondary: string
  onClickSecondary: () => void
  onClickPrimary: () => void
  isPrimaryDisabled?: boolean
}

const DrawerBottomButtons: React.FC<DrawerBottomButtonsProps> = ({
  testIdPrimary,
  testIdSecondary,
  secondaryButtonLabel,
  primaryButtonLabel,
  onClickPrimary,
  onClickSecondary,
  isPrimaryDisabled,
}) => {
  const {formatMessage} = useIntl()
  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  return (
    <div className="absolute bottom-0 left-0 flex h-16 w-full items-center justify-end gap-4 border-t bg-white px-6">
      {isPublishedOrCompleted && (
        <SecondaryButton data-testid={testIdSecondary} data-cy={testIdSecondary} onClick={onClickSecondary}>
          {formatMessage({id: 'GENERAL.CLOSE_BUTTON'})}
        </SecondaryButton>
      )}

      {!isPublishedOrCompleted && (
        <>
          {secondaryButtonLabel && (
            <SecondaryButton data-testid={testIdSecondary} data-cy={testIdSecondary} onClick={onClickSecondary}>
              {secondaryButtonLabel}
            </SecondaryButton>
          )}

          {primaryButtonLabel && (
            <PrimaryButton
              data-testid={testIdPrimary}
              data-cy={testIdPrimary}
              onClick={onClickPrimary}
              disabled={isPrimaryDisabled}
            >
              {primaryButtonLabel}
            </PrimaryButton>
          )}
        </>
      )}
    </div>
  )
}

export default DrawerBottomButtons

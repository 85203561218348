import {AuthContext} from '@/helpers/Auth/AuthProvider'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useCountries} from '@/hooks/useCountries'
import {UserRoleEnum, UserRoleEnumSchema} from '@/types/UserRole'
import {getUserRoleUseCase} from '@/useCases/getUserRoleUseCase'
import {useQuery} from '@tanstack/react-query'
import {PropsWithChildren, createContext, useContext, useMemo} from 'react'

type UserRoleContextType = {
  isReady: boolean
  isAuthorized: boolean
  userRole?: UserRoleEnum
  userAccessRules: string[]
  error: Error | null
}

const initialState: UserRoleContextType = {
  isReady: false,
  isAuthorized: false,
  userRole: undefined,
  userAccessRules: [],
  error: null,
}

export const UserRoleContext = createContext<UserRoleContextType>(initialState)

export const UserRoleProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {isAuthenticated, authState} = useContext(AuthContext)

  const {countries} = useCountries()

  const {data, error} = useQuery({
    queryKey: [`userRole-${authState?.accessToken?.claims?.gpid}`],
    queryFn: ({signal}) => getUserRoleUseCase(signal),
    enabled: isFeatureEnabled(FeatureToggle.userRole) && isAuthenticated,
  })

  const value = useMemo(() => {
    if (!isFeatureEnabled(FeatureToggle.userRole)) {
      return {
        ...initialState,
        isReady: true,
        isAuthorized: true,
        userRole: UserRoleEnumSchema.Values.admin,
      }
    }

    if (!data || error) {
      return {
        ...initialState,
        error,
      }
    }

    const userRole = data[0]?.role
    const userAccessRules = accessRuleToCountryCodeAdapter(data[0]?.accessRules?.[0]?.values ?? [])
    const isAdmin = userRole === UserRoleEnumSchema.Values.admin
    const isAuthorized = isAdmin ? true : countries.some(countryCode => userAccessRules.includes(countryCode))

    return {
      ...initialState,
      isReady: true,
      isAuthorized,
      userRole,
      userAccessRules,
    }
  }, [countries, data, error])

  return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>
}

/**
 * Parse market from accessRules pattern adapting it to ["countryCode"] format.
 * Accepted patterns:
 *  "region-market" -> ["Europe-TR"]
 *  "market" ->  ["TR"]
 * @param accessRule array of accessRules strings
 * @returns array of countrCodes user has access to: ["TR"]
 */
const accessRuleToCountryCodeAdapter = (accessRule: string[] = []) => {
  return accessRule.map(rule => {
    const [, market] = rule.split('-')
    return market ?? rule
  })
}

import {DefaultLinkModel} from '@projectstorm/react-diagrams'

export class CustomLinkModel extends DefaultLinkModel {
  constructor() {
    super({
      type: 'custom',
      width: 3,
      curvyness: 2,
      color: '#000',
    })
  }

  isSelected(): boolean {
    return false
  }
}

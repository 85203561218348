import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useIntl} from 'react-intl'

export const HowtoAudience = () => {
  const {formatMessage} = useIntl()

  return (
    <>
      <p>
        The audience criteria is configured in the <b> Entry Conditions </b> step. After the Journey Details were filled
        from the previous screen, it's time to define the audience criteria for the Journey.
      </p>

      <div className="flex gap-4">
        <ol className="w-full">
          <li>
            The <b> Re-entry days </b> determines whether a store should repeat a particular journey if it still meets
            the entry condition of that journey. It also specifies the number of days the system should wait before
            including the store again as part of the audience, even if all the journey steps have been executed before
            and regardless of recurrence and/or existing wait steps. If the journey is set to not allow the store to
            repeat the journey, the store will never be included again, even if it meets the entry condition again in
            the future. To do so, simply leave the field blank.
            {isFeatureEnabled(FeatureToggle.journeyType) && (
              <p data-testid="reentry-days-tip">
                <i>
                  <b> Obs: </b> This field is only available when the type of journey is
                  <b> {formatMessage({id: 'JOURNEY_BUILDER.JOURNEY_TYPE_RECURRENT'})} </b>.
                </i>
              </p>
            )}
          </li>

          <li>
            Then, select a <b> Parameter </b> which will be used to filter the audience of the journey and configure it.
            <p>
              Optionally, you can add more parameters to the audience criteria by clicking on the{' '}
              <b> Add New Parameter button </b>.
              <br />
              <i>Obs: any extra trait will be treated as an "and" condition to be satisfied.</i>
            </p>
          </li>
        </ol>

        <p className="w-1/2 border-l-4 border-yellow-300/50 bg-yellow-100/10 p-4 font-medium italic">
          To edit the Entry Conditions configuration later, from the Journey Builder screen, click on the{' '}
          <b> Edit button </b> of the step or double-click on the step widget.
          <img src="/img/userGuide/edit-entry-conditions-button.png" width="227" alt="Edit Journey Details button" />
        </p>
      </div>
    </>
  )
}

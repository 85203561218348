import {isNew, isPublishedOrCompleted} from '@/helpers/statusHandler'
import {useAppSelector} from '@/store'
import {useMemo} from 'react'

// MARK: - Hook

export const useJourneyMetaConfig = () => {
  const journeyConfig = useAppSelector(state => state.journeyConfig)

  const _isPublishedOrCompleted = useMemo(() => isPublishedOrCompleted(journeyConfig.status), [journeyConfig.status])
  const _isNew = useMemo(() => isNew(journeyConfig.status), [journeyConfig.status])

  return {
    ...journeyConfig,
    isPublishedOrCompleted: _isPublishedOrCompleted,
    isNew: _isNew,
  }
}

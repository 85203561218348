import {createNotificationService, updateNotificationService} from '@/services/notificationsService'
import {EntryCondition, Step} from '@/types/Notifications'

export type CreateNotificationUseCaseParams = {
  notificationId?: string
  id_country: number
  id_eventstatus: number
  name: string
  description: string
  active: boolean
  redirect_page?: string
  entry_conditions: EntryCondition[]
  steps: Step[]
}

export async function createNotificationUseCase(params: CreateNotificationUseCaseParams) {
  const {notificationId} = params
  const isEditting = !!notificationId

  try {
    const response = (await isEditting)
      ? updateNotificationService(notificationId!, params)
      : createNotificationService(params)
    return response
  } catch (error) {
    console.log('Error in createNotificationUseCase:', error)
    throw error
  }
}

import {useSaveNodeData} from '@/helpers/SaveNodeDataProvider/useNodeDataContext.hook'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {checkIfIsInvalidEntryConditionConfig} from '@/store/middlewares/nodesDataWarningsListener'
import {resetCurrentNode, updateNodeParamsSelectors, updateNodeWarnings, updateReEntryDays} from '@ReduxActions'
import _ from 'lodash'
import {useCallback, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../store'
import {ParamFilters, ParamsSelector} from '../../../types/paramFilters/paramFilters'

export const useEntryConditionsData = () => {
  const {id: currentNodeId} = useAppSelector(state => state.currentNode)
  const {
    reentryDays: reentryDaysRedux,
    paramsSelectors: paramsSelectorsRedux,
    type,
  } = useAppSelector(state => state.nodesData[currentNodeId] as ParamFilters)
  const [reentryDays, setReentryDays] = useState<number | null>(reentryDaysRedux)
  const [paramsSelectors, setParamsSelectors] = useState<Array<ParamsSelector>>(paramsSelectorsRedux)
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const dispatch = useAppDispatch()
  const {setHasUnsavedChanges, setSaveData} = useSaveNodeData()

  const updateReEntryDaysValue = useCallback(() => {
    dispatch(updateReEntryDays({id: currentNodeId, reentryDays}))
  }, [reentryDays, currentNodeId, dispatch])

  const updateParamsSelectors = useCallback(() => {
    dispatch(updateNodeParamsSelectors({id: currentNodeId, paramsSelectors}))
  }, [currentNodeId, paramsSelectors, dispatch])

  const saveAllTempData = useCallback(
    (hasUnsavedChanges: boolean) => {
      if (isPublishedOrCompleted) return
      if (!hasUnsavedChanges) return

      updateReEntryDaysValue()
      updateParamsSelectors()

      dispatch(resetCurrentNode())
    },
    [isPublishedOrCompleted, dispatch, updateReEntryDaysValue, updateParamsSelectors]
  )

  const checkAndUpdateEntryConditionsWarnings = useCallback(
    (paramsSelectorsParam: Array<ParamsSelector>, reentryDaysParam: number | null) => {
      const haveWarnings = checkIfIsInvalidEntryConditionConfig({
        id: currentNodeId,
        type,
        paramsSelectors: paramsSelectorsParam,
        reentryDays: reentryDaysParam,
      })

      dispatch(updateNodeWarnings({id: currentNodeId, haveWarnings}))
    },
    [currentNodeId, type, dispatch]
  )

  useEffect(() => {
    const hasUnsavedChanges = !_.isEqual(
      {reentryDays, paramsSelectors},
      {reentryDays: reentryDaysRedux, paramsSelectors: paramsSelectorsRedux}
    )
    setHasUnsavedChanges(hasUnsavedChanges)

    checkAndUpdateEntryConditionsWarnings(paramsSelectors, reentryDays)

    setSaveData(() => () => saveAllTempData(hasUnsavedChanges))
  }, [
    currentNodeId,
    reentryDays,
    paramsSelectors,
    reentryDaysRedux,
    paramsSelectorsRedux,
    type,
    dispatch,
    saveAllTempData,
    setHasUnsavedChanges,
    setSaveData,
    checkAndUpdateEntryConditionsWarnings,
  ])

  return {
    paramsSelectors,
    reentryDays,
    setParamsSelectors,
    setReentryDays,
  }
}

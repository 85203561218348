import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {NodeTypeForSendNotificationEnum} from '../../types/NodeDataForSendNotification'
import {NodeTypeEnum} from '../../types/NodeTypeEnum'
import {
  StepRequiresIntervention,
  StepSendPushNotification,
  StepSendPushNotificationConfig,
  StepSendSMS,
  StepSendSMSConfig,
  StepSendWhatsApp,
  StepSendWhatsAppConfig,
  StepWait,
  StepWaitConfig,
} from '../../types/Steps'
import {NodeTypeForParamsFiltersEnum, ParamFilters, ParamsSelector} from '../../types/paramFilters/paramFilters'
import {initialRequiresInterventionConfig} from '../data/initialRequiresInterventionConfig'
import {initialSendPushConfig, initialSendSMSConfig, initialSendWhatsAppConfig} from '../data/initialSendPushConfig'
import {initialWaitConfig} from '../data/initialWaitConfig'
import {initialParamsFiltersConfig} from './../data/initialParamsFiltersConfig'

export type NodesData =
  | ParamFilters
  | StepWait
  | StepSendSMS
  | StepSendPushNotification
  | StepSendWhatsApp
  | StepRequiresIntervention

type NodesDataState = Record<string, NodesData>

const initialState: NodesDataState = {}

const nodesDataSlice = createSlice({
  name: 'nodesData',
  initialState: initialState,
  reducers: {
    setInitialNodeParamFiltersData: (
      state,
      action: PayloadAction<{
        id: string
        type: NodeTypeForParamsFiltersEnum
      }>
    ) => {
      const id = action.payload.id
      state[id] = {
        ...initialParamsFiltersConfig,
        ...action.payload,
      }
    },
    updateReEntryDays: (
      state,
      action: PayloadAction<{
        id: string
        reentryDays: number | null
      }>
    ) => {
      const id = action.payload.id
      const node = state[id] as ParamFilters
      state[id] = {
        ...node,
        reentryDays: action.payload.reentryDays,
      }
    },
    updateNodeParamsSelectors: (state, action: PayloadAction<{id: string; paramsSelectors: Array<ParamsSelector>}>) => {
      const id = action.payload.id
      const node = state[id] as ParamFilters
      state[id] = {
        ...node,
        paramsSelectors: action.payload.paramsSelectors,
      }
    },
    updateNodeWarnings: (
      state,
      action: PayloadAction<{
        id: string
        haveWarnings: boolean
      }>
    ) => {
      const id = action.payload.id
      const node = state[id]
      state[id] = {
        ...node,
        haveWarnings: action.payload.haveWarnings,
      }
    },
    setInitialNodeWaitData: (
      state,
      action: PayloadAction<{
        id: string
        type: NodeTypeEnum.wait
      }>
    ) => {
      const id = action.payload.id
      state[id] = {
        ...initialWaitConfig,
        ...action.payload,
      }
    },
    updateNodeWaitData: (state, action: PayloadAction<{id: string; config: StepWaitConfig}>) => {
      const {id, config = initialWaitConfig.config} = action.payload
      const node = state[id] as StepWait
      state[id] = {
        ...node,
        config,
      }
    },

    setInitialNodeSendNotificationData: (
      state,
      action: PayloadAction<{
        id: string
        type: NodeTypeForSendNotificationEnum
      }>
    ) => {
      const id = action.payload.id
      const config = (() => {
        switch (action.payload.type) {
          case NodeTypeEnum.sendSMS:
            return initialSendSMSConfig
          case NodeTypeEnum.sendPush:
            return initialSendPushConfig
          case NodeTypeEnum.sendWhatsApp:
            return initialSendWhatsAppConfig
        }
      })()

      state[id] = {
        ...config,
        id: action.payload.id,
      }
    },
    updateNodeSendPushNotificationData: (
      state,
      action: PayloadAction<{id: string; config: StepSendPushNotificationConfig}>
    ) => {
      const id = action.payload.id
      const node = state[id] as StepSendPushNotification
      state[id] = {
        ...node,
        config: {
          ...initialSendPushConfig.config,
          ...action.payload.config,
        },
      }
    },
    updateNodeSendSMSData: (state, action: PayloadAction<{id: string; config: StepSendSMSConfig}>) => {
      const id = action.payload.id
      const node = state[id] as StepSendSMS
      state[id] = {
        ...node,
        config: {
          ...initialSendSMSConfig.config,
          ...action.payload.config,
        },
      }
    },
    updateNodeSendWhatsAppData: (state, action: PayloadAction<{id: string; config: StepSendWhatsAppConfig}>) => {
      const id = action.payload.id
      const node = state[id] as StepSendWhatsApp
      state[id] = {
        ...node,
        config: {
          ...initialSendWhatsAppConfig.config,
          ...action.payload.config,
        },
      }
    },

    setInitialNodeRequiresInterventionData: (
      state,
      action: PayloadAction<{
        id: string
        type: NodeTypeEnum.requiresIntervention
      }>
    ) => {
      const id = action.payload.id
      state[id] = {
        ...initialRequiresInterventionConfig,
        ...action.payload,
      }
    },

    removeNodeDataRef: (state, action: PayloadAction<{id: string}>) => {
      const currentState = {...state}
      if (currentState[action.payload.id]?.type === NodeTypeEnum.entryCondition) return state

      delete currentState[action.payload.id]
      return currentState
    },
    cleanNodesWithWarnings: state => {
      return Object.keys(state).reduce((acc, key) => {
        const node = state[key]
        if (node.haveWarnings) return acc
        acc[key] = node
        return acc
      }, {} as NodesDataState)
    },
    resetNodesData: () => initialState,
  },
})

export const {
  setInitialNodeParamFiltersData,
  updateNodeParamsSelectors,
  updateNodeWarnings,
  updateReEntryDays,
  setInitialNodeWaitData,
  updateNodeWaitData,
  setInitialNodeSendNotificationData,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  setInitialNodeRequiresInterventionData,
  removeNodeDataRef,
  resetNodesData,
  cleanNodesWithWarnings,
} = nodesDataSlice.actions

export const nodesDataReducer = nodesDataSlice.reducer

import {getSendPushStepConfigService, getSendWhatsAppStepConfigService} from '@/services/stepConfigService'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendPushStepConfigResponse, GetSendWhatsAppStepConfigResponse} from '@/types/StepConfigParams'

export type StepConfigResult = GetSendPushStepConfigResponse | GetSendWhatsAppStepConfigResponse

export function getStepConfigUseCase(type: NodeTypeEnum, signal?: AbortSignal): Promise<StepConfigResult> {
  switch (type) {
    case NodeTypeEnum.sendPush:
      return getSendPushStepConfigUseCase(signal)
    case NodeTypeEnum.sendWhatsApp:
      return getWhatsAppStepConfigUseCase(signal)
    default:
      throw new Error(`Unsupported step type: ${type}`)
  }
}

function getSendPushStepConfigUseCase(signal?: AbortSignal): Promise<GetSendPushStepConfigResponse> {
  return getSendPushStepConfigService(null, signal)
}

function getWhatsAppStepConfigUseCase(signal?: AbortSignal): Promise<GetSendWhatsAppStepConfigResponse> {
  return getSendWhatsAppStepConfigService(null, signal)
}

// DiamondNodeWidget.tsx
import {PortModelAlignment} from '@projectstorm/react-diagrams'
import classnames from 'classnames'
import {Icon} from 'pepsico-ds'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {CustomPortModel} from '../CustomPortModel'
import {VariantNodeModel} from './VariantNodeModel'

interface VariantNodeWidgetProps extends CustomNodeWidgetProps {
  node: VariantNodeModel
}

export const VariantNodeWidget = ({engine, node, ...props}: VariantNodeWidgetProps) => {
  const isSelected = node.getOptions().selected

  const portTop = node.getPort(PortModelAlignment.TOP) as CustomPortModel
  const portLeft = node.getPort(PortModelAlignment.LEFT) as CustomPortModel
  const portRight = node.getPort(PortModelAlignment.RIGHT) as CustomPortModel

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      portLeft={portLeft}
      portRight={portRight}
    >
      <div>
        <div
          className={classnames('node-widget type-variant', [
            {
              selected: isSelected,
            },
          ])}
        >
          <div className="node-icon" data-testid="variant-icon">
            <Icon icon="filter_alt" size="small" className="$color-icon h-4 w-4" />
          </div>
          <div className="node-content">
            <p className="node-name">{node.getOptions().extras.name}</p>
          </div>
        </div>
      </div>
    </CustomNodeWidget>
  )
}

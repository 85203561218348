import {GenerateWidgetEvent} from '@projectstorm/react-canvas-core'
import {JSX} from 'react'
import {NodeTypeEnum} from '../../../../types/NodeTypeEnum'
import {CustomNodeFactory} from '../CustomNodeFactory'
import {CustomNodeModel} from '../CustomNodeModel'
import {WaitNodeModel} from './WaitNodeModel'
import {WaitNodeWidget} from './WaitNodeWidget'

export class WaitNodeFactory extends CustomNodeFactory {
  constructor() {
    super(NodeTypeEnum.wait)
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomNodeModel>): JSX.Element {
    return <WaitNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel() {
    return new WaitNodeModel()
  }
}

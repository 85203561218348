import {StepSendSMS} from '@/types/Steps'
import {useIntl} from 'react-intl'

type SendSMSStepInfoProps = {
  stepData: StepSendSMS
}

export const SendSMSStepInfo = ({stepData}: SendSMSStepInfoProps) => {
  const {formatMessage} = useIntl()

  const shouldDisplay = stepData.config.message?.length > 0

  if (!shouldDisplay) {
    return null
  }

  return (
    <div className="flex flex-col gap-1" data-testid="step-info-value">
      <span className="line-clamp-2 w-fit max-w-56 overflow-hidden overflow-ellipsis">
        {formatMessage({id: 'SEND_NOTIFICATION_CONTENT.MESSAGE_LABEL'})} : {stepData.config.message}
      </span>
    </div>
  )
}

import {getInputErrorMessage} from '@/helpers/getErrorMessage'
import {cn} from '@/helpers/shadcn/utils'
import {InputText} from '@/shared-components/InputText'
import {useIntl} from 'react-intl'

interface Props {
  id?: string
  inputValue: string
  onChangeInput: (newValue: string) => void
  charLimitSize: number
  placeholder: string
  inputDisabled?: boolean
  displayCharLimit?: boolean
  label?: string
  required?: boolean
}

export const InputWithCharLimit: React.FC<Props> = ({
  inputValue,
  inputDisabled,
  charLimitSize,
  displayCharLimit,
  placeholder,
  onChangeInput,
  label,
  required,
  ...props
}) => {
  const {formatMessage} = useIntl()
  const invalidInputSize = inputValue.length > charLimitSize || inputValue.length === 0
  const onChange = (value: string) => {
    onChangeInput(value)
  }

  const errorMessage = getInputErrorMessage(inputValue, charLimitSize, formatMessage)

  return (
    <div>
      <InputText
        {...props}
        data-testid={`${props.id}-input`}
        data-cy={`${props.id}-input`}
        label={label}
        placeholder={placeholder}
        disabled={inputDisabled}
        name="text"
        error={errorMessage}
        value={inputValue}
        onChange={e => onChange(e.target.value)}
        required={required}
        showEmojiPicker
      />

      {displayCharLimit && (
        <div
          className={cn(
            'ml-auto mt-2 flex w-fit text-xs font-bold',
            invalidInputSize ? '-mt-4 text-red-400' : 'text-text-secondary'
          )}
          data-cy={`${props.id}-input-char-limit`}
        >
          {`${inputValue.length}/${charLimitSize}`}
        </div>
      )}
    </div>
  )
}

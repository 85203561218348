import {showNotification} from '@ReduxActions'
import {DEFAULT_PAGE_SIZE, DEFAULT_SORT, GetJourneysUseCaseParams, getJourneysUseCase} from '@UseCases'
import {keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {
  ComponentPropsWithRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {DataTable} from '../../shared-components/DataTable'
import {store} from '../../store'
import {getTableColumns} from './components/columns'

const initialRequestParams = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
}

type JourneysListTableProps = ComponentPropsWithRef<'div'>

export type JourneysListTableHandler = {
  reload: () => void
}

export const JourneysListTable = forwardRef<JourneysListTableHandler, JourneysListTableProps>(
  (_, ref: Ref<unknown>) => {
    const {formatMessage} = useIntl()
    const [searchParams, setSearchParams] = useSearchParams()
    const pageInSearchParams = searchParams.get('page')
    const sortInSearchParams = searchParams.get('sort')
    const sizeInSearchParams = searchParams.get('size')

    const queryClient = useQueryClient()
    const {requestParams, setRequestParams} = useRequestParams(
      pageInSearchParams,
      sizeInSearchParams,
      sortInSearchParams
    )
    const onPaginationDidChange = useOnPaginationDidChange(setRequestParams, setSearchParams)
    const onSortingDidChange = useOnSortingDidChange(requestParams, setRequestParams, setSearchParams)
    const sortingColumn = useSortingColumn(requestParams.sort)

    const {isFetching, data, error} = useQuery({
      queryKey: ['journeys', requestParams],
      queryFn: ({signal}) => getJourneysUseCase(requestParams, signal),
      placeholderData: keepPreviousData,
    })

    useEffect(() => {
      if (error) {
        onLoadJourneysError()
      }
    }, [error])

    useImperativeHandle(ref, () => ({
      reload: () => {
        if (pageInSearchParams || sizeInSearchParams) {
          onPaginationDidChange({
            pageIndex: pageInSearchParams ? initialRequestParams.page : requestParams.page,
            pageSize: sizeInSearchParams ? initialRequestParams.size : requestParams.size,
          })
        }

        if (sortInSearchParams) {
          const [id, desc] = initialRequestParams.sort.split(',')
          onSortingDidChange([{id, desc: desc === 'desc'}])
        }

        queryClient.invalidateQueries({queryKey: ['journeys', requestParams]})
      },
    }))

    return (
      <>
        {isFetching && (
          <p className="text-md my-16 items-center text-center" data-testid="journeys-loading">
            {formatMessage({id: 'JOURNEY_LIST.LOADING_TABLE_LABEL'})}
          </p>
        )}
        {!isFetching && error && (
          <p className="text-md my-16 items-center text-center" data-testid="journeys-error">
            {formatMessage({id: 'JOURNEY_LIST.ERROR_TABLE_LABEL'})}
          </p>
        )}
        {!isFetching && !error && (
          <DataTable
            columns={getTableColumns(formatMessage)}
            data={data?.content}
            pagination={{
              pageIndex: requestParams.page,
              pageSize: requestParams.size,
            }}
            pageCount={data?.pagination.totalPages ?? 0}
            onPaginationDidChange={onPaginationDidChange}
            sortingColumn={sortingColumn}
            onSortingDidChange={onSortingDidChange}
            data-testid="journeys-content"
            data-cy="journeys-content"
          />
        )}
      </>
    )
  }
)

// MARK: - Custom hooks

const useRequestParams = (
  pageInSearchParams: string | null,
  sizeInSearchParams: string | null,
  sortInSearchParams: string | null
) => {
  const [requestParams, setRequestParams] = useState<Required<GetJourneysUseCaseParams>>({
    ...initialRequestParams,
    page: Math.max(Math.max(0, Number(pageInSearchParams) - 1), initialRequestParams.page),
    size: Number(sizeInSearchParams) || initialRequestParams.size,
    sort: sortInSearchParams?.length ? sortInSearchParams : initialRequestParams.sort,
  })

  return {requestParams, setRequestParams}
}

const useOnPaginationDidChange = (
  setRequestParams: React.Dispatch<React.SetStateAction<Required<GetJourneysUseCaseParams>>>,
  setSearchParams: ReturnType<typeof useSearchParams>[1]
) => {
  return useCallback(
    ({pageIndex, pageSize}: PaginationState) => {
      setRequestParams(prev => ({...prev, page: pageIndex, size: pageSize}))
      setSearchParams(oldParams => {
        oldParams.set('page', `${pageIndex + 1}`)
        return oldParams
      })
    },
    [setRequestParams, setSearchParams]
  )
}

const useOnSortingDidChange = (
  requestParams: Required<GetJourneysUseCaseParams>,
  setRequestParams: React.Dispatch<React.SetStateAction<Required<GetJourneysUseCaseParams>>>,
  setSearchParams: ReturnType<typeof useSearchParams>[1]
) => {
  return useCallback(
    (sorting: SortingState) => {
      const column = sorting[0]
      const sortIndicator = column?.desc ? 'desc' : 'asc'
      const newSort = !column ? '' : `${column.id},${sortIndicator}`

      setRequestParams({
        ...requestParams,
        sort: newSort,
      })
      setSearchParams(oldParams => {
        oldParams.set('sort', newSort)
        return oldParams
      })
    },
    [requestParams, setRequestParams, setSearchParams]
  )
}

const useSortingColumn = (sort: string): SortingState => {
  return useMemo(() => {
    const [id, desc] = sort.split(',')
    return id ? [{id, desc: desc === 'desc'}] : []
  }, [sort])
}

const onLoadJourneysError = () => {
  store.dispatch(
    showNotification({
      show: true,
      type: 'error',
      title: 'Something went wrong!',
      message: 'Please try again later.',
    })
  )
}

export const HowtoDelete = () => {
  return (
    <>
      <p>The delete button will be enabled only when the journey is in "DRAFT" status.</p>
      <p>
        <b> Attention: </b> once the journey is deleted, it can't be reverted!
      </p>
      <ol>
        <li>
          Click on the <b> Delete </b> button at the top right corner of the journey builder.
          <img src="/img/userGuide/delete-button.png" width="79" alt="Delete Journey button" />
        </li>
      </ol>
    </>
  )
}

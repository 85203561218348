import {DefaultLinkPointWidget, DefaultLinkSegmentWidget} from '@projectstorm/react-diagrams'
import {DiagramEngine, LinkWidget, PointModel} from '@projectstorm/react-diagrams-core'
import {Component, RefObject, createElement, createRef} from 'react'
import {CustomLinkFactory} from './CustomLinkFactory'
import {CustomLinkModel} from './CustomLinkModel'

type CustomLinkWidgetProps = {
  link: CustomLinkModel
  diagramEngine: DiagramEngine
  renderPoints?: boolean
  selected?: (event: MouseEvent) => boolean
}

export class CustomLinkWidget extends Component<CustomLinkWidgetProps> {
  refPaths: RefObject<SVGPathElement | unknown>[]
  state: {selected: boolean}

  constructor(props: CustomLinkWidgetProps) {
    super(props)
    this.refPaths = []
    this.state = {
      selected: false,
    }
  }
  renderPoints() {
    let _a
    return (_a = this.props.renderPoints) !== null && _a !== void 0 ? _a : true
  }
  componentDidUpdate() {
    const refs = this.refPaths.map(ref => ref?.current) as SVGPathElement[]
    this.props.link.setRenderedPaths(refs)
  }
  componentDidMount() {
    const refs = this.refPaths.map(ref => ref?.current) as SVGPathElement[]
    this.props.link.setRenderedPaths(refs)
  }
  componentWillUnmount() {
    this.props.link.setRenderedPaths([])
  }
  generatePoint(point: PointModel) {
    return createElement(DefaultLinkPointWidget, {
      key: point.getID(),
      point: point,
      colorSelected: this.props.link.getOptions().selectedColor,
      color: this.props.link.getOptions().color,
    })
  }
  generateLink(path: string, extraProps: object, id: string | number) {
    const ref: RefObject<SVGPathElement> = createRef()
    const factory: CustomLinkFactory = this.props.diagramEngine.getFactoryForLink(this.props.link)

    this.refPaths.push(ref)
    return createElement(DefaultLinkSegmentWidget, {
      key: `link-${id}`,
      path: path,
      selected: this.state.selected,
      diagramEngine: this.props.diagramEngine,
      factory,
      link: this.props.link,
      forwardRef: ref,
      onSelection: selected => {
        this.setState({selected: selected})
      },
      extras: extraProps,
    })
  }
  render() {
    //ensure id is present for all points on the path
    const points = this.props.link.getPoints()
    const paths = []
    this.refPaths = []
    if (points.length === 2) {
      const teste = this.generateLink(this.props.link.getSVGPath(), {}, '0')
      paths.push(teste)
      // draw the link as dangeling
      if (this.props.link.getTargetPort() == null) {
        paths.push(this.generatePoint(points[1]))
      }
    } else {
      //draw the multiple anchors and complex line instead
      for (let j = 0; j < points.length - 1; j++) {
        paths.push(
          this.generateLink(
            LinkWidget.generateLinePath(points[j], points[j + 1]),
            {
              'data-linkid': this.props.link.getID(),
              'data-point': j,
              // onMouseDown: (event: any) => {
              //   let _a, _b
              //   ;(_b = (_a = this.props).selected) === null || _b === void 0 ? void 0 : (_b as any).call(_a, event)
              //   this.addPointToLink(event, j + 1)
              // },
            },
            j
          )
        )
      }
      if (this.renderPoints()) {
        //render the circles
        for (let i = 1; i < points.length - 1; i++) {
          paths.push(this.generatePoint(points[i]))
        }
        if (this.props.link.getTargetPort() == null) {
          paths.push(this.generatePoint(points[points.length - 1]))
        }
      }
    }

    return <g data-default-link-test={this.props.link.getOptions().testName}>{paths}</g>
  }
}

import {DefaultLinkFactory, GenerateWidgetEvent} from '@projectstorm/react-diagrams'
import React from 'react'
import {CustomLinkModel} from './CustomLinkModel'
import {CustomLinkSegment} from './CustomLinkSegment'
import {CustomLinkWidget} from './CustomLinkWidget'

export class CustomLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('custom')
  }

  generateModel(): CustomLinkModel {
    return new CustomLinkModel()
  }

  generateReactWidget(event: GenerateWidgetEvent<CustomLinkModel>) {
    return React.createElement(CustomLinkWidget, {
      link: event.model,
      diagramEngine: this.engine,
    })
  }

  generateLinkSegment(model: CustomLinkModel, selected: boolean, path: string) {
    return (
      <g>
        <CustomLinkSegment model={model} path={path} selected={selected} />
      </g>
    )
  }
}

import {useExportRequiresIntervention} from '@/components/Canvas/hooks/useExportRequiresIntervention'
import {DEFAULT_INTL, JOURNEY_STATUS_EXECUTION_QUERY_KEY} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {Spinner} from '@/shared-components/Spinner'
import {ExecutionStatus, ExecutionStatusTypes} from '@/types/JourneyExecutionStatus'
import {useQueryClient} from '@tanstack/react-query'
import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'

export type StepInfoProps = {
  info?: ExecutionStatus
  shouldDisplayExportRecords: boolean
}

export const StepExecutionStatus = (props: StepInfoProps) => {
  const {shouldDisplayExportRecords, info} = props
  const data = Object.entries(info || {}) as [ExecutionStatusTypes, number][]

  const {isPublishedOrCompleted, country} = useJourneyMetaConfig()
  const {formatMessage} = useIntl()

  const {id: journeyId} = useParams()

  const {isExporting, onExportDidClick} = useExportRequiresIntervention(journeyId)

  const queryClient = useQueryClient()
  const journeyStatus = queryClient.getQueryState([JOURNEY_STATUS_EXECUTION_QUERY_KEY, journeyId, country])

  const isPending = journeyStatus?.status === 'pending'
  const shouldHide = !isPublishedOrCompleted || (!data.length && !isPending)
  if (shouldHide) {
    return null
  }

  if (isPending) {
    return (
      <div data-testid="loading-step-execution-status" data-cy="loading-step-execution-status" className="m-auto w-fit">
        <Spinner size="small" className="m-auto" />
      </div>
    )
  }

  return (
    <>
      <div
        className="ml-2 flex flex-col text-xs text-text-secondary"
        data-testid="step-execution-status"
        data-cy="step-execution-status"
      >
        <ul>
          {data.map(([key, value]) => (
            <li
              key={key}
              data-testid={`step-execution-status-${key}`}
              data-cy={`step-execution-status-${key}`}
              className="mb-2"
            >
              <StepExecutionStatusItem status={key} value={value} />
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-1 flex w-full">
        {shouldDisplayExportRecords && (
          <button
            className="z-10 m-auto flex items-center gap-1 text-sm font-bold text-link group-hover:text-link-hover"
            data-testid="btn-export"
            data-cy="btn-export"
            onClick={onExportDidClick}
            disabled={isExporting}
          >
            {isExporting && <Spinner size="small" className="ml-2" />}
            {!isExporting && (
              <>
                <Icon icon={'download'} size="small" className="$color-link-default group-hover:$color-link-hover" />
                {formatMessage({id: 'JOURNEY_BUILDER.EXPORT_RECORDS_BUTTON'})}
              </>
            )}
          </button>
        )}
      </div>
    </>
  )
}

export const StepExecutionStatusItem = ({status, value}: {status: ExecutionStatusTypes; value?: number}) => {
  const formattedNumber = value?.toLocaleString(DEFAULT_INTL)
  const {formatMessage} = useIntl()

  const shouldDisplayValue = value != undefined

  return (
    <div className="flex items-center gap-2">
      <StepExecutionStatusIcon status={status} />
      <span className="text-xs text-text-secondary">
        {formatMessage({id: `JOURNEY_BUILDER.STEP_EXECUTION_STATUS_${status}`})}
        {shouldDisplayValue && <>: {formattedNumber}</>}
      </span>
    </div>
  )
}

const StepExecutionStatusIcon = ({status}: {status: ExecutionStatusTypes}) => {
  const iconByType = {
    CREATED: 'add_task',
    RUNNING: 'published_with_changes',
    COMPLETED: 'check_circle_outline',
    ON_HOLD: 'error_outline',
    SKIPPED: 'history_toggle_off',
    FAILED: 'warning',
    EXECUTED: 'done_outline',
    INTERRUPTED: 'alarm_off',
    CANCELED: 'disabled_by_default',
  }
  return <Icon icon={iconByType[status]} size="small" className="$color-icon-default h-4 w-4" />
}
